import { FilterOptions } from "../../types/Admin/ApplicantsDTO";
import { IAPIResponse } from "../../types/IAPIResponse";
import axios from "axios";

export class AdminGloablValues {
  async GetMajorsForApplicationType(
    applicationType: string
  ): Promise<IAPIResponse<FilterOptions[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/globalvalues/listofmajors/" + applicationType
    );
    let majors: IAPIResponse<FilterOptions[]> = response.data;
    return Promise.resolve(majors);
  }
}
