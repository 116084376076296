import React, { useState } from "react";
import classes from "./Hero.module.css";
import Spinner from "../Spinner/Spinner";
import { AuthAPI } from "../../API/AuthAPI";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import { useAppDispatch } from "../../store/hooks";
import {
  setEmailVerified,
  setMobileVerified,
} from "../../store/Slices/NotificationState";
import { setApplicantStatus } from "../../store/Slices/ApplicantStatusState";
import { setRole, setUsername } from "../../store/Slices/RoleState";
import video from "../../assests/videos/background.mp4";
import { setFreezedStatus } from "../../store/Slices/Freezed";
import Cookies from "js-cookie";
import { setAllowToEdit } from "../../store/Slices/AdminApplicantId";

const Hero = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [usernameValue, setUsernameValue] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [msg, setMsg] = useState<string>("");
  const [isloading, setIsloading] = useState<boolean>(false);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      loginHanlder();
    }
  };
  const loginHanlder = async () => {
    setIsloading(true);
    localStorage.removeItem("jwttoken");
    if (
      usernameValue === "" ||
      password === "" ||
      usernameValue === undefined ||
      password === undefined
    ) {
      setMsg(t("Login_wrongusernameOrPassword")!);
    } else {
      try {
        const jwttoken = new AuthAPI(usernameValue, password);
        const tokenresult = await jwttoken.GetToken();
        if (tokenresult.isSuccess) {
          setIsloading(true);
          const applicationStatusId = tokenresult.result.applicationStatusId;
          dispatch(setEmailVerified(tokenresult.result.emailConfirmed));
          dispatch(setMobileVerified(tokenresult.result.phoneNumberConfirmed));
          dispatch(setApplicantStatus(tokenresult.result.applicationStatusId));
          const token = "Bearer " + tokenresult.result.token;
          localStorage.setItem("jwttoken", token);
          const decodedjwt = jwttoken.GetRole(token);
          const role = decodedjwt.role;
          Cookies.set("role", role, { expires: 7 });

          //allow edit for the following roles:
          const allowEditRoles = ["ADM", "AVP", "REG", "REO"];

          if (allowEditRoles.indexOf(role) >= 0) {
            dispatch(setAllowToEdit(true));
          } else {
            dispatch(setAllowToEdit(false));
          }

          dispatch(setFreezedStatus(decodedjwt.freezed));
          dispatch(setRole(role));
          dispatch(setUsername(usernameValue));
          if (applicationStatusId === 2) {
            if (role === "UNG") {
              navigate("/ug/idandseatno");
            } else {
              navigate("/gr/idandstudentno");
            }
          } else if (
            (role === "GRU" || role === "UNG") &&
            applicationStatusId > 2
          ) {
            switch (applicationStatusId) {
              case 5:
                navigate("/applicant/info");
                break;
              case 6:
                navigate("/applicant/contact");
                break;
              case 7:
                navigate("/applicant/schoolinfo");
                break;
              case 8:
                navigate("/applicant/experiance");
                break;
              case 9:
                navigate("/applicant/choices");
                break;
              case 10:
                navigate("/applicant/snh");
                break;
              case 11:
                navigate("/applicant/doc");
                break;
              default:
                navigate("/applicant/info");
                break;
            }
          } else if (
            role === "ADM" ||
            role === "BUS" ||
            role === "SOC" ||
            role === "REG" ||
            role === "TOU" ||
            role === "NUR" ||
            role === "AVP" ||
            role === "NUI" ||
            role === "POT" ||
            role === "REO" ||
            role === "MOC" ||
            role === "SPN" ||
            role === "HDP" ||
            role === "FIN" ||
            role === "HDM" ||
            role === "MAS"
          ) {
            i18n.changeLanguage("en-US");
            i18n.dir("ltr");
            navigate("/admin/listofapplicants");
          } else {
            alert("The following role is not defined on the system:" + role);
          }
        } else {
          setMsg(tokenresult.errorMessages.join(" "));
        }
      } catch (error) {
        setMsg(t("Login_wrongusernameOrPassword")!);
      }
    }
    setIsloading(false);
  };

  return (
    <header className={classes.header}>
      <video autoPlay muted loop className={classes.video}>
        <source src={video} type="video/mp4" media="(min-width: 65rem)" />
      </video>
      <div className={classes.bg}></div>
      <Header dark={true} />
      <section>
        <div className={classes.content}>
          <h1 className="headingPrimary">{t("Login_thanks")}</h1>
          <h3 className={classes.desc}>{t("Login_msg")}</h3>
          <div className={classes.loginBox}>
            <div className={classes.loginBoxHeader}>
              <h4 className={"headingFourth"}>{t("Login_input")}</h4>
              {/* <h4 className={"headingFourth " + classes.palpay}>
                {t("Login_palppay")}
              </h4> */}
            </div>
            <div className={classes.loginBoxAuth}>
              <input
                className="textbox textboxBig"
                placeholder={t("Login_username")!}
                type="text"
                required
                onChange={(e) => setUsernameValue(e.target.value)}
              ></input>
              <input
                className="textbox textboxBig"
                placeholder={t("Login_password")!}
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              ></input>
              <button
                disabled={isloading}
                className={"btn"}
                onClick={loginHanlder}
              >
                {t("Login_btn")}
              </button>

              <div>
                {isloading ? (
                  <Spinner />
                ) : (
                  <>
                    {msg !== "" ? (
                      <div className={classes.msg}>
                        <span className={classes.errorMessage}>{msg}</span>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <a
                rel="noreferrer"
                className={"btn goldbutton"}
                href="https://www.bethlehem.edu/ar/admission/procedures/"
                target="_blank"
              >
                {t("Login_pay")}{" "}
              </a>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Hero;
