import axios from "axios";
import {
  CommentNotificationDTO,
  EmailNotificationDTO,
  ListOfCommentsResponseDTO,
  SMSNotificationDTO,
} from "../../types/Admin/AdminCommentsDTO";
import { IAPIResponse } from "../../types/IAPIResponse";

export class AdminCommentsAPI {
  async ListOfComments(
    userId: string | undefined
  ): Promise<IAPIResponse<ListOfCommentsResponseDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/comments/listofcomments/" + userId
    );
    let ListOfComments: IAPIResponse<ListOfCommentsResponseDTO[]> =
      response.data;
    return Promise.resolve(ListOfComments);
  }

  async sendEmail(data: EmailNotificationDTO): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/admin/comments/sendemail",
      data
    );
    let emailNotification: IAPIResponse = response.data;
    return Promise.resolve(emailNotification);
  }

  async sendSMS(data: SMSNotificationDTO): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/admin/comments/sendsms",
      data
    );
    let smsNotification: IAPIResponse = response.data;
    return Promise.resolve(smsNotification);
  }

  async addComment(data: CommentNotificationDTO): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/admin/comments/addcomment",
      data
    );
    let smsNotification: IAPIResponse = response.data;
    return Promise.resolve(smsNotification);
  }
}
