import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  PersonalInfoDTO,
  PersonalInfoResponseDTO,
} from "../../types/Applicant/PersonalInfoDTO";

export class ApplicantInfoUpdateAPI {
  async update(
    applicantInfo: PersonalInfoDTO,
    userId?: string
  ): Promise<IAPIResponse> {
    const response = await axios.put<IAPIResponse>(
      "/applicant/personalinfo" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      applicantInfo
    );
    let applicationInfo: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(applicationInfo);
    });
  }
}

export class ApplicantInfoAPI {
  async Get(userId?: string): Promise<IAPIResponse<PersonalInfoResponseDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/applicant/personalinfo" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let personalInfo: IAPIResponse<PersonalInfoResponseDTO> = response.data;
    return new Promise<IAPIResponse<PersonalInfoResponseDTO>>(
      (resolve, reject) => {
        resolve(personalInfo);
      }
    );
  }
}
