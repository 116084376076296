import react, { useEffect } from "react";

const AdmissionCard = () => {
  useEffect(() => {
    window.location.href = "https://oldadmission.bethlehem.edu/admissioncard/";
  }, []);

  return <></>;
};
export default AdmissionCard;
