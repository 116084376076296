import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import logo from "../../assests/logo_blackbg.png";

const Thanks = () => {
  return (
    <div
      className={"container grid grid--1--cols"}
      style={{ justifyItems: "center", marginBottom: "2.4rem" }}
    >
      <img src={logo} alt="bethlehem" style={{ width: 250 }} />
      <h1>شكرا لإختيارك جامعة بيت لحم </h1>
      <h2>
        سيتم التواصل معك من خلال رقم الهاتف والبريد اللاكتروني المسجل بالطلب عند
        اعلان نتائج القبول
      </h2>
    </div>
  );
};

export default Thanks;
