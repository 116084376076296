import React, { useEffect, useState } from "react";
import classes from "./Header.module.css";
import logo from "../../assests/logo_blackbg.png";
import Step from "./Step";
import i18n from "../../i18n";
import { LANGUAGES } from "../../constants";
import { useTranslation } from "react-i18next";
import { IoMenu } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "../../store/hooks";

import Notification from "./Notification";
import { RootState } from "../../store/store";
import { InstructionsAndAcceptanceAPI } from "../../API/Applicant/InstructionsAndAcceptanceAPI";

const Header = () => {
  const role: string = useAppSelector((state: RootState) => state.role.role);
  const uname: string = useAppSelector(
    (state: RootState) => state.role.username
  );
  const [toggle, setToggle] = useState<boolean>(false);
  const [language, setlanguage] = useState<string>("ar");
  const { t } = useTranslation();
  const [printtoken, setprinttoken] = useState<string>("");
  useEffect(() => {
    const fetchData = async () => {
      const printapp = new InstructionsAndAcceptanceAPI();
      setprinttoken((await printapp.GetPrintToken()).result);
    };

    const lang = localStorage.getItem("lang");
    if (lang !== null) {
      changeLanguageHandler(lang);
    }
    fetchData();
  }, []);

  const changeLanguageHandler = (lang: string) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    document.body.style.direction = i18n.dir();
    setlanguage(lang);
  };
  return (
    <header>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className={"notification"}
      />
      <div className={"container " + classes.mainHeader}>
        <img
          className={classes.logo}
          alt="Admission bethlehem university"
          src={logo}
        />
        <h2 className={"headingSecond " + classes.mainHeaderTitle}>
          {t("Steps_header")}
        </h2>
        <div className={classes.leftMenu}>
          <select
            value={language}
            onChange={(event) => changeLanguageHandler(event.target.value)}
          >
            {LANGUAGES.map(({ code, label }) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
          <h3 className="headingThird">{uname}</h3>
        </div>
      </div>
      <div className={classes.mainNavDiv}>
        <div>
          <button
            className={classes.btnMobileNav}
            onClick={() => setToggle(!toggle)}
          >
            {!toggle ? (
              <IoMenu className={classes.iconMobileNav} name="menu" />
            ) : null}
          </button>
        </div>
        <div>
          <h2>{t("Steps_header")}</h2>
        </div>
        <nav
          className={
            "container " +
            classes.mainNav +
            " " +
            (toggle ? classes.opennav : "")
          }
        >
          <ul className={classes.mainNavList}>
            <Step
              stepNumber={1}
              showOn={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 99]}
              path="/applicant/info"
              active={true}
              title={t("Steps_StudentInfo")!}
              onClick={() => setToggle(false)}
            />
            <Step
              stepNumber={2}
              showOn={[5, 6, 7, 8, 9, 10, 11, 99]}
              path="/applicant/contact"
              active={false}
              title={t("Steps_ContactInfo")!}
              onClick={() => setToggle(false)}
            />
            {role === "UNG" ? (
              <Step
                stepNumber={3}
                showOn={[6, 7, 8, 9, 10, 11, 99]}
                path="/applicant/schoolinfo"
                active={false}
                title={t("Steps_SchoolInfo")!}
                onClick={() => setToggle(false)}
              />
            ) : (
              <Step
                stepNumber={3}
                showOn={[6, 7, 8, 9, 10, 11, 99]}
                path="/applicant/experiance"
                active={false}
                title={t("Steps_Experiance")!}
                onClick={() => setToggle(false)}
              />
            )}
            <Step
              stepNumber={4}
              showOn={[7, 8, 9, 10, 11, 99]}
              path="/applicant/choices"
              active={false}
              title={t("Steps_Choices")!}
              onClick={() => setToggle(false)}
            />
            <Step
              stepNumber={5}
              showOn={[9, 10, 11, 99]}
              path="/applicant/snh"
              active={false}
              title={t("Steps_SpecialNeed")!}
              onClick={() => setToggle(false)}
            />
            <Step
              stepNumber={6}
              showOn={[10, 11, 99]}
              path="/applicant/doc"
              active={false}
              title={t("Steps_Documents")!}
              onClick={() => setToggle(false)}
            />
            <Step
              active={false}
              title={t("Signout")!}
              path="/"
              onClick={() => {
                localStorage.clear();
              }}
            />
          </ul>
        </nav>
      </div>
      {printtoken !== "" ? (
        <div>
          <a
            href={`http://admission.bethlehem.edu/print/${printtoken}`}
            className={`btn ${classes.printApp}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("Steps_printapplication")}
          </a>
        </div>
      ) : null}
      <Notification />
    </header>
  );
};

export default Header;
