import AdminHeader from "../adminheader/AdminHeader";
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MaterialReactTable,
} from "material-react-table";
import { ThemeProvider } from "@mui/material";
import { tableTheme } from "../TableStyle";

import classes from "./ListOfApplicants.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { FilterOptions } from "../../../types/Admin/ApplicantsDTO";
import moment from "moment";
import {
  setAdminApplicantId,
  setUpdatedRecord,
} from "../../../store/Slices/AdminApplicantId";
import { ListOfApplicantsAPI } from "../../apis/ListOfApplicantsAPI";
import { ListOfApplicantsDTO } from "../../types/ListOfApplicantsDTO";
import Modal from "react-bootstrap/Modal";
import { AdminGloablValues } from "../../../API/Admin/AdminGloablValues";
import ApplicantDashboard from "../ApplicationDashboard/ApplicantDashboard";
import { RootState } from "../../../store/store";

export const ListOfApplicants = (props: any): JSX.Element => {
  const getlistOfApplicants = async (type: string) => {
    setData([]);
    setIsRefetching(true);
    let applicants = new ListOfApplicantsAPI();
    setData((await applicants.GetAllApplicants(type)).result);
    setIsRefetching(false);
  };

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handleSaveRow();
    setOpen(false);
  };
  const [index, setindex] = useState<number>(0);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [majorOptions, setmajorOptions] = useState<FilterOptions[]>([]);
  const [majorAcceptanceOptions, setmajorAcceptanceOptions] = useState<
    FilterOptions[]
  >([]);

  const [data, setData] = useState<ListOfApplicantsDTO[]>([]);
  const tableInstanceRef = useRef<MRT_TableInstance<ListOfApplicantsDTO>>(null);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [app, setApp] = useState<string>("");
  const admin = useAppSelector((state: RootState) => state.admin);
  useEffect(() => {
    if (refreshInterval && refreshInterval > 0 && open === false) {
      const interval = setInterval(getApplicants, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval, app, open]);

  const columns = useMemo<MRT_ColumnDef<ListOfApplicantsDTO>[]>(
    () => [
      {
        header: "id",
        accessorKey: "id",
      },
      {
        header: "Application No.",
        accessorKey: "userName",
      },
      {
        header: "English name",
        accessorKey: "englishName",
      },
      {
        header: "First choice",
        accessorKey: "firstChoice",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.firstChoiceNA === true ? (
              <span className={classes.notApplicableMajor}>
                {row.original.firstChoice}
              </span>
            ) : (
              <span>{row.original.firstChoice}</span>
            )}
          </div>
        ),
      },
      {
        header: "Second choice",
        accessorKey: "secondChoice",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.secondChoiceNA === true ? (
              <span className={classes.notApplicableMajor}>
                {row.original.secondChoice}
              </span>
            ) : (
              <span>{row.original.secondChoice}</span>
            )}
          </div>
        ),
      },
      {
        header: "Third choice",
        accessorKey: "thirdChoice",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.thirdChoiceNA === true ? (
              <span className={classes.notApplicableMajor}>
                {row.original.thirdChoice}
              </span>
            ) : (
              <span>{row.original.thirdChoice}</span>
            )}
          </div>
        ),
      },
      {
        header: "Accepted major",
        accessorKey: "acceptedMajor",
        filterSelectOptions: majorAcceptanceOptions,
        filterVariant: "select",
      },
      {
        size: 20,
        header: "Tawjihi average",
        accessorKey: "tawjihiAverage",
        columnFilterModeOptions: ["between", "greaterThan", "lessThan"],
        filterFn: "between",
      },
      {
        header: "Certificate type",
        accessorKey: "certificateType",
      },
      {
        header: "Certificate year",
        accessorKey: "certificateYear",
      },
      {
        header: "Arabic name",
        accessorKey: "arabicName",
      },
      {
        header: "Last modifieddate",
        accessorKey: "lastModifiedDate",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <span>
              {row.original.verified?.toString() !== "0001-01-01T00:00:00"
                ? moment
                    .utc(row.original.lastModifiedDate)
                    .local()
                    .format("DD/MM/YYYY HH:mm")
                : ""}
            </span>
          </div>
        ),
      },

      {
        header: "Verified",
        accessorKey: "verified",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {(() => {
              switch (row.original.verified) {
                case "Verified":
                  return (
                    <span className={`${classes.status} ${classes.verified}`}>
                      Verified
                    </span>
                  );
                case "Imported":
                  return (
                    <span className={`${classes.status} ${classes.imported}`}>
                      Imported
                    </span>
                  );
                case "Not verified":
                  return (
                    <span
                      className={`${classes.status} ${classes.notVerified}`}
                    >
                      Not verified
                    </span>
                  );
                default:
                  return (
                    <div className={classes.notVerified}>
                      {row.original.verified}
                    </div>
                  );
              }
            })()}
          </div>
        ),
      },
    ],
    [majorOptions, majorAcceptanceOptions]
  );

  const nextPrevious = (next: boolean = true) => {
    const filterdData = tableInstanceRef.current?.getSortedRowModel().rows;
    const currentFilterDataIndex = filterdData?.findIndex(
      (x) => x.index === index
    );

    if (filterdData != undefined) {
      if (!next) {
        if (index === filterdData[0].index) {
          alert("No previous!");
          return;
        }
        const previousIndex = (currentFilterDataIndex ?? 0) - 1;
        let previousFilterData = filterdData[previousIndex];
        dispatch(setAdminApplicantId(previousFilterData.original.id));
        setindex(previousFilterData.index);
      } else {
        if (index === filterdData[filterdData.length - 1].index) {
          alert("No next!");
          return;
        }
        const nextIndex = (currentFilterDataIndex ?? 0) + 1;
        let filterDataIndex = filterdData[nextIndex];
        dispatch(setAdminApplicantId(filterDataIndex.original.id));
        setindex(filterDataIndex.index);
      }
    }
    handleSaveRow();
  };

  const handleSaveRow = async () => {
    if (admin.updatedRecord) {
      let updatedRow = data[index];
      let applicants = new ListOfApplicantsAPI();
      const applicantInfo = (await applicants.GetAnApplicant(updatedRow.id))
        .result;
      if (applicantInfo !== undefined) {
        updatedRow = applicantInfo;
        const updatedData = [...data];
        updatedData[index] = updatedRow;
        setData(updatedData);
      }
      dispatch(setUpdatedRecord(false));
    }
  };

  const onTypeHandler = async (appType: string) => {
    setApp(appType);
    getlistOfApplicants(appType);
    let adminvalues = new AdminGloablValues();
    let options = (await adminvalues.GetMajorsForApplicationType(appType))
      .result;
    setmajorOptions(options);
    let list = [...options];
    list.push({
      text: "Not assigned yet",
      value: "-",
    });
    setmajorAcceptanceOptions(list);
    setRefreshInterval(600000);
  };

  const getApplicants = async () => {
    onTypeHandler(app);
  };
  return (
    <div style={{ width: "100%" }}>
      {" "}
      <header>
        <AdminHeader onTypeHandler={onTypeHandler} title="List of applicants" />
      </header>
      <section className={classes.listOfApplicants}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={data}
            tableInstanceRef={tableInstanceRef}
            enablePagination={true}
            state={{
              showProgressBars: isRefetching,
            }}
            muiTableContainerProps={{
              sx: {
                minHeight: 0,
                maxHeight: "calc(100vh - 400px)",
              },
            }}
            initialState={{
              pagination: {
                pageSize: 100,
                pageIndex: 0,
              },
              showColumnFilters: true,
              columnPinning: { left: ["userName"], right: ["verified"] },
              columnVisibility: {
                id: false,
                serial: false,
                acceptedMajor: true,
                interviewDate: false,
              },
              density: "compact",
              sorting: [{ id: "lastModifiedDate", desc: true }],
            }}
            muiTableBodyCellProps={({ cell }) => ({
              onDoubleClick: (event) => {
                setindex(cell.row.index);
                handleOpen();
                dispatch(setAdminApplicantId(data[cell.row.index].id));
              },
              sx: {
                cursor: "pointer",
              },
            })}
            enableRowNumbers
            muiTableBodyRowProps={({ row }) => ({
              onClick: (e) => {
                let stoploop = false;
                if (!e.shiftKey) {
                } else {
                  tableInstanceRef.current
                    ?.getRowModel()
                    .rows.forEach((element) => {
                      if (row.id === element.id) {
                        element.toggleSelected();
                        stoploop = true;
                      } else if (!stoploop) {
                        const firstChoice = document.getElementById(
                          "firstChoice" + element.original.userName
                        ) as HTMLInputElement | null;
                        firstChoice!.checked = true;
                        element.toggleSelected();
                      }
                    });
                }
              },
              sx: {
                cursor: "pointer",
              },
            })}
            rowNumberMode="static"
            positionToolbarAlertBanner="bottom"
          />
        </ThemeProvider>
      </section>
      <Modal
        show={open}
        onHide={handleClose}
        className={classes.modal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Applicant Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApplicantDashboard
            nextrow={() => nextPrevious(true)}
            previousrow={() => nextPrevious(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
