import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface LoadingState {
  isLoading: boolean;
}

// Define the initial state using that type
const initialState: LoadingState = {
  isLoading: false,
};

export const loadingStateSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = loadingStateSlice.actions;
export const selectRole = (state: RootState) => state.role;
export default loadingStateSlice.reducer;
