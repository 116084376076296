import React from "react";
import "./App.css";
import { routes } from "./routes";
import { useRoutes } from "react-router-dom";
import "react-datetime/css/react-datetime.css";

function App() {
  let element = useRoutes(routes);
  return element;
}

export default App;
