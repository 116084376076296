import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { IOption } from "../../types/Codes/IOptions";
import { ApplicationSMSTempletes } from "../types/SMSTempletes";

export class SMSTempletesAPI {
  async GetAllTempletes(registrar: boolean): Promise<IAPIResponse<IOption[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/templates/sms/getAllTempletes/" + registrar
    );
    let templetes: IAPIResponse<IOption[]> = response.data;
    return Promise.resolve(templetes);
  }

  async GetATemplete(
    id: number
  ): Promise<IAPIResponse<ApplicationSMSTempletes>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/templates/sms/getatemplete/" + id
    );
    let templetes: IAPIResponse<ApplicationSMSTempletes> = response.data;
    return Promise.resolve(templetes);
  }
}
