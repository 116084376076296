import { useEffect, useState, useRef } from "react";
import "./PrintApplicant.css";
import logo from "../../../assests/logo_blackbg.png";
import { PrintApplicationDTO } from "../../../types/Applicant/PrintApplicationDTO";
import { useParams } from "react-router-dom";
import { InstructionsAndAcceptanceAPI } from "../../../API/Applicant/InstructionsAndAcceptanceAPI";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const PrintApplicant = () => {
  const [data, setData] = useState<PrintApplicationDTO>();
  let { id } = useParams();
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({});

  useEffect(() => {
    const fetchData = async () => {
      const printapp = new InstructionsAndAcceptanceAPI();

      await printapp.GetPrintApplicationDetails(id).then((r) => {
        setData(r.result);
        //window.print();
      });
    };
    fetchData();
  }, [id]);

  return (
    <>
      <div style={{ padding: "15px" }}>
        <button
          className="btn"
          onClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        >
          Print
        </button>
      </div>
      <div>
        <div className="printcontainer" ref={contentToPrint}>
          <div className="header padding">
            <div className="applicantNo">
              <span>Applicant ref. No:</span>
              <span className="refno">
                {String(data?.admNo ?? 0).padStart(4, "0")}
              </span>
            </div>
            <div className="logoheader">
              <img className="logo" src={logo} alt="bethlehem university" />
              <span className="headerAdm">Admission Application</span>
            </div>
            <div className="emptyPicture">PHOTO</div>
          </div>
          <div className={`grid--2--colsNotResponsive padding`}>
            <div className="englishInstruction">
              <strong>
                Documents required to complete the admission application:
              </strong>
              <ol>
                <li>A personal photo</li>
                <li>A copy of identity card</li>
                <li>Signed copy of tawjihi grades</li>
              </ol>
              <strong>Optional documents, include if applicable:</strong>
              <ol>
                <li>Form students with specail needs for</li>
                <li>Humanitarian cases applicantion</li>
              </ol>
            </div>
            <div dir="rtl">
              <strong>الوثائق المطلوبة لإعتماد الطلب:</strong>
              <ol>
                <li>صورة شخصية</li>
                <li>صورة عن بطاقة الهوية الشخصية مع الملحق</li>
                <li>
                  صورة مصدقة من وزارة التربية والتعليم عن كشف علامات الثانوية
                  العامة (التوجيهي أو الإنجاز). أو ما يعادلها من شهادات اخرى
                  للطلبة الغير حاصلين على شهادة الثانوية.
                </li>
              </ol>
              <strong>وثائق يجب ارفاقها مع الحالات الخاصة:</strong>
              <ol>
                <li>بيانات ذوي الإعاقة ان وجدت</li>
                <li>طلب الحالات الإنسانية في حالة حدوثها</li>
              </ol>
            </div>
          </div>
          <div>
            <div className="studentInfo padding">
              <div className="grid--2--colsNotResponsive">
                <span>
                  Name: <strong>{data?.name}</strong>
                </span>
                <span>
                  Id Number/Type:{" "}
                  <strong>
                    {data?.idNo}/ {data?.idType}
                  </strong>
                </span>
                <span>
                  Nationallity: <strong>{data?.nationallity}</strong>
                </span>
                <span>
                  Birth date:{" "}
                  <strong>
                    {moment(data?.birthDate).format("DD/MM/YYYY")}
                  </strong>
                </span>
                <span>
                  Gender: <strong>{data?.gender}</strong>{" "}
                </span>
                <span>
                  Religion/Denomination:
                  <strong>
                    {" "}
                    {data?.religion}/{data?.denomination}
                  </strong>
                </span>
                <span>
                  Material status: <strong>{data?.materialStatus}</strong>
                </span>
              </div>
              <div className="grid--2--colsNotResponsive">
                <span>
                  Address: <strong>{data?.address}</strong>
                </span>
                <span>
                  Mobile No: <strong>{data?.mobileNo}</strong>
                </span>
              </div>
              <div className="grid--2--colsNotResponsive">
                <span>
                  School Name: <strong>{data?.schoolName}</strong>
                </span>
                <span>
                  Certificate type: <strong>{data?.certificateType}</strong>
                </span>
                <span>
                  Stream: <strong>{data?.stream}</strong>
                </span>
                <span>
                  Year: <strong>{data?.year ? data?.year : "-"}</strong>
                </span>
              </div>
            </div>
            <div className="padding">
              <div className="agreement ">
                <div>
                  <h3>Agreement</h3>
                  <p>
                    I certify that the information provided in the admission
                    application is accurate and complete the best of my
                    knowledge and belief. I understand that withholding
                    information requested on the admission application or
                    providing false information will make me ineligible for
                    admission to Bethlehem University or subject to cancellation
                    of registration if admission has been granted. I also
                    authorize Bethlehem University to investigate the
                    information and documents provided by me. I declare that if
                    I am admitted to the university, I will abide by all rules
                    and regulations in the student handbook and the university
                    catalog. I understand that Bethlehem University has the
                    right to change the tuition and fees that is charges and
                    that the tuition and fees may be subject to increase at any
                    time during my course of studies at Bethlehem University. I
                    also commit to pay the tuition and fees within the scheduled
                    deadlines as published by the University.
                  </p>
                </div>
                <div dir="rtl">
                  <h3>اتفاقية</h3>
                  <p>
                    أشهد أن المعلومات المزودة في طلب الإلتحاق هي صحيحة وكاملة
                    وفقا لمعرفتي واعتقادي الأكيدين. وأفهم أن حجب أية معلومات
                    مطلوبة في طلب الإلتحاق أو تزويد معلومات مزورة سيجعلني غير
                    مؤهل للإلتحاق بجامعة بيت لحم أو معرضا إلى إلغاء تسجيلي حتى
                    لو حصلت على القبول في الجامعة. كذلك فإنني أخول جامعة بيت لحم
                    صلاحية التحقق من المعلومات والوثائق التي قدمتها. كما أعلن
                    موافقتي في حالي قبولي في الجامعة عن إلتزامي بجميع القوانين
                    واللوائح المعمول بها في الجامعة بما في ذلك ما ورد من
                    القوانين واللوائح في كتيب الطالب ودليل الجامعة. أفهم أن
                    لجامعة بيت لحم الحق في تغيير الأقساط والرسوم التي تستوفيها
                    مني, وأن هذه الأقساط والرسوم قابلة للزيادة في أي وقت خلال
                    فترة دراستي في جامعة بيت لحم. كذلك فإنني ألتزم بتسديد القسط
                    والرسوم وفق المواعيد التي تحددها الجامعة.
                  </p>
                </div>
                <div className="grid--2--colsNotResponsive">
                  <p>Name:___________________________</p>
                  <p>Signature:___________________________</p>
                </div>
              </div>
            </div>
            <div className="grid--2--colsNotResponsive ">
              <p>For University Use Only</p>
              <p style={{ textAlign: "right" }}>لإستعمال الجامعة فقط</p>
            </div>
            <div className="grid--university">
              <p className="grid--recievedBy">Received by:</p>
              <p>Name:____________________</p>
              <p>Signature:____________________</p>
              <p>Date:____________________</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrintApplicant;
