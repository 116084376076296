import AdminHeader from "../adminheader/AdminHeader";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableInstance,
  MaterialReactTable,
} from "material-react-table";
import { ThemeProvider } from "@mui/material";
import { tableTheme } from "../TableStyle";

import classes from "../rankingList/rankingList.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import {
  ActiveListDTO,
  FilterOptions,
} from "../../../types/Admin/ApplicantsDTO";
import moment from "moment";
import { setAdminApplicantId } from "../../../store/Slices/AdminApplicantId";
import Modal from "react-bootstrap/Modal";
import ApplicantDashboard from "../ApplicationDashboard/ApplicantDashboard";
import Cookies from "js-cookie";
import { AdminGloablValues } from "../../../API/Admin/AdminGloablValues";
import { ListOfAcceptedApplicantsDTO } from "../../types/AcceptedList";
import { AcceptedListAPI } from "../../apis/AcceptedListAPI";
import { AcceptanceToMajorNotification } from "../Notifications/AcceptanceToMajorNotification";

export const AdminAcceptedToMajor = (props: any): JSX.Element => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [index, setindex] = useState<number>(0);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [majorOptions, setmajorOptions] = useState<FilterOptions[]>([]);
  const [dataTable, setDataTable] = useState<ListOfAcceptedApplicantsDTO[]>([]);
  const tableInstanceRef =
    useRef<MRT_TableInstance<ListOfAcceptedApplicantsDTO>>(null);

  const [openAcceptance, setOpenAcceptance] = useState(false);
  const handleOpenAcceptance = () => setOpenAcceptance(true);
  const handleCloseAcceptance = () => setOpenAcceptance(false);

  useEffect(() => {
    updateList();
  }, []);

  const updateList = async () => {
    await updateActiveList();
  };
  const nextPrevious = (next: boolean = true) => {
    if (!next) {
      if (index - 1 === -1) {
        alert("No previous!");
        return;
      }
      dispatch(setAdminApplicantId(dataTable[index - 1].id));
      setindex(index - 1);
    } else {
      if (index + 1 === dataTable.length) {
        alert("No next!");
        return;
      }
      dispatch(setAdminApplicantId(dataTable[index + 1].id));
      setindex(index + 1);
    }
  };

  const onTypeChangeHandler = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const appType = Cookies.get("appType");
    await updateList();
    let adminvalues = new AdminGloablValues();
    setmajorOptions(
      (await adminvalues.GetMajorsForApplicationType(appType ?? "0")).result
    );
  };

  const getApplicantList = async () => {
    const appType = Cookies.get("appType");
    if (appType == undefined || appType == null) {
      return;
    }
    setIsRefetching(true);
    const acceptedList = new AcceptedListAPI();
    await acceptedList.GetAllAcceptedStudents(appType ?? "-1").then((data) => {
      setDataTable(data.result);
    });
    setIsRefetching(false);
  };

  const updateActiveList = async () => {
    await getApplicantList();
    setRowSelection({});
    handleCloseAcceptance();
  };

  const columns = useMemo<MRT_ColumnDef<ListOfAcceptedApplicantsDTO>[]>(
    () => [
      {
        header: "id",
        accessorKey: "id",
      },
      {
        header: "Application No.",
        accessorKey: "userName",
      },
      {
        header: "English name",
        accessorKey: "englishName",
      },
      {
        header: "First choice",
        accessorKey: "firstChoice",
        filterSelectOptions: majorOptions,
        filterVariant: "select",
      },
      {
        header: "Second choice",
        accessorKey: "secondChoice",
        filterSelectOptions: majorOptions,
        filterVariant: "select",
      },
      {
        header: "Third choice",
        accessorKey: "thirdChoice",
        filterSelectOptions: majorOptions,
        filterVariant: "select",
      },
      {
        header: "Accepted major",
        accessorKey: "acceptedMajor",
        filterSelectOptions: majorOptions,
        filterVariant: "select",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.acceptedMajor !== "" ? (
              <div className={classes.accepted}>
                {row.original.acceptedMajor}
                <br />
                {row.original.acceptedDate?.toString() !== "0001-01-01T00:00:00"
                  ? moment
                      .utc(row.original.acceptedDate)
                      .local()
                      .format("DD/MM/YYYY HH:mm")
                  : ""}
              </div>
            ) : null}
          </div>
        ),
      },
      {
        header: "Acceptance notifications",
        accessorKey: "acceptanceNotification",
        accessorFn: (originalRow) =>
          originalRow.acceptanceNotification ? "true" : "false",
        filterVariant: "checkbox",
        Cell: ({ cell }) => (cell.getValue() === "true" ? "Yes" : "No"),
      },

      {
        header: "Finance notifications",
        accessorKey: "financeNotification",
        accessorFn: (originalRow) =>
          originalRow.financeNotification ? "true" : "false",
        filterVariant: "checkbox",
        Cell: ({ cell }) => (cell.getValue() === "true" ? "Yes" : "No"),
      },
      {
        header: "Calculated payment date",
        accessorKey: "paymentDate",
        filterVariant: "text",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <span>
              {row.original.paymentDate?.toString() !== "0001-01-01T00:00:00"
                ? moment(row.original.paymentDate).format("DD/MM/YYYY HH:mm")
                : ""}
            </span>
          </div>
        ),
      },
      {
        header: "Paid",
        accessorKey: "paid",
        accessorFn: (originalRow) => (originalRow.paid ? "true" : "false"),
        filterVariant: "checkbox",
        Cell: ({ cell }) => (cell.getValue() === "true" ? "Yes" : "No"),
      },
      {
        header: "Transfered",
        accessorKey: "transfered",
        accessorFn: (originalRow) =>
          originalRow.transfered ? "true" : "false",
        filterVariant: "checkbox",
        Cell: ({ cell }) => (cell.getValue() === "true" ? "Yes" : "No"),
      },
      {
        header: "PlacementExam",
        accessorKey: "hasPlacementExam",
        accessorFn: (originalRow) =>
          originalRow.hasPlacementExam ? "true" : "false",
        filterVariant: "checkbox",
        Cell: ({ cell }) => (cell.getValue() === "true" ? "Yes" : "No"),
      },
      {
        header: "Placement exam",
        accessorKey: "placementExamDate",
        filterVariant: "text",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <span>
              {row.original.hasPlacementExam
                ? moment(row.original.placementExamDate).format(
                    "DD/MM/YYYY HH:mm"
                  )
                : ""}
            </span>
          </div>
        ),
      },
    ],
    [majorOptions]
  );

  const sendNotification = () => {
    //console.log(Object.keys(rowSelection));
    handleOpenAcceptance();
  };

  return (
    <div style={{ width: "100%" }}>
      {" "}
      <header>
        <AdminHeader
          onTypeHandler={onTypeChangeHandler}
          title="Accepted students"
        />
        <div>
          <div>
            <div className="grid grid--5--cols">
              <input
                className="btn"
                onClick={sendNotification}
                value="Send a notification"
              />
            </div>
          </div>
        </div>
      </header>
      <section className={classes.listOfApplicants}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={dataTable}
            tableInstanceRef={tableInstanceRef}
            enablePagination={true}
            enableRowSelection={true}
            getRowId={(row) => row.id}
            onRowSelectionChange={setRowSelection}
            state={{
              rowSelection: rowSelection,
              showProgressBars: isRefetching,
            }}
            muiTableContainerProps={{
              sx: {
                minHeight: 0,
                maxHeight: "calc(100vh - 400px)",
              },
            }}
            initialState={{
              pagination: { pageSize: 100, pageIndex: 0 },
              showColumnFilters: true,
              columnPinning: { left: ["userName"], right: ["verified"] },
              columnVisibility: {
                id: false,
                serial: true,
                acceptedMajor: true,
                firstChoice: false,
                secondChoice: false,
                thirdChoice: false,
              },
              density: "compact",
            }}
            muiTableBodyCellProps={({ cell }) => ({
              onDoubleClick: (event) => {
                setindex(cell.row.index);
                handleOpen();
                dispatch(setAdminApplicantId(dataTable[cell.row.index].id));
              },
              sx: {
                cursor: "pointer",
              },
            })}
            enableRowNumbers
            muiTableBodyRowProps={({ row }) => ({
              onClick: (e) => {
                let stoploop = false;
                if (!e.shiftKey) {
                } else {
                  tableInstanceRef.current
                    ?.getRowModel()
                    .rows.forEach((element) => {
                      if (row.id === element.id) {
                        element.toggleSelected();
                        stoploop = true;
                      } else if (!stoploop) {
                        const firstChoice = document.getElementById(
                          "firstChoice" + element.original.userName
                        ) as HTMLInputElement | null;
                        firstChoice!.checked = true;
                        element.toggleSelected();
                      }
                    });
                }
              },
              sx: {
                cursor: "pointer",
              },
            })}
            rowNumberMode="static"
            positionToolbarAlertBanner="bottom"
          />
        </ThemeProvider>
      </section>
      <Modal
        show={open}
        onHide={handleClose}
        className={classes.modal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Applicant Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApplicantDashboard
            nextrow={() => nextPrevious(true)}
            previousrow={() => nextPrevious(false)}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={openAcceptance}
        onHide={handleCloseAcceptance}
        className={classes.modal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AcceptanceToMajorNotification
            listOfApplicants={Object.keys(rowSelection)}
            updateList={updateList}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
