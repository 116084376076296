import { createTheme } from "@mui/material";

export const tableTheme = createTheme({
  components: {
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#f1f3f5",
          },
          "& td": {
            fontSize: "1.3rem",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "1.563rem",
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: "1.7em",
          width: "1.7em",
        },
      },
    },
  },
});
