import axios from "axios";
import { IGroupOption } from "../../types/Codes/IOptions";
import { IAPIResponse } from "../../types/IAPIResponse";

export class RegionsWithCitiesAPI {
  async GetAll(): Promise<IAPIResponse<IGroupOption[]>> {
    const response = await axios.get<IAPIResponse<IGroupOption[]>>(
      "/codes/region/listofcities"
    );
    let regionswithcities: IAPIResponse<IGroupOption[]> = response.data;
    console.log(regionswithcities);
    return Promise.resolve(regionswithcities);
  }
}

export class RegionsWithSchoolsAPI {
  async GetAll(): Promise<IAPIResponse<IGroupOption[]>> {
    const response = await axios.get<IAPIResponse<IGroupOption[]>>(
      "/codes/region/regionswithschools"
    );
    let regionswithcities: IAPIResponse<IGroupOption[]> = response.data;
    return Promise.resolve(regionswithcities);
  }
}
