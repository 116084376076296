import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { UploadedDocumentResponseDTO } from "../../types/Applicant/UploadedDocumentDTO";

export class DashboardAPI {
  async GetUploadedDocuments(
    id: string | undefined
  ): Promise<IAPIResponse<UploadedDocumentResponseDTO[]>> {
    const response = await axios.get<IAPIResponse>("/admin/documents/" + id);
    let applicantInfo: IAPIResponse<UploadedDocumentResponseDTO[]> =
      response.data;
    return Promise.resolve(applicantInfo);
  }
}
