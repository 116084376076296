import React from "react";
import classes from "./NextPrevious.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NextPrevious = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={classes.nextPreviousButtons}>
      {props.pre ? (
        <button
          type="submit"
          className={"btn smallbtn"}
          onClick={props.onClickPrevious}
        >
          {t("NextPrevious_Previous")}
        </button>
      ) : (
        <div></div>
      )}
      {props.next ? (
        <button
          type="submit"
          className={"btn smallbtn"}
          onClick={props.onClickNext}
        >
          {t("NextPrevious_Next")}
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default NextPrevious;
