import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import Spinner from "../../Spinner/Spinner";
import NextPrevious from "../../Layout/NextPrevious";
import { useNavigate } from "react-router-dom";
import contact from "../../../assests/illustration/contact.svg";
import { toast } from "react-toastify";
import {
  setEmailVerified,
  setMobileVerified,
} from "../../../store/Slices/NotificationState";
import { setApplicantStatus } from "../../../store/Slices/ApplicantStatusState";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ContactInfoDTO } from "../../../types/Applicant/ContactInfoDTO";
import { RegionsWithCitiesAPI } from "../../../API/Applicant/RegionsAPI";
import { IGroupOption, IOption } from "../../../types/Codes/IOptions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import {
  ContactAndAddressInfoAPI,
  ContactAndAddressInfoUpdateAPI,
} from "../../../API/Applicant/ContactAndAddressInfoAPI";
import { RootState } from "../../../store/store";
import { setUpdatedRecord } from "../../../store/Slices/AdminApplicantId";

const formatGroupLabel = (data: IGroupOption) => (
  <div className="groupStyles">
    <span>{data.label}</span>
    {/* <span className="groupBadgeStyles">{data.options.length}</span> */}
  </div>
);

const ContactInfo = () => {
  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );

  const admin = useAppSelector((state: RootState) => state.admin);
  const freezedValue: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );
  const freezed =
    (!admin.allowToEdit && admin.userId !== undefined) || freezedValue;

  const { t } = useTranslation();
  const [wait, setWait] = useState<boolean>(false);

  const [previousMobileNo, setpreviousMobileNo] = useState<string | null>(null);
  const [previousEmail, setpreviousEmail] = useState<string | null>(null);

  const [regionswithcities, setRegionswithcities] = useState<IGroupOption[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<IOption>();
  const role: string = useAppSelector((state: RootState) => state.role.role);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ContactInfoDTO>();

  useEffect(() => {
    setWait(true);

    async function fetchData() {
      let regionswithcitiesAPI = new RegionsWithCitiesAPI();
      setRegionswithcities((await regionswithcitiesAPI.GetAll()).result);

      let contactInfo = new ContactAndAddressInfoAPI();
      let info = (await contactInfo.Get(admin.userId)).result;
      reset(info);
      setpreviousEmail(info.email);
      setpreviousMobileNo(info.mobile);
      (await regionswithcitiesAPI.GetAll()).result.forEach((r) =>
        r.options.forEach((o) =>
          o.value === info.cityId?.toString() ? setSelectedOption(o) : null
        )
      );
    }
    fetchData();
    setWait(false);
  }, [reset]);

  const onSubmit: SubmitHandler<ContactInfoDTO> = async (data) => {
    setWait(true);
    let contactAndAddressInfo = new ContactAndAddressInfoUpdateAPI();

    await contactAndAddressInfo.update(data, admin.userId).then((r) => {
      let res = r.result;

      dispatch(setEmailVerified(res.email));
      dispatch(setMobileVerified(res.phomeNumber));
      dispatch(setApplicantStatus(applicantStatus <= 6 ? 6 : applicantStatus));
      setWait(false);
      toast.success(t("ContactInfo_sent"));

      if (previousMobileNo !== data.mobile) {
        toast.success(t("ContactInfo_sentMessage"), { autoClose: false });
      }

      if (previousEmail !== data.email) {
        toast.success(t("ContactInfo_sentEmail"), {
          autoClose: false,
        });
      }
      dispatch(setUpdatedRecord(true));
    });
  };

  return (
    <div
      className={
        "container grid gridbox marginTopBottom" +
        (admin.userId !== undefined ? " admin" : "")
      }
    >
      <aside className="instruction">
        <p>{t("ContactInfo_instruction")}</p>
        <img
          className="instructionimg"
          src={contact}
          alt="Contact info, Bethlehem University"
        />
      </aside>
      {!wait ? (
        <div className="formBoxContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formBox">
              <div className="formBoxHeader">
                <div>
                  <p>{t("ContactInfo_Title")}</p>
                  {errors.mobile && errors.mobile?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("ContactInfo_mobile")!}
                    </p>
                  )}
                  {errors.email && (
                    <p className="errorMessage">{t("Error_Email")!}</p>
                  )}
                  {errors.mobile &&
                    (errors.mobile?.type === "minLength" ||
                      errors.mobile?.type === "maxLength") && (
                      <p className="errorMessage">{t("Error_Mobile")!}</p>
                    )}
                  {errors.cityId && errors.cityId?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("ContactInfo_City")!}
                    </p>
                  )}
                </div>
              </div>
              <div className={"grid grid--2--cols"}>
                <div>
                  <label htmlFor="material">{t("ContactInfo_mobile")!}</label>
                  <input
                    {...register("mobile", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                    })}
                    disabled={freezed}
                    className="textbox"
                    placeholder={t("0599000000")!}
                    type="textbox"
                  />
                </div>
                <div>
                  <label htmlFor="email">{t("ContactInfo_email")!}</label>
                  <input
                    {...register("email", {
                      required: true,
                      validate: {
                        minLength: (v) => v!.length >= 5,
                        matchPattern: (v) =>
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                            v!
                          ),
                      },
                    })}
                    disabled={freezed}
                    className="textbox"
                    placeholder={t("ContactInfo_email")!}
                    type="textbox"
                  />
                </div>
                <div>
                  <label htmlFor="city">{t("ContactInfo_City")!}</label>

                  <Controller
                    control={control}
                    name="cityId"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select<IOption, false, IGroupOption>
                        options={regionswithcities}
                        {...register("cityId", { required: true })}
                        formatGroupLabel={formatGroupLabel}
                        isDisabled={freezed}
                        onChange={(val) => {
                          setValue("cityId", val == null ? "-1" : val.value);
                          setSelectedOption(val == null ? selectedOption : val);
                        }}
                        value={selectedOption}
                      />
                    )}
                  />
                </div>
                <div>
                  <label htmlFor="street">{t("ContactInfo_Street")!}</label>
                  <input
                    {...register("street")}
                    className="textbox"
                    placeholder={t("ContactInfo_Street")!}
                    type="textbox"
                    disabled={freezed}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="telephone">
                    {t("ContactInfo_Telephone")!}
                  </label>
                  <input
                    {...register("telephone")}
                    className="textbox"
                    placeholder={t("ContactInfo_Telephone")!}
                    type="textbox"
                    disabled={freezed}
                  />
                </div>
              </div>
            </div>

            <div className="nextprevious">
              {!freezed && admin.userId === undefined ? (
                <NextPrevious
                  next={true}
                  pre={true}
                  onClickPrevious={handleSubmit((d) => {
                    onSubmit(d);
                    navigate("/applicant/info");
                  })}
                  onClickNext={handleSubmit((d) => {
                    onSubmit(d);
                    navigate(
                      role === "UNG"
                        ? "/applicant/schoolinfo"
                        : "/applicant/experiance"
                    );
                  })}
                />
              ) : null}
            </div>

            {admin.userId !== undefined && admin.allowToEdit ? (
              <div className="formBox">
                <button
                  className="btn"
                  onClick={handleSubmit((d) => {
                    onSubmit(d);
                    alert("Saved!");
                  })}
                >
                  Save
                </button>
              </div>
            ) : null}
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ContactInfo;
