import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CodesAPI } from "../../../API/Codes/CodesAPI";
import { ICode } from "../../../types/ICode";
import Spinner from "../../Spinner/Spinner";
import NextPrevious from "../../Layout/NextPrevious";
import { useNavigate } from "react-router-dom";
import personal from "../../../assests/illustration/personalinfo.svg";
import { toast } from "react-toastify";
import Select from "react-select";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  ApplicantInfoAPI,
  ApplicantInfoUpdateAPI,
} from "../../../API/Applicant/ApplicationInfoAPI";
import { PersonalInfoDTO } from "../../../types/Applicant/PersonalInfoDTO";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setApplicantStatus } from "../../../store/Slices/ApplicantStatusState";
import { RootState } from "../../../store/store";
import moment from "moment";
import { setUpdatedRecord } from "../../../store/Slices/AdminApplicantId";
import { IGroupOption, IOption } from "../../../types/Codes/IOptions";
import { RegionsWithCitiesAPI } from "../../../API/Applicant/RegionsAPI";

const ApplicantInfo = () => {
  const { t } = useTranslation();
  const [wait, setWait] = useState<boolean>(true);
  const navigate = useNavigate();
  const [materialStatus, setMaterialStatus] = useState<ICode[]>();
  const [genders, setGenders] = useState<ICode[]>();
  const [religions, setReligons] = useState<ICode[]>();
  const [secs, setsecs] = useState<ICode[]>();
  const [showsec, setshowsec] = useState<boolean>(false);
  const [shownationallity, setshownationallity] = useState<boolean>(false);
  const formatGroupLabel = (data: IGroupOption) => (
    <div className="groupStyles">
      <span>{data.label}</span>
      {/* <span className="groupBadgeStyles">{data.options.length}</span> */}
    </div>
  );
  const [regionswithcities, setRegionswithcities] = useState<IGroupOption[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<IOption>();

  const [idTypes, setIdTypes] = useState<ICode[]>();
  const [nationallity, setNationallity] = useState<ICode[]>();

  const [nameImported, setNameImported] = useState<boolean>(false);
  const [nameImportedAr, setNameImportedAr] = useState<boolean>(false);
  const [genderImported, setGenderImported] = useState<boolean>(false);
  const [birthdayImported, setBirthdayImported] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );

  const admin = useAppSelector((state: RootState) => state.admin);
  const freezedValue: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );

  const freezed =
    (!admin.allowToEdit && admin.userId !== undefined) || freezedValue;

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PersonalInfoDTO>();
  useEffect(() => {
    async function fetchData() {
      //Material status
      let materialStatus = new CodesAPI("/codes/materialstatus/");
      setMaterialStatus((await materialStatus.GetAll()).result);

      //ReglionsCodesAPI
      let religions = new CodesAPI("/codes/religion");
      setReligons((await religions.GetAll()).result);

      //gender
      let genders = new CodesAPI("/codes/gender");

      setGenders((await genders.GetAll()).result);

      let regionswithcitiesAPI = new RegionsWithCitiesAPI();
      const cities = (await regionswithcitiesAPI.GetAll()).result;
      setRegionswithcities(cities);

      //idtype
      let idType = new CodesAPI("/codes/idtype");
      setIdTypes((await idType.GetAll()).result);

      let applicantInfo = new ApplicantInfoAPI();
      let info = (await applicantInfo.Get(admin.userId)).result;
      cities.forEach((r) =>
        r.options.forEach((o) =>
          o.value === info.cityId?.toString() ? setSelectedOption(o) : null
        )
      );

      info.birthday =
        info.birthday !== undefined
          ? new Date(info.birthday)
          : moment(info.birthday).toDate();
      updateSecs(info.religionId).then(() => {
        if (info.religionId === null) {
          setshowsec(true);
        } else if (info.religionId > 1) {
          setshowsec(true);
        }
      });

      await updateNationallity(info.codesIdTypeId);
      reset(info);

      setNameImported(info.nameImported);
      setNameImportedAr(info.nameArImported);
      setGenderImported(info.genderImported);
      setBirthdayImported(info.birthdayImported);
    }
    fetchData();
    setWait(false);
  }, [reset]);

  const updateSecs = async (religion: number | null) => {
    if (religion === null || religion === 1) {
      return;
    }
    let secs = new CodesAPI("/codes/sec/" + religion);
    setsecs((await secs.GetAll()).result);
  };

  const updateNationallity = async (type: number | null) => {
    if (type !== 99) {
      setshownationallity(false);
      if (type === 5 || type === 6) {
        setValue("codesNationalityId", 1);
      } else {
        if (type === 7) {
          setValue("codesNationalityId", 3);
        }
      }
    } else {
      setValue("codesNationalityId", -1);
      setshownationallity(true);
      let nationality = new CodesAPI("/codes/nationallity");
      setNationallity((await nationality.GetAll()).result);
    }
  };

  const onSubmit: SubmitHandler<PersonalInfoDTO> = (data) => {
    toast.success(t("ApplicantInfo_sent"), {});
    setWait(true);
    let updateApplicantInfo = new ApplicantInfoUpdateAPI();
    updateApplicantInfo.update(data, admin.userId);
    dispatch(setApplicantStatus(applicantStatus <= 5 ? 5 : applicantStatus));
    setWait(false);
    dispatch(setUpdatedRecord(true));
  };

  return (
    <div
      className={
        "container grid gridbox marginTopBottom" +
        (admin.userId !== undefined ? " admin" : "")
      }
    >
      <aside className="instruction">
        <p>{t("ApplicantInfo_instruction")}</p>
        <img
          className="instructionimg"
          src={personal}
          alt="Contact info, Bethlehem University"
        />
      </aside>
      {!wait ? (
        <div className="formBoxContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formBox">
              <div className="formBoxHeader">
                <div>
                  <p>{t("ApplicantInfo_NameTitle")}</p>
                  {errors.firstNameAr &&
                    errors.firstNameAr?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! + t("ApplicantInfo_FirstNameAr")!}
                      </p>
                    )}
                  {errors.secondNameAr &&
                    errors.secondNameAr?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! +
                          t("ApplicantInfo_SecondNameAr")!}
                      </p>
                    )}
                  {errors.thirdNameAr &&
                    errors.thirdNameAr?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! + t("ApplicantInfo_ThirdNameAr")!}
                      </p>
                    )}

                  {errors.lastNameAr &&
                    errors.lastNameAr?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! + t("ApplicantInfo_LastNameAr")!}
                      </p>
                    )}

                  {errors.firstName &&
                    errors.firstName?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! +
                          t("ApplicantInfo_FirstNameEng")!}
                      </p>
                    )}
                  {errors.secondName &&
                    errors.secondName?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! +
                          t("ApplicantInfo_SecondNameEng")!}
                      </p>
                    )}
                  {errors.thirdName &&
                    errors.thirdName?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! +
                          t("ApplicantInfo_ThirdNameEng")!}
                      </p>
                    )}

                  {errors.lastName && errors.lastName?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("ApplicantInfo_LastNameEng")!}
                    </p>
                  )}
                </div>

                {/* <div className="helpbox">
                  <HelpCenterIcon />
                  {t("help")!}
                </div> */}
              </div>
              <div
                className={
                  "grid grid--4--cols" +
                  (admin.userId !== undefined ? " reverse" : "")
                }
              >
                <input
                  disabled={(nameImportedAr || freezed) && !admin.allowToEdit}
                  {...register("firstNameAr", { required: true })}
                  className={
                    "textbox" +
                    (nameImportedAr && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_FirstNameAr")!}
                  type="textbox"
                />
                <input
                  {...register("secondNameAr", { required: true })}
                  className={
                    "textbox" +
                    (nameImportedAr && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_SecondNameAr")!}
                  type="textbox"
                  disabled={(nameImportedAr || freezed) && !admin.allowToEdit}
                />
                <input
                  disabled={(nameImportedAr || freezed) && !admin.allowToEdit}
                  {...register("thirdNameAr", { required: true })}
                  className={
                    "textbox" +
                    (nameImportedAr && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_ThirdNameAr")!}
                  type="textbox"
                />
                <input
                  disabled={(nameImportedAr || freezed) && !admin.allowToEdit}
                  {...register("lastNameAr", { required: true })}
                  className={
                    "textbox" +
                    ((nameImportedAr || freezed) && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_LastNameAr")!}
                  type="textbox"
                />
                <input
                  disabled={(nameImported || freezed) && !admin.allowToEdit}
                  {...register("lastName", { required: true })}
                  className={
                    "textbox" +
                    ((nameImportedAr || freezed) && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_LastNameEng")!}
                  type="textbox"
                />
                <input
                  disabled={(nameImported || freezed) && !admin.allowToEdit}
                  {...register("thirdName", { required: true })}
                  className={
                    "textbox textboxLTR" +
                    ((nameImported || freezed) && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_ThirdNameEng")!}
                  type="textbox"
                />
                <input
                  disabled={(nameImported || freezed) && !admin.allowToEdit}
                  {...register("secondName", { required: true })}
                  className={
                    "textbox textboxLTR" +
                    (nameImported && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_SecondNameEng")!}
                  type="textbox"
                />
                <input
                  disabled={(nameImported || freezed) && !admin.allowToEdit}
                  {...register("firstName", { required: true })}
                  className={
                    "textbox textboxLTR" +
                    (nameImported && admin.userId !== undefined
                      ? " importedAdmin"
                      : "")
                  }
                  placeholder={t("ApplicantInfo_FirstNameEng")!}
                  type="textbox"
                />
              </div>
            </div>
            <div className="formBox">
              <div className="formBoxHeader">
                <div>
                  <p>{t("ApplicantInfo_InfoTitle", { required: true })}</p>
                  {errors.materialStatusId &&
                    errors.materialStatusId?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! +
                          t("ApplicantInfo_MaterialStatus")!}
                      </p>
                    )}
                  {errors.secId && errors.secId?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("ApplicantInfo_Religion")!}
                    </p>
                  )}
                  {errors.genderId && errors.genderId?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("ApplicantInfo_Gender")!}
                    </p>
                  )}
                  {errors.birthday && errors.birthday?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("ApplicantInfo_Birthday")!}
                    </p>
                  )}
                  {errors.cityId && errors.cityId?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("ApplicantInfo_Birthplace")!}
                    </p>
                  )}
                  {errors.codesIdTypeId &&
                    errors.codesIdTypeId?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! + t("ApplicantInfo_IdType")!}
                      </p>
                    )}
                  {errors.codesNationalityId &&
                    errors.codesNationalityId?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! + t("ApplicantInfo_Nationality")!}
                      </p>
                    )}
                </div>
                {/* <div className="helpbox">
                  <HelpCenterIcon />
                  {t("help")!}
                </div> */}
              </div>
              <div className={"grid grid--3--cols"}>
                <div>
                  <label htmlFor="material">
                    {t("ApplicantInfo_MaterialStatus")!}
                  </label>
                  <select
                    defaultValue={"-1"}
                    disabled={freezed}
                    id="material"
                    {...register("materialStatusId", { required: true })}
                  >
                    <option key={"-1"} value={"-1"} disabled>
                      {t("select")!}
                    </option>
                    {materialStatus &&
                      materialStatus?.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.nameAr}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="religion">
                    {t("ApplicantInfo_Religion")!}
                  </label>
                  <select
                    defaultValue={"-1"}
                    id="religion"
                    disabled={freezed}
                    {...register("religionId", { required: true })}
                    onChange={(val) => {
                      const religion = Number.parseInt(val.target?.value);
                      setshowsec(religion > 1);
                      if (religion === 1) {
                        setValue("secId", 1);
                      } else {
                        setValue("secId", null);
                      }
                      updateSecs(religion);
                    }}
                  >
                    <option key={"-1"} value={"-1"} disabled>
                      {t("select")!}
                    </option>
                    {religions &&
                      religions?.map((religion) => (
                        <option key={religion.id} value={religion.id}>
                          {religion.nameAr}
                        </option>
                      ))}
                  </select>
                </div>
                {showsec ? (
                  <div>
                    <label htmlFor="sec">{t("ApplicantInfo_Sec")!}</label>
                    <select
                      id="sec"
                      {...register("secId", { required: true })}
                      defaultValue={"-1"}
                      disabled={freezed}
                    >
                      <option key={"-1"} value={"-1"} disabled>
                        {t("select")!}
                      </option>
                      {secs &&
                        secs?.map((sec) => (
                          <option key={sec.id} value={sec.id}>
                            {sec.nameAr}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : null}
                <div>
                  <label htmlFor="gender">{t("ApplicantInfo_Gender")!}</label>
                  <select
                    defaultValue={"-1"}
                    className={
                      genderImported && admin.userId !== undefined
                        ? " importedAdmin"
                        : ""
                    }
                    id="gender"
                    {...register("genderId", { required: true })}
                    disabled={(genderImported || freezed) && !admin.allowToEdit}
                  >
                    <option key={"-1"} value={"-1"} disabled>
                      {t("select")!}
                    </option>
                    {genders &&
                      genders?.map((gender) => (
                        <option key={gender.id} value={gender.id}>
                          {gender.nameAr}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="inputwithflag">
                  <span>
                    {t("ApplicantInfo_Birthday", { required: true })!}
                  </span>
                  <Controller
                    control={control}
                    name="birthday"
                    render={({ field }) => (
                      <DatePicker
                        disabled={
                          (birthdayImported || freezed) && !admin.allowToEdit
                        }
                        placeholderText={t("ApplicantInfo_Birthday")!}
                        dateFormat="yyyy/MM/dd"
                        showIcon
                        className={
                          birthdayImported || admin.userId !== undefined
                            ? " importedAdmin"
                            : ""
                        }
                        minDate={moment().subtract(100, "years").toDate()}
                        maxDate={moment().subtract(17, "years").toDate()}
                        onChange={(date) => field.onChange(date)}
                        selected={
                          field.value !== undefined &&
                          field.value.getFullYear() === 1970
                            ? undefined
                            : moment(field.value).toDate()
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <label htmlFor="birthplace">
                    {t("ApplicantInfo_Birthplace")!}
                  </label>

                  <Controller
                    control={control}
                    name="cityId"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select<IOption, false, IGroupOption>
                        options={regionswithcities}
                        {...register("cityId", { required: true })}
                        formatGroupLabel={formatGroupLabel}
                        isDisabled={freezed}
                        onChange={(val) => {
                          setValue(
                            "cityId",
                            val == null ? -1 : Number(val.value)
                          );
                          setSelectedOption(val == null ? selectedOption : val);
                        }}
                        value={selectedOption}
                      />
                    )}
                  />
                </div>
                <div className="inputwithflag">
                  <span>{t("ApplicantInfo_Id")!} </span>
                  <input
                    className="textbox"
                    disabled={admin.userId === undefined || !admin.allowToEdit}
                    {...register("idNo", { required: true })}
                    placeholder="4xxxxxxxx"
                    type="text"
                  />
                </div>
                <div>
                  <label htmlFor="idtype">{t("ApplicantInfo_IdType")!}</label>
                  <select
                    defaultValue={"-1"}
                    id="idtype"
                    {...register("codesIdTypeId", { required: true })}
                    onChange={async (e) =>
                      await updateNationallity(Number.parseInt(e.target.value))
                    }
                    disabled={freezed}
                  >
                    <option key={"-1"} value={"-1"} disabled>
                      {t("select")!}
                    </option>
                    {idTypes &&
                      idTypes?.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.nameAr}
                        </option>
                      ))}
                  </select>
                </div>
                {shownationallity ? (
                  <div>
                    <label htmlFor="nationality">
                      {t("ApplicantInfo_Nationality")!}
                    </label>
                    <select
                      id="nationallity"
                      {...register("codesNationalityId", { required: true })}
                      defaultValue={"-1"}
                      disabled={freezed}
                    >
                      <option key={"-1"} value={"-1"} disabled>
                        {t("select")!}
                      </option>
                      {nationallity &&
                        nationallity?.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.nameAr}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          {!freezed && admin.userId === undefined ? (
            <div className="formBox nextprevious">
              <NextPrevious
                next={true}
                pre={false}
                onClickNext={handleSubmit((d) => {
                  onSubmit(d);
                  navigate("/applicant/contact");
                })}
              />
            </div>
          ) : null}
          {admin.userId !== undefined && admin.allowToEdit ? (
            <div className="formBox">
              <button
                className="btn"
                onClick={handleSubmit((d) => {
                  onSubmit(d);
                  alert("Saved!");
                })}
              >
                Save
              </button>
            </div>
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ApplicantInfo;
