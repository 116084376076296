import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { IOption } from "../../types/Codes/IOptions";
import { ApplicationTempletesEmail } from "../types/EmailTempletes";

export class TempletesEmailAPI {
  async GetAllTempletes(registrar: boolean): Promise<IAPIResponse<IOption[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/templates/email/getAllTempletes/" + registrar
    );
    let templetes: IAPIResponse<IOption[]> = response.data;
    return Promise.resolve(templetes);
  }

  async GetATemplete(
    id: number
  ): Promise<IAPIResponse<ApplicationTempletesEmail>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/templates/email/getatemplete/" + id
    );
    let templetes: IAPIResponse<ApplicationTempletesEmail> = response.data;
    return Promise.resolve(templetes);
  }
}
