import axios from "axios";
import {
  AcceptAnApplicantIndividually,
  AcceptApplicantDTO,
  AcceptListOfApplicantsDTO,
  ActiveListDTO,
} from "../../types/Admin/ApplicantsDTO";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  PrintRankingListDTO,
  RankingListDTO,
  RemoveApplicantsFromRankingListDTO,
} from "../../types/Admin/RankingListsDTO";

export class RankingListAPI {
  async VirtualRankingList(
    applicationType: string
  ): Promise<IAPIResponse<RankingListDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/rankinglist/virtualrankinglist/" + applicationType
    );
    let applicantInfo: IAPIResponse<RankingListDTO[]> = response.data;
    return Promise.resolve(applicantInfo);
  }

  async GetRankingList(
    applicationType: string,
    activeList: Number
  ): Promise<IAPIResponse<RankingListDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/rankinglist/freezedrankingList/" +
        applicationType +
        "/" +
        activeList
    );
    let applicantInfo: IAPIResponse<RankingListDTO[]> = response.data;

    return Promise.resolve(applicantInfo);
  }

  async PrintRankingList(
    applicationType: string,
    activeList: Number,
    majorAbbr: string
  ): Promise<IAPIResponse<PrintRankingListDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/rankinglist/printarankinglist/" +
        applicationType +
        "/" +
        activeList +
        "/" +
        majorAbbr
    );
    console.log(response);
    let applicantInfo: IAPIResponse<PrintRankingListDTO[]> = response.data;

    return Promise.resolve(applicantInfo);
  }

  async GetActiveList(
    applicationType: string
  ): Promise<IAPIResponse<ActiveListDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/rankinglist/activelist/" + applicationType
    );
    let applicantInfo: IAPIResponse<ActiveListDTO> = response.data;
    return Promise.resolve(applicantInfo);
  }

  async CalculateAndFreeze(
    applicationType: string
  ): Promise<IAPIResponse<ActiveListDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/rankinglist/calculateAndFreeze/" + applicationType
    );
    let applicantInfo: IAPIResponse<ActiveListDTO> = response.data;
    return Promise.resolve(applicantInfo);
  }

  async AcceptList(
    applicants: AcceptListOfApplicantsDTO
  ): Promise<IAPIResponse<boolean>> {
    console.log(applicants);
    const response = await axios.post<IAPIResponse>(
      "/admin/acceptance/acceptlistofapplicants/",
      applicants
    );
    let list: IAPIResponse<boolean> = response.data;
    return Promise.resolve(list);
  }

  async Unfreeze(activeList: number): Promise<IAPIResponse<RankingListDTO[]>> {
    const response = await axios.put<IAPIResponse>(
      "/admin/rankinglist/unfreeze/" + activeList
    );
    let list = response.data;
    return Promise.resolve(list);
  }

  async AcceptIndividually(
    request: AcceptAnApplicantIndividually
  ): Promise<IAPIResponse<string>> {
    const response = await axios.post<IAPIResponse>(
      "/admin/acceptance/acceptindividually/",
      request
    );
    let individual = response.data;
    return Promise.resolve(individual);
  }

  async RemoveAcceptanceIndividually(
    applicantId: string
  ): Promise<IAPIResponse<string>> {
    const response = await axios.post<IAPIResponse>(
      "/admin/acceptance/removeacceptanceindvidually/" + applicantId
    );
    let individual = response.data;
    return Promise.resolve(individual);
  }

  async RemoveFromRankingList(
    data: RemoveApplicantsFromRankingListDTO
  ): Promise<IAPIResponse<string>> {
    const response = await axios.post<IAPIResponse>(
      "/admin/rankinglist/removefromrankinglist",
      data
    );
    let res = response.data;
    return Promise.resolve(res);
  }
}
