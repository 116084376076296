import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
export class VerifyMobileAPI {
  async VerifyMobileNumber(token: string): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/verifiynotifications/sms",
      { token: token }
    );
    let verifyNumber: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(verifyNumber);
    });
  }

  async sendCode(): Promise<IAPIResponse> {
    const response = await axios.put<IAPIResponse>(
      "/applicant/contactandaddress/sendmobilecode"
    );
    let request: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(request);
    });
  }
}

export class VerifyEmailAPI {
  async VerifyEmail(id: string, token: string): Promise<IAPIResponse> {
    const response = await axios.put<IAPIResponse>(
      "/verifiynotifications/email",
      {
        id: id,
        token: token,
      }
    );
    let verifyNumber: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(verifyNumber);
    });
  }

  async sendEmail(): Promise<IAPIResponse> {
    const response = await axios.put<IAPIResponse>(
      "/applicant/contactandaddress/sendemailtoken"
    );
    let request: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(request);
    });
  }
}
