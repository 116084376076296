import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Spinner from "../../Spinner/Spinner";
import NextPrevious from "../../Layout/NextPrevious";
import { useNavigate } from "react-router-dom";
import school from "../../../assests/illustration/choices.svg";
import classes from "./Choices.module.css";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { IGroupOption, IOption } from "../../../types/Codes/IOptions";
import { ChoicesRequestDTO } from "../../../types/Applicant/ChoicesDTO";
import {
  ChoicesAPI,
  UpdateChoicesAPI,
} from "../../../API/Applicant/ChoicesAPI";

import { toast } from "react-toastify";
import { setApplicantStatus } from "../../../store/Slices/ApplicantStatusState";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { setUpdatedRecord } from "../../../store/Slices/AdminApplicantId";

const formatGroupLabel = (data: IGroupOption) => (
  <div className="groupStyles">
    <span>{data.label}</span>
    {/* <span className="groupBadgeStyles">{data.options.length}</span> */}
  </div>
);

const Choices = () => {
  const { t } = useTranslation();

  const [wait, setWait] = useState<boolean>(false);
  const [firstchoices, setFirstChoices] = useState<IGroupOption[]>([]);
  const [choices, setChoices] = useState<IGroupOption[]>([]);
  const [selectedFirstChoice, setSelectedFirstChoice] = useState<IOption>();
  const [selectedSecondChoice, setSelectedSecondChoice] = useState<IOption>();
  const [selectedThirdChoice, setSelectedThirdChoice] = useState<IOption>();
  const [allowthirdchoice, setAllowedThirdChoice] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );

  const admin = useAppSelector((state: RootState) => state.admin);
  const freezedValue: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );

  const freezed =
    (!admin.allowToEdit && admin.userId !== undefined) || freezedValue;

  const role: string = useAppSelector((state: RootState) => state.role.role);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ChoicesRequestDTO>();
  useEffect(() => {
    setWait(true);

    async function fetchData() {
      let choices = new ChoicesAPI();
      setChoices((await choices.GetListOfChoices(admin.userId)).result);
      const firstChoices = (await choices.GetListOfFirstChoices(admin.userId))
        .result;
      setFirstChoices(firstChoices);
      setAllowedThirdChoice((await choices.GetAllowedThirdChoice()).result);
      const selectedchoices = (await choices.GetSelectedChoices(admin.userId))
        .result;
      if (selectedchoices == null) return; //no datafound
      const firstChoice = selectedchoices.find(
        (c) => c.choiceOrder === 1
      )?.applicantsMajorId;
      const secondChoice = selectedchoices.find(
        (c) => c.choiceOrder === 2
      )?.applicantsMajorId;
      const thirdChoice = selectedchoices.find(
        (c) => c.choiceOrder === 3
      )?.applicantsMajorId;

      reset({
        firstChoice: firstChoice,
        secondChoice: secondChoice,
        thirdChoice: thirdChoice,
      });

      firstChoices.forEach((c) =>
        c.options.forEach((c) => {
          if (c.value === firstChoice?.toString()) {
            setSelectedFirstChoice(c);
          }

          if (c.value === secondChoice?.toString()) {
            setSelectedSecondChoice(c);
          }

          if (c.value === thirdChoice?.toString()) {
            setSelectedThirdChoice(c);
          }
        })
      );
    }
    fetchData();
    setWait(false);
  }, [reset, admin]);

  const onSubmit: SubmitHandler<ChoicesRequestDTO> = (data) => {
    if (admin.userId === undefined) {
      toast.success(t("Choices_sent"));
    }
    setWait(true);
    if (data.thirdChoice == null) {
      data.thirdChoice = -1;
    }
    let updateChoicesAPI = new UpdateChoicesAPI(data);
    updateChoicesAPI.UpdateChoices(admin.userId);
    dispatch(setApplicantStatus(applicantStatus <= 9 ? 9 : applicantStatus));
    setWait(false);
    dispatch(setUpdatedRecord(true));
  };

  return (
    <div
      className={
        "container grid gridbox marginTopBottom" +
        (admin.userId !== undefined ? " admin" : "")
      }
    >
      <aside className="instruction">
        <p>{t("Choices_Instruction")}</p>
        <img
          className="instructionimg"
          src={school}
          alt="Choices info, Bethlehem University"
        />
      </aside>
      {!wait ? (
        <div className="formBoxContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formBox">
              <div className="formBoxHeader">
                <p>{t("Choices_Title")}</p>
                {/* <div className="helpbox">
                  <HelpCenterIcon />
                  {t("help")!}
                </div> */}
              </div>
              <div className={"grid grid--2--cols"}>
                <div className={classes.choice}>
                  {errors.firstChoice &&
                    errors.firstChoice?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! + t("Choices_First")!}
                      </p>
                    )}
                  <label htmlFor="firstChoice">{t("Choices_First")!}</label>
                  <Controller
                    control={control}
                    name="firstChoice"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select<IOption, false, IGroupOption>
                        options={firstchoices}
                        {...register("firstChoice", { required: true })}
                        formatGroupLabel={formatGroupLabel}
                        isDisabled={freezed}
                        onChange={(val) => {
                          setValue("firstChoice", Number.parseInt(val!.value!));
                          setSelectedFirstChoice(val!);
                        }}
                        value={selectedFirstChoice}
                      />
                    )}
                  />
                </div>

                <div className={classes.choice}>
                  {errors.firstChoice &&
                    errors.firstChoice?.type === "required" && (
                      <p className="errorMessage">
                        {t("Error_Required")! + t("Choices_Second")!}
                      </p>
                    )}
                  <label htmlFor="secondChoice">{t("Choices_Second")!}</label>
                  <Controller
                    control={control}
                    name="secondChoice"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select<IOption, false, IGroupOption>
                        {...register("secondChoice", { required: true })}
                        options={choices}
                        isDisabled={freezed}
                        formatGroupLabel={formatGroupLabel}
                        onChange={(val) => {
                          setValue(
                            "secondChoice",
                            Number.parseInt(val!.value!)
                          );
                          setSelectedSecondChoice(val!);
                        }}
                        value={selectedSecondChoice}
                      />
                    )}
                  />

                  {admin.userId !== undefined || allowthirdchoice ? (
                    <div className={classes.choice}>
                      <label htmlFor="thirdChoice">{t("Choices_Third")!}</label>
                      <Controller
                        control={control}
                        name="thirdChoice"
                        render={({ field: { onChange, value, ref } }) => (
                          <Select<IOption, false, IGroupOption>
                            {...register("thirdChoice")}
                            options={choices}
                            formatGroupLabel={formatGroupLabel}
                            onChange={(val) => {
                              setValue(
                                "thirdChoice",
                                Number.parseInt(val!.value!)
                              );
                              setSelectedThirdChoice(val!);
                            }}
                            isDisabled={
                              freezed &&
                              (!allowthirdchoice || admin.allowToEdit)
                            }
                            value={selectedThirdChoice}
                          />
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>

          {admin.userId !== undefined && admin.allowToEdit ? (
            <div className="formBox">
              <button
                className="btn"
                onClick={handleSubmit((d) => {
                  onSubmit(d);
                  alert("Saved!");
                })}
              >
                Save
              </button>
            </div>
          ) : (
            <>
              {(!freezed || allowthirdchoice) && admin.userId === undefined ? (
                <NextPrevious
                  next={true}
                  pre={true}
                  onClickPrevious={handleSubmit((d) => {
                    onSubmit(d);
                    role === "UNG"
                      ? navigate("/applicant/schoolinfo")
                      : navigate("/applicant/experiance");
                  })}
                  onClickNext={handleSubmit((d) => {
                    onSubmit(d);
                    navigate("/applicant/snh");
                  })}
                />
              ) : null}
            </>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Choices;
