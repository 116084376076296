import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import { LeftNavigation } from "./leftNavigation";
import styles from "./Dashboard.module.scss";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

export const DashboardContainer = (): JSX.Element => {
  const [opened, setOpened] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  useEffect(() => {
    setOpened(isDesktop);
  }, [isDesktop]);

  const onMenuOpened = () => {
    setOpened(!opened);
  };

  const additionalClass = opened ? styles["opened"] : "";
  const arrowAdditonalClass = opened ? styles["arrow-rotate"] : "";

  return (
    <div className={`${styles["collapse-container"]}`}>
      <div className={`${styles["left-menu-container"]} ${additionalClass} `}>
        <LeftNavigation opened={opened} />
        <MediaQuery minWidth={992}>
          <div className={styles["collapse-icon-container"]}>
            <div onClick={onMenuOpened} className={styles["collapse-icon"]}>
              <span className={arrowAdditonalClass}>
                <IoArrowForward />
              </span>
            </div>
          </div>
        </MediaQuery>
      </div>
      <div className={styles["right-menu-container"]}>
        <Outlet />
      </div>
    </div>
  );
};
