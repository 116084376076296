import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  SchoolInfoRequestDTO,
  SchoolInfoResponseDTO,
} from "../../types/Applicant/SchoolInfoDto";
export class UpdateSchoolInfoAPI {
  constructor(public schoolinfo: SchoolInfoRequestDTO) {}
  async update(userId?: string): Promise<IAPIResponse> {
    const response = await axios.put<IAPIResponse>(
      "/applicant/schoolinfo" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      this.schoolinfo
    );
    let schoolinforesponse: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(schoolinforesponse);
    });
  }
}

export class GetSchoolInfoAPI {
  async Get(userId?: string): Promise<IAPIResponse<SchoolInfoResponseDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/applicant/schoolinfo" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let schoolinforesponse: IAPIResponse<SchoolInfoResponseDTO> = response.data;
    return new Promise<IAPIResponse<SchoolInfoResponseDTO>>(
      (resolve, reject) => {
        resolve(schoolinforesponse);
      }
    );
  }
}
