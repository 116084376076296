import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { SpecialNeedDTO } from "../../types/Applicant/SpecialNeedAndHumRequestDTO";

export class SpecialNeedAPI {
  async GetSpecialNeed(userId?: string): Promise<IAPIResponse<SpecialNeedDTO>> {
    const response = await axios.get<IAPIResponse<SpecialNeedDTO>>(
      "/applicant/specialneed" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let specialNeed: IAPIResponse<SpecialNeedDTO> = response.data;
    return new Promise<IAPIResponse<SpecialNeedDTO>>((resolve, reject) => {
      resolve(specialNeed);
    });
  }

  async UpdateSpecialNeed(
    specialneed: SpecialNeedDTO,
    userId?: string
  ): Promise<IAPIResponse<SpecialNeedDTO>> {
    const response = await axios.post<IAPIResponse<SpecialNeedDTO>>(
      "/applicant/specialneed" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      specialneed
    );
    let specialNeed: IAPIResponse<SpecialNeedDTO> = response.data;
    return new Promise<IAPIResponse<SpecialNeedDTO>>((resolve, reject) => {
      resolve(specialNeed);
    });
  }

  async DeleteSpecialNeed(
    userId?: string
  ): Promise<IAPIResponse<SpecialNeedDTO>> {
    const response = await axios.delete<IAPIResponse<SpecialNeedDTO>>(
      "/applicant/specialneed" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let specialNeed: IAPIResponse<SpecialNeedDTO> = response.data;
    return new Promise<IAPIResponse<SpecialNeedDTO>>((resolve, reject) => {
      resolve(specialNeed);
    });
  }

  async UpdateStatus(userId?: string): Promise<IAPIResponse<SpecialNeedDTO>> {
    const response = await axios.post<IAPIResponse<SpecialNeedDTO>>(
      "/applicant/specialneed/updateStatus" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let specialNeed: IAPIResponse<SpecialNeedDTO> = response.data;
    return new Promise<IAPIResponse<SpecialNeedDTO>>((resolve, reject) => {
      resolve(specialNeed);
    });
  }
}
