import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  UploadedDocumentRequestDTO,
  UploadedDocumentResponseDTO,
  UploadedOtherDocumentRequestDTO,
  UploadedOtherDocumentResponseDTO,
} from "../../types/Applicant/UploadedDocumentDTO";
export class Documents {
  async GetUploadedDocuments(): Promise<
    IAPIResponse<UploadedDocumentResponseDTO[]>
  > {
    const response = await axios.get<
      IAPIResponse<UploadedDocumentResponseDTO[]>
    >("/applicant/documents");
    let listOfChoices: IAPIResponse<UploadedDocumentResponseDTO[]> =
      response.data;
    return new Promise<IAPIResponse<UploadedDocumentResponseDTO[]>>(
      (resolve, reject) => {
        resolve(listOfChoices);
      }
    );
  }

  async GetUploadedOtherDocuments(): Promise<
    IAPIResponse<UploadedOtherDocumentResponseDTO[]>
  > {
    const response = await axios.get<
      IAPIResponse<UploadedOtherDocumentResponseDTO[]>
    >("/applicant/documents/otherdocuments");
    let listOfChoices: IAPIResponse<UploadedOtherDocumentResponseDTO[]> =
      response.data;
    return new Promise<IAPIResponse<UploadedOtherDocumentResponseDTO[]>>(
      (resolve, reject) => {
        resolve(listOfChoices);
      }
    );
  }

  async DeleteDocument(
    id: number
  ): Promise<IAPIResponse<UploadedDocumentResponseDTO>> {
    const response = await axios.delete<
      IAPIResponse<UploadedDocumentResponseDTO>
    >("/applicant/documents/" + id);
    let newdocument: IAPIResponse<UploadedDocumentResponseDTO> = response.data;
    return new Promise<IAPIResponse<UploadedDocumentResponseDTO>>(
      (resolve, reject) => {
        resolve(newdocument);
      }
    );
  }

  async DeleteOtherDocument(
    id: number
  ): Promise<IAPIResponse<UploadedOtherDocumentResponseDTO[]>> {
    const response = await axios.delete<
      IAPIResponse<UploadedOtherDocumentResponseDTO[]>
    >("/applicant/documents/otherdocument/" + id);
    let otherdocument: IAPIResponse<UploadedOtherDocumentResponseDTO[]> =
      response.data;
    return new Promise<IAPIResponse<UploadedOtherDocumentResponseDTO[]>>(
      (resolve, reject) => {
        resolve(otherdocument);
      }
    );
  }
}

export class UploadDocument {
  async UploadDocument(
    document: UploadedDocumentRequestDTO
  ): Promise<IAPIResponse<UploadedDocumentResponseDTO>> {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const payload = {
      id: document.id,
      formFile: document.formFile,
    };

    const response = await axios.post<
      IAPIResponse<UploadedDocumentResponseDTO>
    >("/applicant/documents", payload, config);
    let newdocument: IAPIResponse<UploadedDocumentResponseDTO> = response.data;
    return new Promise<IAPIResponse<UploadedDocumentResponseDTO>>(
      (resolve, reject) => {
        resolve(newdocument);
      }
    );
  }

  async UploadOtherDocument(
    document: UploadedOtherDocumentRequestDTO
  ): Promise<IAPIResponse<UploadedOtherDocumentResponseDTO>> {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const payload = {
      note: document.note,
      formFile: document.formFile,
    };

    const response = await axios.post<
      IAPIResponse<UploadedOtherDocumentResponseDTO>
    >("/applicant/documents/otherdocuments", payload, config);
    let newdocument: IAPIResponse<UploadedOtherDocumentResponseDTO> =
      response.data;
    return new Promise<IAPIResponse<UploadedOtherDocumentResponseDTO>>(
      (resolve, reject) => {
        resolve(newdocument);
      }
    );
  }
}
