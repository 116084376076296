import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { NavigationItem } from "./NavigationItem";
import styles from "./LeftNavigation.module.scss";
import { IoList, IoListCircleSharp } from "react-icons/io5";
import Cookies from "js-cookie";

export interface NavigationMenuItem {
  title: string;
  icon: React.ReactNode;
  path: string;
  allowedRoles: string[];
}

const getNavigationList = (
  t: TFunction<"translation">
): Array<NavigationMenuItem> => [
  {
    title: "List of applicants",
    icon: <IoList />,
    path: "/admin/listofapplicants",
    allowedRoles: [
      "ADM",
      "BUS",
      "SOC",
      "REG",
      "TOU",
      "NUR",
      "AVP",
      "NUI",
      "POT",
      "REO",
      "MOC",
      "SPN",
      "HDP",
      "FIN",
      "HDM",
      "MAS",
    ],
  },
  {
    title: "Ranking list",
    icon: <IoListCircleSharp />,
    path: "/admin/rankingList",
    allowedRoles: ["ADM", "REG", "AVP"],
  },
  {
    title: "Accepted to major",
    icon: <IoListCircleSharp />,
    path: "/admin/acceptedmajor",
    allowedRoles: ["ADM", "REG", "AVP", "FIN"],
  },
  {
    title: "Assigend to interview",
    icon: <IoListCircleSharp />,
    path: "/admin/assignedtoInterview",
    allowedRoles: ["ADM", "REG", "AVP", "NUI", "POT"],
  },
];

export const LeftNavigation = ({
  opened,
}: {
  opened: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const base = `${pathname.slice(1).split("/").shift()}`;

  const onNavigationSelect = (path: string, index: number) => {
    navigate(path);
    setSelectedIndex(index);
  };

  const isItemSelected = (path: string, index: number): boolean => {
    return path === base || (base === "" && index === selectedIndex);
  };
  const role = Cookies.get("role");

  return (
    <div className={styles["left-navigation"]}>
      {getNavigationList(t).map((item, index) => {
        return (
          <>
            {item.allowedRoles.includes(role ?? "") ? (
              <NavigationItem
                key={`navMenu_${index}`}
                {...item}
                opened={opened}
                isSelected={isItemSelected(item.path, index)}
                onSelect={() => {
                  onNavigationSelect(item.path, index);
                }}
              />
            ) : null}
          </>
        );
      })}
    </div>
  );
};
