import { useNavigate } from "react-router";
import styles from "./Header.module.scss";
import Logo from "./logo.png";
import { RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import i18n from "../../../i18n";
import Cookies from "js-cookie";
import { setAdminApplicantId } from "../../../store/Slices/AdminApplicantId";

export const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const uname: string = useAppSelector(
    (state: RootState) => state.role.username
  );
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(setAdminApplicantId(undefined));
    document.body.style.direction = i18n.dir();
    localStorage.clear();
    navigate("/");
    Cookies.remove("role");
    Cookies.remove("appType");
    Cookies.remove("semester");
  };

  return (
    <div className={styles["header"]}>
      <img src={Logo} className={styles["header-logo"]} />
      <div className={styles["header-title"]}>Admission admin portal</div>
      <div className={styles["header-content"]}>
        <div className={styles["info"]}>
          <span>Welcome, {uname}</span>
          <button className="btn" onClick={logoutHandler}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};
