import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import BankstampImg from "../../../../assests/bankstamp.gif";
import moment from "moment";
import { InvoiceBankType } from "./BankstampType";

const BankStamp = (props: InvoiceBankType) => {
  useEffect(() => {
    if (props.semesterCode === -1) {
      return;
    }

    //Format student No
    const zeroPad = (num: number, places: number) =>
      String(num).padStart(places, "0");
    if (props.semesterCode == null) {
      return;
    }
    const semster =
      props.semesterCode.toString().substring(0, 1) +
      props.semesterCode.toString().substring(2, 5);

    const canvas = document.getElementById("canvas")! as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");

    let img = new Image();
    if (ctx != null) {
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
        ctx.font = "700 18px 'Inter, Courier, monospace'";
        ctx.fillText("Student No: " + zeroPad(props.studentNumber, 7), 12, 25);
        ctx.fillText("Name: " + props.studentName, 12, 50);
        ctx.fillText(
          "Semester Code:              <--------------------",
          12,
          75
        );
        ctx.font = "700 18px 'Inter, Courier, monospace'";
        ctx.fillText(semster, 140, 75);
        ctx.fillText(
          "Minimum payment: **" +
            (props.minimumPayment || props.balance) +
            (props.jdCurrency ? " JD" : " ₪"),
          12,
          100
        );
        ctx.fillText(
          "Last date for payment: " +
            moment(props.lastDate).format("DD/MM/YYYY"),
          12,
          125
        );
        ctx.font = "700 14px 'Inter, Courier, monospace'";
        ctx.fillText("Printed On: " + moment().format("DD/MM/YYYY"), 110, 150);
      };
    }
    img.src = BankstampImg;
  });
  return (
    <div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Grid>
          <canvas id="canvas" width="371" height="167"></canvas>
        </Grid>
      </div>
    </div>
  );
};

export default BankStamp;
