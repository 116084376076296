import React, { useEffect } from "react";
import classes from "./Step.module.css";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";

const Step = (props: any) => {
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );
  return (
    <li>
      {(props.showOn !== undefined && props.showOn.includes(applicantStatus)) ||
      props.stepNumber == null ? (
        <NavLink
          onClick={props.onClick}
          to={props.path}
          className={({ isActive }) =>
            isActive && props.stepNumber
              ? classes.step + " " + classes.stepActive
              : classes.step
          }
        >
          {props.stepNumber != null ? (
            <div className={classes.stepNumber}>{props.stepNumber}</div>
          ) : null}
          <div className={classes.desc}>{props.title}</div>
        </NavLink>
      ) : (
        <div className={classes.step + " " + classes.stepDisabled}>
          <div
            className={classes.stepNumber + " " + classes.stepNumberDisabled}
          >
            {props.stepNumber}
          </div>
          <div className={classes.desc}>{props.title}</div>
        </div>
      )}
    </li>
  );
};

export default Step;
