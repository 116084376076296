import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  AssignedListOfApplicantsDTO,
  InterviewResultDTO,
  ListOfApplicantsAssignedToInterviewDTO,
} from "../types/InterviewList";
import { IOption } from "../../types/Codes/IOptions";

export class InterviewListAPI {
  async GetAllAssignedApplicants(
    appType: string
  ): Promise<IAPIResponse<ListOfApplicantsAssignedToInterviewDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/interviews/getlistofapplicants/" + appType
    );
    let templetes: IAPIResponse<ListOfApplicantsAssignedToInterviewDTO[]> =
      response.data;
    return Promise.resolve(templetes);
  }

  async InterviewResultList(): Promise<IAPIResponse<IOption[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/interviews/interviewresultlist/"
    );
    let templetes: IAPIResponse<IOption[]> = response.data;
    return Promise.resolve(templetes);
  }

  async AssignResult(
    result: InterviewResultDTO
  ): Promise<IAPIResponse<boolean>> {
    const response = await axios.post<IAPIResponse>(
      "/admin/interviews/assignresult/",
      result
    );
    let templetes: IAPIResponse<boolean> = response.data;
    return Promise.resolve(templetes);
  }

  async AssignAList(
    list: AssignedListOfApplicantsDTO
  ): Promise<IAPIResponse<boolean>> {
    const response = await axios.post<IAPIResponse>(
      "/admin/interviews/assignListOfApplicants",
      list
    );
    let templetes: IAPIResponse<boolean> = response.data;
    return Promise.resolve(templetes);
  }
}
