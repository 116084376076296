import axios from "axios";
import { IGroupOption, IOption } from "../../types/Codes/IOptions";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  ChoicesRequestDTO,
  ChoicesResponseDTO,
} from "../../types/Applicant/ChoicesDTO";

export class ChoicesAPI {
  async GetStudentChoices(userId?: string): Promise<IAPIResponse<IOption[]>> {
    const response = await axios.get<IAPIResponse<IOption[]>>(
      "/applicant/choices/getStudentChoices" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let listOfChoices: IAPIResponse<IOption[]> = response.data;
    return Promise.resolve(listOfChoices);
  }

  async GetListOfFirstChoices(
    userId?: string
  ): Promise<IAPIResponse<IGroupOption[]>> {
    const response = await axios.get<IAPIResponse<IGroupOption[]>>(
      "/applicant/choices/firstchoicelist" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let listOfChoices: IAPIResponse<IGroupOption[]> = response.data;
    return Promise.resolve(listOfChoices);
  }

  async GetAllowedThirdChoice(): Promise<IAPIResponse<boolean>> {
    const response = await axios.get<IAPIResponse<boolean>>(
      "/applicant/choices/allowthirdchoice"
    );
    let allowedThirdChoice: IAPIResponse<boolean> = response.data;
    return Promise.resolve(allowedThirdChoice);
  }

  async GetListOfChoices(
    userId?: string
  ): Promise<IAPIResponse<IGroupOption[]>> {
    const response = await axios.get<IAPIResponse<IGroupOption[]>>(
      "/applicant/choices/list" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let listOfChoices: IAPIResponse<IGroupOption[]> = response.data;
    return new Promise<IAPIResponse<IGroupOption[]>>((resolve, reject) => {
      resolve(listOfChoices);
    });
  }

  async GetSelectedChoices(
    userId?: string
  ): Promise<IAPIResponse<ChoicesResponseDTO[]>> {
    const response = await axios.get<IAPIResponse<ChoicesResponseDTO[]>>(
      "/applicant/choices" + (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let choices: IAPIResponse<ChoicesResponseDTO[]> = response.data;
    return new Promise<IAPIResponse<ChoicesResponseDTO[]>>(
      (resolve, reject) => {
        resolve(choices);
      }
    );
  }
}

export class UpdateChoicesAPI {
  constructor(public data: ChoicesRequestDTO) {}
  async UpdateChoices(userId?: string): Promise<IAPIResponse> {
    const payload = {
      firstChoice: this.data.firstChoice,
      secondChoice: this.data.secondChoice,
      thirdChoice: this.data.thirdChoice,
    };
    const response = await axios.post<IAPIResponse<ChoicesRequestDTO>>(
      "/applicant/choices" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      payload
    );
    let choices: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(choices);
    });
  }
}
