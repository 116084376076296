import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  AdminAcceptedListNotificationDTO,
  AdminAssignedListInterviewsNotificationDTO,
} from "../types/NotificationTypes";

export class NotificationsAPI {
  async AcceptedList(
    data: AdminAcceptedListNotificationDTO
  ): Promise<IAPIResponse<string[]>> {
    const response = await axios.post<IAPIResponse>(
      "/admin/notification/acceptedlist",
      data
    );
    let res: IAPIResponse<string[]> = response.data;
    return Promise.resolve(res);
  }

  async AssignInterviews(
    data: AdminAssignedListInterviewsNotificationDTO
  ): Promise<IAPIResponse<string[]>> {
    const response = await axios.post<IAPIResponse>(
      "/admin/notification/sendinterviews",
      data
    );
    let res: IAPIResponse<string[]> = response.data;
    return Promise.resolve(res);
  }
}
