import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import classes from "./PrintRankingList.module.css";
import { PrintRankingListDTO } from "../../../types/Admin/RankingListsDTO";
import { RankingListAPI } from "../../../API/Admin/AdminRankingListAPI";
import moment from "moment";
import Spinner from "../../../features/Spinner/Spinner";
import { useReactToPrint } from "react-to-print";

const PrintRankingList = () => {
  let { apptype, listno, major } = useParams();
  const [rankinglist, setRankingList] = useState<PrintRankingListDTO[]>([]);
  const [wait, setWait] = useState<boolean>();

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({});

  useEffect(() => {
    const fetchData = async () => {
      setWait(true);
      const data = new RankingListAPI();
      const result = await data.PrintRankingList(
        listno ?? "",
        Number(apptype ?? 0),
        major ?? ""
      );
      setRankingList(result.result);
      setWait(false);
    };
    fetchData();
  }, [listno, apptype]);

  const choiceNA = (choice: string, choiceNA: boolean) => {
    return choiceNA ? (
      <span className={classes.notApplicableMajor}>*{choice}</span>
    ) : (
      <span>{choice}</span>
    );
  };

  return (
    <>
      {wait ? (
        <Spinner />
      ) : (
        <>
          <button
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print the list
          </button>

          <div className={`${classes.printcontainer}`} ref={contentToPrint}>
            <style type="text/css" media="print">
              {
                "\
   @page { size: landscape; }\
"
              }
            </style>
            <h2>
              Ranking list - {moment().local().format("DD/MM/YYYY HH:mm")} -
              Major: {major}
            </h2>
            <div>Key: *= Not eligible</div>
            <table className={`${classes.table}`}>
              <tr>
                <th>Notes</th>
                <th>Serial</th>
                <th>Username</th>
                <th>First choice</th>
                <th>Second choice</th>
                <th>Third choice</th>
                <th>Avg.</th>
                <th>School</th>
                <th>Religion</th>
                <th>Gender</th>
                <th>Stream</th>
                <th>Cer.Type</th>
                <th>Cer.Year</th>
                <th>Interview result</th>
                <th>Speical group</th>
                <th>Prv.List</th>
              </tr>
              {rankinglist.map((row) => {
                return (
                  <tr>
                    <th></th>
                    <th>{row.serial}</th>
                    <th>{row.userName}</th>
                    <th>
                      {choiceNA(row.firstChoice ?? "", row.firstChoiceNA)}
                    </th>
                    <th>
                      {choiceNA(row.secondChoice ?? "", row.secondChoiceNA)}
                    </th>
                    <th>
                      {choiceNA(row.thirdChoice ?? "", row.thirdChoiceNA)}
                    </th>
                    <th>{row.tawjihiAverage}</th>
                    <th>{row.schoolName}</th>
                    <th>{row.religion}</th>
                    <th>{row.gender}</th>
                    <th>{row.stream}</th>
                    <th>{row.certificateType}</th>
                    <th>{row.certificateYear}</th>
                    <th>
                      {row.hasInterviewFirstChoice &&
                      row.interviewDateFirstChoice &&
                      row.interviewDateFirstChoice.toString() !=
                        "0001-01-01T00:00:00" ? (
                        <>
                          <div>
                            {}
                            {row.firstChoice} -
                            <i>
                              {row.interviewDateFirstChoice == null
                                ? "Not assigned"
                                : moment(row.interviewDateFirstChoice)
                                    .local()
                                    .format("DD/MM/YYYY")}
                            </i>
                            -
                            {row.interviewResultFirstChoice == ""
                              ? "No result"
                              : row.interviewResultFirstChoice}
                          </div>
                        </>
                      ) : null}
                      {row.hasInterviewSecondChoice &&
                      row.interviewDateSecondChoice &&
                      row.interviewDateSecondChoice.toString() !=
                        "0001-01-01T00:00:00" ? (
                        <>
                          <div>
                            {row.secondChoice}-
                            <i>
                              {row.interviewDateSecondChoice == null
                                ? "Not assigned"
                                : moment(row.interviewDateSecondChoice)
                                    .local()
                                    .format("DD/MM/YYYY")}
                            </i>
                            -
                            {row.interviewResultSecondChoice == ""
                              ? "No result"
                              : row.interviewResultSecondChoice}
                          </div>
                        </>
                      ) : null}
                      {row.hasInterviewThirdChoice &&
                      row.interviewDateThirdChoice &&
                      row.interviewDateThirdChoice.toString() !=
                        "0001-01-01T00:00:00" ? (
                        <>
                          <div>
                            {row.thirdChoice}-
                            <i>
                              {row.interviewDateThirdChoice == null
                                ? "Not assigned"
                                : moment(row.interviewDateThirdChoice)
                                    .local()
                                    .format("DD/MM/YYYY")}
                            </i>
                            -
                            {row.interviewResultThirdChoice == ""
                              ? "No result"
                              : row.interviewResultThirdChoice}
                          </div>
                        </>
                      ) : null}
                    </th>
                    <th>{row.specialGroup}</th>
                    <th>{row.hasAPreviousRankingList ? "Yes" : "No"}</th>
                  </tr>
                );
              })}
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default PrintRankingList;
