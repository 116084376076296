import { useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";
import classes from "./AdminApplicantIdsInfo.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { ApplicantIdsInfoDTO } from "../../../types/Admin/ApplicantsDTO";
import { AdminApplicantMainInfoAPI } from "../../../API/Admin/AdminApplicantMainInfoAPI";
import { RootState } from "../../../store/store";
import Cookies from "js-cookie";

const AdminApplicantIdsInfo = (props: any) => {
  const { register, handleSubmit, reset } = useForm<ApplicantIdsInfoDTO>();
  const admin = useAppSelector((state: RootState) => state.admin);
  const role = Cookies.get("role");
  useEffect(() => {
    const fetchData = async () => {
      const getData = new AdminApplicantMainInfoAPI();
      reset((await getData.GetIDs(admin.userId)).result);
    };
    fetchData();
  }, [reset, admin]);

  const onSubmit: SubmitHandler<ApplicantIdsInfoDTO> = async (data) => {
    const updateInfo = new AdminApplicantMainInfoAPI();
    await updateInfo.POSTIDs(data);
    alert("Updated");
  };

  return (
    <div className="grid">
      {role === "ADM" || role === "AVP" || role === "REG" ? (
        <div className="formBoxContainer">
          <div className={classes.form + " " + classes.top}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={"grid grid--2--cols"}>
                <div className={classes.input}>
                  <div>
                    <span>SeatNo</span>
                  </div>
                  <div>
                    <input
                      {...register("seatNo")}
                      className="textbox"
                      placeholder={"Seat Number"}
                      type="textbox"
                    />
                  </div>
                </div>

                <div className={classes.input}>
                  <div>
                    <span>IdNo</span>
                  </div>
                  <div>
                    <input
                      {...register("idNo")}
                      className="textbox"
                      placeholder={"Id No"}
                      type="textbox"
                    />
                  </div>
                </div>

                <div className={classes.input}>
                  <div>
                    <span>StudentNo</span>
                  </div>
                  <div>
                    <input
                      {...register("previousStudentNo")}
                      className="textbox"
                      placeholder={"Student no"}
                      type="textbox"
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="btn"
                    style={{ height: "3.5rem", padding: "0.4rem 0.8rem" }}
                  >
                    Update SeatNo/IdNo/StudentNo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AdminApplicantIdsInfo;
