import { Header } from "../header";
import { DashboardContainer } from "./DashboardContainer";
import styles from "./Dashboard.module.scss";
import { Footer } from "../footer";
import { useEffect } from "react";
import i18n from "../../../i18n";

export const Dashboard = (): JSX.Element => {
  useEffect(() => {
    i18n.changeLanguage("en-US");
    document.body.style.direction = i18n.dir();
  }, []);
  return (
    <div className={styles["main-layout"]}>
      <Header />
      <DashboardContainer />
      <Footer />
    </div>
  );
};
