import React from "react";
import styles from "./LeftNavigation.module.scss";

export interface NavigationItem {
  title: string;
  icon: React.ReactNode;
  opened: boolean;
  isSelected: boolean;
  showIcon?: boolean;
  onSelect: () => void;
}

export const NavigationItem = ({
  title,
  icon,
  isSelected,
  opened,
  onSelect,
}: NavigationItem): JSX.Element => {
  const itemClass = isSelected
    ? `${styles["menu-item"]} ${styles["menu-item-selected"]}`
    : styles["menu-item"];
  const iconTitle = !opened ? title : "";

  return (
    <div onClick={onSelect} className={itemClass}>
      <div className={styles["icon-title"]} title={iconTitle}>
        {icon}
      </div>
      <div className={styles["menu-title"]}>{opened && title}</div>
    </div>
  );
};
