import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

// Define a type for the slice state
interface FreezedState {
  freezed: boolean;
}

// Define the initial state using that type
const initialState: FreezedState = {
  freezed: false,
};

export const freezedStateSlice = createSlice({
  name: "freezed",
  // `applicantStatusStateSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFreezedStatus: (state, action: PayloadAction<boolean>) => {
      state.freezed = action.payload;
    },
  },
});

export const { setFreezedStatus } = freezedStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectFreezed = (state: RootState) => state.freezed;

export default freezedStateSlice.reducer;
