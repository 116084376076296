import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "ar",
    lng: localStorage.getItem("lang")==null?localStorage.getItem("lang")!:"ar",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      "ar": {
        translation: translationAR,
      },
      "en-US": {
          translation: translationEN,
      },
    },
  });

export default i18n;