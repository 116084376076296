import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { ListOfApplicantsDTO } from "../types/ListOfApplicantsDTO";

export class ListOfApplicantsAPI {
  async GetAllApplicants(
    type: string
  ): Promise<IAPIResponse<ListOfApplicantsDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/applicants/listofapplicants/" + type
    );
    let applicantInfo: IAPIResponse<ListOfApplicantsDTO[]> = response.data;
    return Promise.resolve(applicantInfo);
  }

  async GetAnApplicant(
    userId: string
  ): Promise<IAPIResponse<ListOfApplicantsDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/applicants/getanapplicant/" + userId
    );
    let applicantInfo: IAPIResponse<ListOfApplicantsDTO> = response.data;
    return Promise.resolve(applicantInfo);
  }
}
