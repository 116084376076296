import { Box, Tab, ThemeProvider, createTheme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import SchoolInfo from "../../../features/Applicant/Schoolinfo/Schoolinfo";
import ContactInfo from "../../../features/Applicant/ContactInfo/ContactInfo";
import ApplicantInfo from "../../../features/Applicant/ApplicantInfo/ApplicantInfo";
import Choices from "../../../features/Applicant/Choices/Choices";
import SpecialNeedAndHum from "../../../features/Applicant/SpecialNeedAndHum/SpecialNeedAndHum";
import Experiance from "../../../features/Graduate/Experiance/Experiance";
import Comments from "../../../features/Adminv1/Comments";
import classes from "./ApplicantDashboard.module.css";
import MainInfo from "../ApplicantInfo/MainInfo";
import AdminApplicantDocuments from "../ApplicantInfo/AdminApplicantDocuments";
import AdminApplicantIdsInfo from "../ApplicantInfo/AdminApplicantIdsInfo";
const ApplicantDashboard = (props: any) => {
  const [value, setValue] = useState("school");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const tabTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: "#e8f1f9",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: "1.2rem",
            fontWeight: "700",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={tabTheme}>
      <div className={classes.dashboard}>
        <div className={classes.fullwidth}>
          <MainInfo getRankingList={props.getRankingList} />
        </div>
        <div className={classes.fullwidth}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList onChange={handleChange} aria-label="Applicant dashboard">
                <Tab label="School" value="school" />
                <Tab label="Info" value="info" />
                <Tab label="Contact" value="contact" />
                <Tab label="Choices" value="choices" />
                <Tab label="Special Need/Huminutarian" value="snandhum" />
                <Tab label="Degrees and Experiances" value="experiance" />
                <Tab label="Comments" value="comments" />
              </TabList>
            </Box>
          </TabContext>
        </div>
        <div>
          <TabContext value={value}>
            <TabPanel value="school">
              <SchoolInfo />
            </TabPanel>
            <TabPanel value="info">
              <ApplicantInfo />
            </TabPanel>
            <TabPanel value="contact">
              <ContactInfo />
            </TabPanel>
            <TabPanel value="choices">
              <Choices />
            </TabPanel>
            <TabPanel value="snandhum">
              <SpecialNeedAndHum />
            </TabPanel>
            <TabPanel value="experiance">
              <Experiance />
            </TabPanel>
            <TabPanel value="comments">
              <Comments />
            </TabPanel>
          </TabContext>
        </div>
        <div>
          <AdminApplicantIdsInfo />
          <AdminApplicantDocuments />
        </div>
      </div>

      <button
        className={classes.nextPrevious + " " + classes.nextButton}
        onClick={props.nextrow}
      >
        Next
      </button>
      <button
        className={classes.nextPrevious + " " + classes.previousButton}
        onClick={props.previousrow}
      >
        Previous
      </button>
    </ThemeProvider>
  );
};
export default ApplicantDashboard;
