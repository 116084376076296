import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./style/index.css";
import "./style/queries.css";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./store/store";

let persistor = persistStore(store);

//AXIOS configuration
axios.defaults.baseURL = "https://admapi.bethlehem.edu:5001/admission/v1/api/";
//axios.defaults.baseURL = "https://localhost:5001/admission/v1/api/";
//axios.defaults.baseURL = "https://apicopy.bethlehem.edu:5001/admission/v1/api/";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  async (request) => {
    const token = localStorage.getItem("jwttoken");
    // if (token == null && request.url !== "/usersauth/login") {
    //   window.location.href = "https://admission.bethlehem.edu/";
    // }
    request.headers.Authorization = token;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.removeItem("jwttoken");
      window.location.href = "https://admission.bethlehem.edu/";
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
