import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  ApplicantGraduateCertificatesRequestDTO,
  ApplicantGraduateCertificatesResponseDTO,
  ApplicantWorkExperianceRequestDTO,
  ApplicantWorkExperianceResponseDTO,
} from "../../types/Applicant/GraduateDTO";

export class ApplicantGraduateCertificatesPostAPI {
  constructor(public certificate: ApplicantGraduateCertificatesRequestDTO) {}
  async add(userId: string | undefined): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/applicant/graduate/addcertificate" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      this.certificate
    );
    let applicationInfo: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(applicationInfo);
    });
  }
}

export class ApplicantGraduateCertificatesGetAPI {
  async Get(
    userId: string | undefined
  ): Promise<IAPIResponse<ApplicantGraduateCertificatesResponseDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/applicant/graduate/certificates" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let personalInfo: IAPIResponse<ApplicantGraduateCertificatesResponseDTO[]> =
      response.data;
    return new Promise<
      IAPIResponse<ApplicantGraduateCertificatesResponseDTO[]>
    >((resolve, reject) => {
      resolve(personalInfo);
    });
  }
}

export class ApplicantWorkExperiancePostAPI {
  constructor(public workExperiance: ApplicantWorkExperianceRequestDTO) {}
  async add(userId: string | undefined): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/applicant/graduate/addworkexperiance" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      this.workExperiance
    );
    let work: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(work);
    });
  }
}

export class ApplicantWorkExperianceGetAPI {
  async Get(
    userId: string | undefined
  ): Promise<IAPIResponse<ApplicantWorkExperianceResponseDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/applicant/graduate/workexperiances" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let workExperiances: IAPIResponse<ApplicantWorkExperianceResponseDTO[]> =
      response.data;
    return new Promise<IAPIResponse<ApplicantWorkExperianceResponseDTO[]>>(
      (resolve, reject) => {
        resolve(workExperiances);
      }
    );
  }
}

export class ApplicantGraduateCertificatesDeleteAPI {
  async deleteCertificate(
    id: number,
    userId: string | undefined
  ): Promise<IAPIResponse> {
    const url =
      "/applicant/graduate/deleteeducation/" +
      id +
      "/" +
      (typeof userId !== "undefined" ? "/" + userId : "");
    const response = await axios.delete(url);
    let newdocument: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(newdocument);
    });
  }
}

export class ApplicantWorkExperianceDeleteAPI {
  async DeleteWorkExperiance(
    id: number,
    userId: string | undefined
  ): Promise<IAPIResponse> {
    const response = await axios.delete(
      "/applicant/graduate/deletework/" +
        id +
        "/" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let newdocument: IAPIResponse = response.data;
    return new Promise<IAPIResponse>((resolve, reject) => {
      resolve(newdocument);
    });
  }
}
