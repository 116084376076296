import React, { useState } from "react";
import classes from "./Notification.module.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { VerifyEmailAPI, VerifyMobileAPI } from "../../API/Applicant/VerifyAPI";
import { setMobileVerified } from "../../store/Slices/NotificationState";
import { toast } from "react-toastify";

const Notification = (props: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let emailnotification: boolean = useAppSelector(
    (state: RootState) => state.notification.email
  );
  let mobilenotification: boolean = useAppSelector(
    (state: RootState) => state.notification.mobile
  );

  let applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );

  let freezed: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );

  const ref1 = React.useRef<HTMLInputElement>(null);
  const ref2 = React.useRef<HTMLInputElement>(null);
  const ref3 = React.useRef<HTMLInputElement>(null);
  const ref4 = React.useRef<HTMLInputElement>(null);
  const ref5 = React.useRef<HTMLInputElement>(null);
  const ref6 = React.useRef<HTMLInputElement>(null);

  const submit = async () => {
    const number =
      ref6.current?.value +
      "" +
      ref5.current?.value +
      "" +
      ref4.current?.value +
      "" +
      ref3.current?.value +
      "" +
      ref2.current?.value +
      "" +
      ref1.current?.value;
    let verify = new VerifyMobileAPI();
    if ((await verify.VerifyMobileNumber(number)).isSuccess) {
      dispatch(setMobileVerified(true));
      toast.success(t("Verify_SMSSent"));
      setShow(false);
    } else {
      toast.error(t("Verify_SMSFail"));
    }
  };

  const resendEmail = async () => {
    let request = new VerifyEmailAPI();
    await request.sendEmail();
    toast.success(t("ContactInfo_sentEmail"));
  };
  const resendSMS = async () => {
    let request = new VerifyMobileAPI();
    await request.sendCode();
    toast.success(t("ContactInfo_sentMessage"));
  };
  return (
    <div className={classes.notification}>
      <div className={"container " + classes.notificationBox}>
        {!emailnotification && applicantStatus > 5 ? (
          <div className={classes.alert}>
            <div>
              <span className={classes.notificationAlert}>
                {t("Verify_EmailTitle")}
              </span>
            </div>
            <div>
              <span>{t("Verify_EmailDesc")}</span>
              {" / "}
              <button onClick={resendEmail}>
                {t("Verify_EmailSendAgain")}
              </button>
            </div>
          </div>
        ) : null}
        {!mobilenotification && applicantStatus > 5 ? (
          <div className={classes.alert}>
            <div>
              <span className={classes.notificationAlert}>
                {t("Verify_MsgTitle")}
              </span>
            </div>
            <div>
              <button onClick={handleShow}>{t("Verify_MsgDesc")}</button>
            </div>
          </div>
        ) : null}
      </div>

      {/* {freezed ? (
        <div className={"container"}>
          <div className={classes.understudying}>
            الطلب تحت الدراسة - لن تتمكن من تعديل الطلب خلال فترة دراسة الطلب
          </div>
        </div>
      ) : null} */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={classes.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Verify_ModelTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("Verify_ModelBody")}</p>
          <div>
            <div className={classes.verifyNumber}>
              <input type="textbox" ref={ref1} />
              <input
                type="textbox"
                ref={ref2}
                onChange={() => ref1.current?.focus()}
              />
              <input
                type="textbox"
                ref={ref3}
                onChange={() => ref2.current?.focus()}
              />
              <input
                type="textbox"
                ref={ref4}
                onChange={() => ref3.current?.focus()}
              />
              <input
                type="textbox"
                ref={ref5}
                onChange={() => ref4.current?.focus()}
              />
              <input
                type="textbox"
                ref={ref6}
                onChange={() => ref5.current?.focus()}
                autoFocus
              />
            </div>
            <div className={classes.verifyNumber}>
              <input
                className={
                  classes.verifyNumberbtn + " " + classes.verifyNumberbtnconfirm
                }
                type="submit"
                onClick={submit}
                value={t("Verify_ModelConfirm")!}
              />
              <input
                className={classes.verifyNumberbtn}
                type="submit"
                onClick={resendSMS}
                value={t("Verify_ResendSMS")!}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Verify_ModelClose")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Notification;
