import { IAPIResponse } from "../../types/IAPIResponse";
import axios from "axios";
import { IApplicationStatus } from "../../types/Applicant/IApplicationStatus";
import { IdAndStudentNoDTO } from "../../types/Applicant/graduate/IdAndStudentNoDTO";

export class IdAndStudentnoAPI {
  async StudentNoAndID(
    data: IdAndStudentNoDTO
  ): Promise<IAPIResponse<IApplicationStatus>> {
    const response = await axios.post<IAPIResponse<IApplicationStatus>>(
      "/applicant/personalinfo/studentno",
      data
    );
    let idAndStudentNo: IAPIResponse<IApplicationStatus> = response.data;
    return new Promise<IAPIResponse<IApplicationStatus>>((resolve, reject) => {
      resolve(idAndStudentNo);
    });
  }
}
