import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./Experiance.module.css";
import DatePicker from "react-datepicker";
import Spinner from "../../Spinner/Spinner";
import NextPrevious from "../../Layout/NextPrevious";
import { useNavigate } from "react-router-dom";
import personal from "../../../assests/illustration/personalinfo.svg";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {} from "../../../API/Applicant/ApplicationInfoAPI";
import {
  ApplicantGraduateCertificatesDeleteAPI,
  ApplicantGraduateCertificatesGetAPI,
  ApplicantGraduateCertificatesPostAPI,
  ApplicantWorkExperianceDeleteAPI,
  ApplicantWorkExperianceGetAPI,
  ApplicantWorkExperiancePostAPI,
} from "../../../API/Applicant/GraduateAPI";
import {
  ApplicantGraduateCertificatesResponseDTO,
  ApplicantWorkExperianceResponseDTO,
} from "../../../types/Applicant/GraduateDTO";
import { Button } from "react-bootstrap";
import { RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setApplicantStatus } from "../../../store/Slices/ApplicantStatusState";
import moment from "moment";

const Experiance = () => {
  const { t } = useTranslation();
  const [wait, setWait] = useState<boolean>(false);
  const [showEducAdd, setShowEducAdd] = useState<boolean>(false);
  const [showWorkAdd, setShowWorkAdd] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );
  const admin = useAppSelector((state: RootState) => state.admin);

  const freezedValue: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );

  const freezed =
    (!admin.allowToEdit && admin.userId !== undefined) || freezedValue;

  const navigate = useNavigate();

  const [educations, seteducations] =
    useState<ApplicantGraduateCertificatesResponseDTO[]>();
  const [workExperiance, setworkExperiance] =
    useState<ApplicantWorkExperianceResponseDTO[]>();

  const {
    register: registerCertificate,
    handleSubmit: handleSubmitCertificate,
    control: controlCertificate,
    reset: resetCertificate,
    watch: watchCertificate,
    formState: { errors: errorsCertificate },
  } = useForm<ApplicantGraduateCertificatesResponseDTO>();

  const {
    register: registerExperiance,
    handleSubmit: handleSubmitExperiance,
    control: controlExperiance,
    reset: resetExperiance,
    watch: watchExperiance,
    formState: { errors: errorsExperiance },
  } = useForm<ApplicantWorkExperianceResponseDTO>();

  useEffect(() => {
    setWait(true);

    async function fetchData() {
      getEducation();
      getWorkExperiance();
    }
    fetchData();
    setWait(false);
  }, []);

  const getEducation = async () => {
    let listOfEducations = new ApplicantGraduateCertificatesGetAPI();
    seteducations((await listOfEducations.Get(admin.userId)).result);
  };

  const getWorkExperiance = async () => {
    let listOfWorkExperiance = new ApplicantWorkExperianceGetAPI();
    setworkExperiance((await listOfWorkExperiance.Get(admin.userId)).result);
  };

  const deleteEducation = async (id: number) => {
    const deleteFileComment = t("Delete_File");
    const c = window.confirm(deleteFileComment);
    if (c) {
      let deleteFile = new ApplicantGraduateCertificatesDeleteAPI();
      await deleteFile.deleteCertificate(id, admin.userId);
    }
    getEducation();
  };

  const deleteWorkExperiance = async (id: number) => {
    const deleteFileComment = t("Delete_File");
    const c = window.confirm(deleteFileComment);
    if (c) {
      let deleteFile = new ApplicantWorkExperianceDeleteAPI();
      await deleteFile.DeleteWorkExperiance(id, admin.userId);
    }
    getWorkExperiance();
  };

  const next = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate("/applicant/choices");
  };

  const onSubmitCertificate: SubmitHandler<
    ApplicantGraduateCertificatesResponseDTO
  > = async (data) => {
    toast.success("تم اضافة الشهادة الجامعية", {});
    setShowEducAdd(false);
    let add = new ApplicantGraduateCertificatesPostAPI({
      certificate: data.certificate!,
      universityName: data.universityName!,
      year: data.year!,
    });
    await add.add(admin.userId);
    getEducation();
    resetCertificate();
    dispatch(setApplicantStatus(applicantStatus <= 8 ? 8 : applicantStatus));
  };

  const onSubmitExperiance: SubmitHandler<
    ApplicantWorkExperianceResponseDTO
  > = async (data) => {
    toast.success("تم اضافة الشهادة الجامعية", {});
    setShowEducAdd(false);
    let add = new ApplicantWorkExperiancePostAPI({
      employmentDateFrom: data.employmentDateFrom,
      employmentDateTo: data.employmentDateTo,
      position: data.position!,
    });
    await add.add(admin.userId);
    getWorkExperiance();
    resetExperiance();
    dispatch(setApplicantStatus(applicantStatus <= 8 ? 8 : applicantStatus));
  };

  return (
    <div
      className={
        "container grid gridbox marginTopBottom" +
        (admin.userId !== undefined ? " admin" : "")
      }
    >
      <aside className="instruction">
        <p>{t("Experiance_instruction")}</p>
        <img
          className="instructionimg"
          src={personal}
          alt="Contact info, Bethlehem University"
        />
      </aside>
      {!wait ? (
        <div>
          <form onSubmit={next}>
            <div className="formBox">
              <div className="formBoxHeader">
                <p>{t("Experiance_Education")}</p>
              </div>
              <div>
                <button
                  className="btn smallbtn"
                  type="button"
                  onClick={() => setShowEducAdd(true)}
                >
                  {t("Add")!}
                </button>
              </div>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>الشهادة</th>
                    <th>اسم الجامعة</th>
                    <th>السنه</th>
                    <th>حذف</th>
                  </tr>
                </thead>
                <tbody>
                  {educations
                    ? educations.map((e) => (
                        <tr key={e.id}>
                          <td>{e.certificate}</td>
                          <td>{e.universityName}</td>
                          <td>{e.year}</td>
                          <td>
                            <button
                              className="btn goldbutton smallbtn"
                              type="button"
                              onClick={() => deleteEducation(e.id)}
                            >
                              {t("Delete")!}
                            </button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div className="formBox">
              <div className="formBoxHeader">
                <p>{t("Experiance_Exper")}</p>
              </div>
              <div>
                <button
                  className="btn smallbtn"
                  type="button"
                  onClick={() => setShowWorkAdd(true)}
                >
                  {t("Add")!}
                </button>
              </div>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>الوظيفة</th>
                    <th>بدء العمل</th>
                    <th>انهاء العمل</th>
                    <th>حذف</th>
                  </tr>
                </thead>
                <tbody>
                  {workExperiance
                    ? workExperiance.map((e) => (
                        <tr key={e.id}>
                          <td>{e.position}</td>
                          <td>
                            {moment
                              .utc(e.employmentDateFrom)
                              .local()
                              .format("YYYY-MM-DD")}
                          </td>
                          <td>
                            {moment
                              .utc(e.employmentDateTo)
                              .local()
                              .format("YYYY-MM-DD")}
                          </td>
                          <td>
                            <button
                              className="btn goldbutton smallbtn"
                              type="button"
                              onClick={() => deleteWorkExperiance(e.id)}
                            >
                              {t("Delete")!}
                            </button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </form>
          {admin.userId === undefined && !freezed ? (
            <div className="formBox">
              <NextPrevious
                next={true}
                pre={true}
                onClickPrevious={() => {
                  navigate("/applicant/contact");
                }}
                onClickNext={() => {
                  navigate("/applicant/choices");
                }}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}

      <Modal
        show={showEducAdd}
        onHide={() => setShowEducAdd(false)}
        keyboard={false}
        className={classes.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h2>{t("Experiance_AddCertificate")}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px" }}>
          {errorsCertificate.certificate &&
            errorsCertificate.certificate?.type === "required" && (
              <p className="errorMessage">
                {t("Error_Required")! + t("Experiance_Certificate")!}
              </p>
            )}
          {errorsCertificate.universityName &&
            errorsCertificate.universityName?.type === "required" && (
              <p className="errorMessage">
                {t("Error_Required")! + t("Experiance_UniversityName")!}
              </p>
            )}
          {errorsCertificate.year &&
            errorsCertificate.year?.type === "required" && (
              <p className="errorMessage">
                {t("Error_Required")! + t("Experiance_Year")!}
              </p>
            )}
          {errorsCertificate.year &&
            errorsCertificate.year?.type === "pattern" && (
              <p className="errorMessage">{t("Experiance_YearValidation")!}</p>
            )}
          <form onSubmit={handleSubmitCertificate(onSubmitCertificate)}>
            <div className="grid grid--1--cols">
              <div>
                <input
                  {...registerCertificate("certificate", { required: true })}
                  type="textbox"
                  placeholder={t("Experiance_Certificate")!}
                />
              </div>
              <div>
                <input
                  {...registerCertificate("universityName", { required: true })}
                  placeholder={t("Experiance_UniversityName")!}
                  type="textbox"
                />
              </div>
              <div>
                <input
                  {...registerCertificate("year", {
                    required: true,
                    pattern: /^\d{4}$/,
                  })}
                  type="textbox"
                  placeholder={t("Experiance_Year")!}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleSubmitCertificate((d) => {
              onSubmitCertificate(d);
            })}
          >
            {t("Add")}
          </Button>
          <Button variant="secondary" onClick={() => setShowEducAdd(false)}>
            {t("Verify_ModelClose")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showWorkAdd}
        onHide={() => setShowWorkAdd(false)}
        keyboard={false}
        className={classes.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>{t("Experiance_AddExperiance")}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px" }}>
          <form onSubmit={handleSubmitExperiance(onSubmitExperiance)}>
            {errorsExperiance.position &&
              errorsExperiance.position?.type === "required" && (
                <p className="errorMessage">
                  {t("Error_Required")! + t("Experiance_Position")!}
                </p>
              )}
            {errorsExperiance.employmentDateFrom &&
              errorsExperiance.employmentDateFrom?.type === "required" && (
                <p className="errorMessage">
                  {t("Error_Required")! + t("Experiance_From")!}
                </p>
              )}
            {errorsExperiance.employmentDateTo &&
              errorsExperiance.employmentDateTo?.type === "required" && (
                <p className="errorMessage">
                  {t("Error_Required")! + t("Experiance_To")!}
                </p>
              )}
            <div className="grid grid--1--cols">
              <input
                {...registerExperiance("position", { required: true })}
                type="textbox"
                placeholder={t("Experiance_Position")!}
              />
              <Controller
                control={controlExperiance}
                {...registerExperiance("employmentDateFrom", {
                  required: true,
                })}
                render={({ field }) => (
                  <DatePicker
                    placeholderText={t("Experiance_From")!}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="yyyy/MM/dd"
                  />
                )}
              />
              <Controller
                control={controlExperiance}
                {...registerExperiance("employmentDateTo", {
                  required: true,
                })}
                render={({ field }) => (
                  <DatePicker
                    placeholderText={t("Experiance_To")!}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="yyyy/MM/dd"
                  />
                )}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleSubmitExperiance((d) => {
              onSubmitExperiance(d);
            })}
          >
            {t("Add")}
          </Button>
          <Button variant="secondary" onClick={() => setShowWorkAdd(false)}>
            {t("Verify_ModelClose")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Experiance;
