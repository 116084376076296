import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

// Define a type for the slice state
interface ApplicantStatusState {
  applicantstatus: number;
}

// Define the initial state using that type
const initialState: ApplicantStatusState = {
  applicantstatus: 0,
};

export const applicantStatusStateSlice = createSlice({
  name: "applicantstatus",
  // `applicantStatusStateSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setApplicantStatus: (state, action: PayloadAction<number>) => {
      state.applicantstatus = action.payload;
    },
  },
});

export const { setApplicantStatus } = applicantStatusStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectApplicantStatus = (state: RootState) =>
  state.applicantStatus;

export default applicantStatusStateSlice.reducer;
