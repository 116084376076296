import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { ContactInfoDTO } from "../../types/Applicant/ContactInfoDTO";
import { ApplicantVerificationStatusDTO } from "../../types/Applicant/ApplicantVerificationStatusDTO";
export class ContactAndAddressInfoUpdateAPI {
  async update(
    contactInfo: ContactInfoDTO,
    userId?: string
  ): Promise<IAPIResponse> {
    const response = await axios.put<IAPIResponse>(
      "/applicant/contactandaddress" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      contactInfo
    );
    let applicantVerificationStatus: IAPIResponse<ApplicantVerificationStatusDTO> =
      response.data;
    return new Promise<IAPIResponse<ApplicantVerificationStatusDTO>>(
      (resolve, reject) => {
        resolve(applicantVerificationStatus);
      }
    );
  }
}

export class ContactAndAddressInfoAPI {
  async Get(userId?: string): Promise<IAPIResponse<ContactInfoDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/applicant/contactandaddress" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let contactinfo: IAPIResponse<ContactInfoDTO> = response.data;
    return new Promise<IAPIResponse<ContactInfoDTO>>((resolve, reject) => {
      resolve(contactinfo);
    });
  }
}
