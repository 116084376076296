import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classes from "./AcceptanceMajor.module.css";
import logo from "../../../assests/logo_blackbg.png";
import Confetti from "react-confetti";
import { AcceptedMajorDTO } from "../../../types/Applicant/AcceptedMajorDTO";
import moment from "moment";
import { InstructionsAndAcceptanceAPI } from "../../../API/Applicant/InstructionsAndAcceptanceAPI";

const AcceptanceMajor = () => {
  let { id } = useParams();
  const [major, setMajor] = useState<AcceptedMajorDTO>();

  useEffect(() => {
    const fetchData = async () => {
      const majordetails = new InstructionsAndAcceptanceAPI();
      setMajor((await majordetails.GetMajorDetails(id)).result);
    };
    fetchData();
  }, [id]);

  return (
    <>
      <div className={`container grid grid--1--cols ${classes.padding}`}>
        <header className={` ${classes.header}`}>
          <div className={classes.logo}>
            <Confetti numberOfPieces={100} width={300} height={300} />
            <img src={logo} alt="bethlehem university" />
          </div>
          <div className={classes.acceptanceHeader}>
            <span>خاص بطلب الإلتحاق رقم: {major?.username}</span>
            <span className={classes.highLight}>
              لقد تم قبولك في جامعة بيت لحم
            </span>
            <span className={classes.highLight}>تخصص: {major?.major}</span>
            <span>الرجاء اكمال الخطوات التالية لإتمام التسجيل</span>
          </div>
        </header>
        <div className="grid grid--2--cols" dir="rtl">
          <section className={classes.section}>
            <h2>تسديد رسوم التسجيل</h2>
            <p>لإتمام حجز مقعدك الرجاء دفع مبلغ</p>
            <div style={{ textAlign: "center" }}>
              <h2 dir="rtl">
                {major?.amount} شيكل في الفترة الواقعة من تاريخ{" "}
                {moment(major?.payFrom).format("YYYY/MM/DD")} الى{" "}
                {moment(major?.payTo).format("YYYY/MM/DD")}
              </h2>
            </div>
            <p style={{ marginTop: "1.2rem" }}>
              الدفع من خلال نظام شركة PalPay، حيث يتم الدفع عن طريق نقاط البيع
              التابعة لشركة PalPay والمنتشرة في جميع أنحاء الوطن. يجب التأكد من
              رقم الطلب والاسم الشخصي قبل الدفع تفاديا لأي خطأ. عند استلام الوصل
              من احد معتمدي شركة PalPay يجب التاكد ان الوصل يحتوي على جملة (حركة
              مقبولة) ولا يُعتمد الوصل بغير ذلك.
            </p>
            <p>لمعرفة نقاط البيع الرجاء الضغط على الرابط التالي</p>
            <div>
              <a
                className="btn"
                target="_blank"
                href="https://bethlehem.edu/palpay"
                rel="noreferrer"
              >
                نقاط البيع الخاصة بPalPay
              </a>
            </div>
            <p style={{ marginTop: "2.4rem" }}>
              اذا اردت الدفع في البنك العربي الرجاء التوجه للمكتب المالي / جامعة
              بيت لحم
              <br />
              للمراجعة من الساعة التاسعة صباحا لغاية الساعة الواحدة بعد الظهر
            </p>
            <b>الرجاء الأخذ بعين الاعتبار ما يلي:</b>
            <b style={{ color: "red" }}>
              هذا المبلغ غير مسترد في حال انسحب الطالب من الجامعة.
            </b>
            <p>
              الطلبة الذين تم قبولهم ولم ينهوا عملية التسجيل ودفع الرسوم في
              الأوقات المحددة لا يحق لهم الالتحاق في الجامعة في وقت لاحق إلا من
              خلال تقديم طلب التحاق جديد في المواعيد المحددة لذلك.{" "}
            </p>
          </section>
          <section className={classes.section}>
            <h2>تسليم الوثائق المطلوبة</h2>
            <p>القبول مشروط بتسليم الوثائق الرسمية للجامعة </p>
            <div dir="rtl">
              <p>ستصلكم رسالة لاحقا عن آلية تسليم الوثائق </p>

              {/* <p>الوثائق المطلوبة لإعتماد الطلب:</p>
              <ol>
                <li>
                  ملف القبول, الرجاء الضغط على الرابط التالي لطباعة الملف
                  <input
                    type="submit"
                    className="btn"
                    value="طباعة ملف القبول"
                  ></input>
                </li>
                <li>كشف علامات التوجيهي الأصلي وصورة مصدقة عنه</li>
                <li>صورة عن الهوية الشخصية</li>
                <li>صورتين شخصيتين</li>
              </ol>
              <p>وثائق يجب ارفاقها مع الحالات الخاصة</p>
              <ol>
                <li>بيانات ذوي الإعاقة ان وجدت</li>
                <li>طلب الحالات الإنسانية في حالة حدوثها</li>
              </ol> */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AcceptanceMajor;
