import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

// Define a type for the slice state
interface AdminApplicantIdState {
  userId: string | undefined;
  allowToEdit: boolean;
  updatedRecord: boolean;
}

// Define the initial state using that type
const initialState: AdminApplicantIdState = {
  userId: undefined,
  allowToEdit: false,
  updatedRecord: false,
};

export const adminApplicationStateSlice = createSlice({
  name: "adminapplicationid",
  initialState,
  reducers: {
    setAdminApplicantId: (state, action: PayloadAction<string | undefined>) => {
      state.userId = action.payload;
    },
    setAllowToEdit: (state, action: PayloadAction<boolean>) => {
      state.allowToEdit = action.payload;
    },
    setUpdatedRecord: (state, action: PayloadAction<boolean>) => {
      state.updatedRecord = action.payload;
    },
  },
});

export const { setAdminApplicantId, setAllowToEdit, setUpdatedRecord } =
  adminApplicationStateSlice.actions;

export const selectApplicantId = (state: RootState) => state.admin;

export default adminApplicationStateSlice.reducer;
