import { useEffect, useState } from "react";
import { TempletesEmailAPI } from "../../apis/TempletesEmailAPI";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { IOption } from "../../../types/Codes/IOptions";
import { SMSTempletesAPI } from "../../apis/SMSTempletesAPI";
import { Alert } from "react-bootstrap";
import { AdminAcceptedListNotificationDTO } from "../../types/NotificationTypes";
import Select from "react-select";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment-business-days";
import { ApplicationTempletesEmail } from "../../types/EmailTempletes";
import { ApplicationSMSTempletes } from "../../types/SMSTempletes";
import { NotificationsAPI } from "../../apis/NotificationsAPI";
import ReactHtmlParser from "react-html-parser";
import Spinner from "../../../features/Spinner/Spinner";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export const AcceptanceToMajorNotification = (props: any) => {
  const role = Cookies.get("role");
  const [wait, setWait] = useState<boolean>(false);
  const [registrar, setRegistrar] = useState<boolean>(
    role === "FIN" ? false : true
  );
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(
    moment(new Date()).businessAdd(3).toDate()
  );

  const [dataEmailTempletes, setDataEmailTempletes] = useState<IOption[]>([]);
  const [dataEmailTempletesSelected, setDataEmailTempletesSelected] =
    useState<IOption>();

  const [dataSMSTempletes, setDataSMSTempletes] = useState<IOption[]>([]);
  const [dataSMSTempletesSelected, setDataSMSTempletesSelected] =
    useState<IOption>();

  const [emailTemplete, setEmailTemplete] =
    useState<ApplicationTempletesEmail>();

  const [smsTemplete, setSMSTemplete] = useState<ApplicationSMSTempletes>();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<AdminAcceptedListNotificationDTO>();

  useEffect(() => {
    updateNotificationsList();
  }, [registrar]);

  const updateNotificationsList = async () => {
    setValue("emailTemplete", 0);
    setValue("smsTemplete", 0);
    setDataSMSTempletesSelected(undefined);
    setDataEmailTempletesSelected(undefined);
    setEmailTemplete(undefined);
    setSMSTemplete(undefined);
    const emailTempletes = new TempletesEmailAPI();
    await emailTempletes.GetAllTempletes(registrar).then((data) => {
      setDataEmailTempletes(data.result);
    });

    const smsTempletes = new SMSTempletesAPI();
    await smsTempletes.GetAllTempletes(registrar).then((data) => {
      setDataSMSTempletes(data.result);
    });
  };

  const onEmailNotificationChangeHandler = async (val: IOption) => {
    setValue("emailTemplete", Number.parseInt(val!.value!));
    setDataEmailTempletesSelected(val!);
    const emailTempletes = new TempletesEmailAPI();
    await emailTempletes
      .GetATemplete(Number.parseInt(val!.value!))
      .then((data) => {
        setEmailTemplete(data.result);
      });
  };

  const onSMSNotificationChangeHandler = async (val: IOption) => {
    setValue("smsTemplete", Number.parseInt(val!.value!));
    setDataSMSTempletesSelected(val!);
    const smsTempletes = new SMSTempletesAPI();
    await smsTempletes
      .GetATemplete(Number.parseInt(val!.value!))
      .then((data) => {
        setSMSTemplete(data.result);
      });
  };

  const onSubmit: SubmitHandler<AdminAcceptedListNotificationDTO> = async (
    data
  ) => {
    setWait(true);
    const notification = new NotificationsAPI();
    const res = await notification.AcceptedList({
      emailTemplete: emailTemplete?.id ?? 0,
      smsTemplete: smsTemplete?.id ?? 0,
      finance: !registrar,
      listOfApplicants: props.listOfApplicants,
      payFrom: fromDate,
      payTo: toDate,
    });
    toast.success(
      <div>
        {res.result.map((item) => {
          return <strong>{item}</strong>;
        })}
      </div>
    );
    setWait(false);
    await props.updateList();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Alert variant="warning">
            <h4>
              Would you like to accept{" "}
              <strong>{props.listOfApplicants.length}</strong> applicants for
              majors?
            </h4>
          </Alert>
        </div>
        <h3></h3>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Select a notification type:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="reg"
              control={
                <Radio
                  disabled={!["ADM", "REG", "AVP"].includes(role ?? "")}
                  onChange={() => {
                    setRegistrar(true);
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: "18px" }}>
                  Registrar office
                </Typography>
              }
              checked={registrar}
            />
            <FormControlLabel
              value="fin"
              control={
                <Radio
                  disabled={!["ADM", "FIN"].includes(role ?? "")}
                  onChange={() => {
                    setRegistrar(false);
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: "18px" }}>
                  Finance office
                </Typography>
              }
              checked={!registrar}
            />
          </RadioGroup>
        </FormControl>
        {!registrar ? (
          <div className="grid grid--2--cols">
            <div>Notification date:</div>
            <div>Calculated date (3 business-days):</div>
            <div>
              <DatePicker
                placeholderText={"Select a date"}
                dateFormat="yyyy/MM/dd"
                showIcon
                onChange={(d) => {
                  setFromDate(d ?? new Date());
                  setToDate(moment(d).businessAdd(3).toDate());
                }}
                selected={fromDate}
              />
            </div>
            <div>
              <DatePicker
                placeholderText={"Select a date"}
                dateFormat="yyyy/MM/dd"
                showIcon
                onChange={(d) => setToDate(d ?? new Date())}
                selected={toDate}
              />
            </div>
          </div>
        ) : null}
        <div className="grid grid--2--cols">
          <div>SMS Notification</div>
          <div>Email Notification</div>

          <Controller
            control={control}
            name="smsTemplete"
            render={({ field: { onChange, value, ref } }) => (
              <Select<IOption, false>
                options={dataSMSTempletes}
                {...register("smsTemplete", { required: true })}
                onChange={(val) => {
                  onSMSNotificationChangeHandler(val!);
                }}
                value={dataSMSTempletesSelected}
              />
            )}
          />
          <Controller
            control={control}
            name="emailTemplete"
            render={({ field: { onChange, value, ref } }) => (
              <Select<IOption, false>
                options={dataEmailTempletes}
                {...register("emailTemplete", { required: true })}
                onChange={(val) => {
                  onEmailNotificationChangeHandler(val!);
                }}
                value={dataEmailTempletesSelected}
              />
            )}
          />
          <div dir="rtl">{ReactHtmlParser(smsTemplete?.messageAr ?? "")}</div>
          <div dir="rtl">
            <div>{ReactHtmlParser(emailTemplete?.subjectAr ?? "")}</div>
            <div>{ReactHtmlParser(emailTemplete?.bodyAr ?? "")}</div>
          </div>
        </div>
        {props.listOfApplicants.length > 0 ? (
          <div>
            {wait ? (
              <Spinner />
            ) : (
              <button className="btn" type="submit">
                Finish
              </button>
            )}
          </div>
        ) : null}
      </form>
    </div>
  );
};
