import AdminHeader from "../adminheader/AdminHeader";
import { toast } from "react-toastify";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
  MaterialReactTable,
} from "material-react-table";
import { Alert, ThemeProvider } from "@mui/material";
import { tableTheme } from "../TableStyle";

import classes from "../rankingList/rankingList.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { ActiveListDTO } from "../../../types/Admin/ApplicantsDTO";

import { setAdminApplicantId } from "../../../store/Slices/AdminApplicantId";
import Modal from "react-bootstrap/Modal";
import ApplicantDashboard from "../ApplicationDashboard/ApplicantDashboard";
import Cookies from "js-cookie";
import { ListOfApplicantsAssignedToInterviewDTO } from "../../types/InterviewList";
import { InterviewListAPI } from "../../apis/InterviewListAPI";
import { IOption } from "../../../types/Codes/IOptions";
import { AssignToInterviewNotification } from "../Notifications/AssignToInterviewNotification";

export const AdminAssignedToInterview = (props: any): JSX.Element => {
  const role = Cookies.get("role");
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [index, setindex] = useState<number>(0);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [majorOptions, setmajorOptions] = useState<IOption[]>([]);
  const [dataTable, setDataTable] = useState<
    ListOfApplicantsAssignedToInterviewDTO[]
  >([]);
  const tableInstanceRef =
    useRef<MRT_TableInstance<ListOfApplicantsAssignedToInterviewDTO>>(null);
  const [activeList, setactiveList] = useState<ActiveListDTO | undefined>(
    undefined
  );
  const [codesresutls, setCodesresults] = useState<IOption[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [openInterview, setOpenInterview] = useState(false);
  const handleOpenInterview = () => setOpenInterview(true);
  const handleCloseInterview = () => setOpenInterview(false);

  useEffect(() => {
    interviewCodeResults();
    updateList();
  }, [activeList]);

  const updateList = async () => {
    await getRankingList();
    handleCloseInterview();
  };
  const nextPrevious = (next: boolean = true) => {
    if (!next) {
      if (index - 1 === -1) {
        alert("No previous!");
        return;
      }
      dispatch(setAdminApplicantId(dataTable[index - 1].id));
      setindex(index - 1);
    } else {
      if (index + 1 === dataTable.length) {
        alert("No next!");
        return;
      }
      dispatch(setAdminApplicantId(dataTable[index + 1].id));
      setindex(index + 1);
    }
  };

  const onTypeChangeHandler = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    await updateList();
    await interviewCodeResults();
  };

  const interviewCodeResults = async () => {
    let results = new InterviewListAPI();
    setCodesresults((await results.InterviewResultList()).result);
  };

  const getRankingList = async () => {
    const appType = Cookies.get("appType");
    if (appType == undefined || appType == null) {
      return;
    }
    setIsRefetching(true);
    const interview = new InterviewListAPI();
    await interview.GetAllAssignedApplicants(appType ?? "-1").then((data) => {
      setDataTable(data.result);
    });
    setIsRefetching(false);
  };

  const updateResultHandler = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    applicantId: string,
    choice: number,
    interviewNumber: number
  ) => {
    const interview = new InterviewListAPI();
    await interview.AssignResult({
      applicantId: applicantId,
      result: Number(e.target.value),
      majorId: choice,
      interviewNumber: interviewNumber,
    });
    toast.success("Updated");
    setRowSelection({});
  };

  const formatChoice = (
    row: MRT_Row<ListOfApplicantsAssignedToInterviewDTO>,
    choice: number,
    interviewNumber: number,
    value: number,
    hasInterview: boolean,
    assigned: boolean
  ) => {
    return (
      <div>
        {hasInterview ? (
          <select
            name={`${choice}-${interviewNumber}`}
            id={`${choice}-${interviewNumber}`}
            onChange={(e) => {
              updateResultHandler(e, row.id, choice, interviewNumber);
            }}
            defaultValue={value}
            disabled={!assigned || row.original.hasAcceptance}
          >
            <option value="0">Select a result</option>
            {codesresutls.map((option) => {
              return <option value={option.value}>{option.label}</option>;
            })}
          </select>
        ) : null}
      </div>
    );
  };

  const columns = useMemo<
    MRT_ColumnDef<ListOfApplicantsAssignedToInterviewDTO>[]
  >(
    () => [
      {
        header: "id",
        accessorKey: "id",
      },
      {
        header: "Application No.",
        accessorKey: "userName",
      },
      {
        header: "English name",
        accessorKey: "englishName",
      },
      {
        header: "Accepted major",
        accessorKey: "acceptedMajor",
      },
      {
        header: "First choice",
        accessorKey: "firstChoice",
      },
      {
        header: "Second choice",
        accessorKey: "secondChoice",
      },
      {
        header: "Third choice",
        accessorKey: "thirdChoice",
      },
      {
        header: "Assigned interview",
        accessorKey: "hasInterviewNotification",
        accessorFn: (originalRow) =>
          originalRow.hasInterviewNotification ? "true" : "false",
        filterVariant: "checkbox",
        Cell: ({ cell }) => (cell.getValue() === "true" ? "Yes" : "No"),
      },
      {
        header: "First choice interview / First",
        accessorKey: "firstChoiceInterviewFirst",
        Cell: ({ renderedCellValue, row }) =>
          formatChoice(
            row,
            row.original.firstChoiceId ?? -1,
            1,
            row.original.firstChoiceInterviewFirst,
            row.original.hasFirstChoiceInteview,
            row.original.assignedFirstChoiceInteview
          ),
      },

      {
        header: "First choice interview / Second",
        accessorKey: "firstChoiceInterviewSecond",
        Cell: ({ renderedCellValue, row }) =>
          formatChoice(
            row,
            row.original.firstChoiceId ?? -1,
            2,
            row.original.firstChoiceInterviewSecond,
            row.original.hasFirstChoiceInteview,
            row.original.assignedFirstChoiceInteview
          ),
      },
      {
        header: "Second choice interview / First",
        accessorKey: "secondChoiceInterviewFirst",
        Cell: ({ renderedCellValue, row }) =>
          formatChoice(
            row,
            row.original.secondChoiceId ?? -1,
            1,
            row.original.secondChoiceInterviewFirst,
            row.original.hasSecondChoiceInteview,
            row.original.assignedSecondChoiceInteview
          ),
      },
      {
        header: "Second choice interview / Second",
        accessorKey: "secondChoiceInterviewSecond",
        Cell: ({ renderedCellValue, row }) =>
          formatChoice(
            row,
            row.original.secondChoiceId ?? -1,
            2,
            row.original.secondChoiceInterviewSecond,
            row.original.hasSecondChoiceInteview,
            row.original.assignedSecondChoiceInteview
          ),
      },
      {
        header: "Third choice interview / First",
        accessorKey: "thirdChoiceInterviewFirst",
        Cell: ({ renderedCellValue, row }) =>
          formatChoice(
            row,
            row.original.thirdChoiceId ?? -1,
            1,
            row.original.thirdChoiceInterviewFirst,
            row.original.hasThirdChoiceInteview,
            row.original.assignedThirdChoiceInteview
          ),
      },
      {
        header: "Third choice interview / Second",
        accessorKey: "thirdChoiceInterviewSecond",
        Cell: ({ renderedCellValue, row }) =>
          formatChoice(
            row,
            row.original.thirdChoiceId ?? -1,
            2,
            row.original.thirdChoiceInterviewSecond,
            row.original.hasThirdChoiceInteview,
            row.original.assignedThirdChoiceInteview
          ),
      },
    ],
    [majorOptions, activeList, codesresutls]
  );

  return (
    <div style={{ width: "100%" }}>
      {" "}
      <header>
        <AdminHeader
          onTypeHandler={onTypeChangeHandler}
          title="Assigned to interview"
        />
        {["AVP", "ADM", "REG"].includes(role ?? "") ? (
          <div>
            <div>
              <div className="grid grid--5--cols">
                <input
                  className="btn"
                  onClick={handleOpenInterview}
                  value="Send a notification"
                />
              </div>
            </div>
          </div>
        ) : null}
      </header>
      <section className={classes.listOfApplicants}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={columns}
            data={dataTable}
            tableInstanceRef={tableInstanceRef}
            enablePagination={true}
            enableRowSelection={true}
            getRowId={(row) => row.id}
            onRowSelectionChange={setRowSelection}
            state={{
              rowSelection: rowSelection,
              showProgressBars: isRefetching,
            }}
            muiTableContainerProps={{
              sx: {
                minHeight: 0,
                maxHeight: "calc(100vh - 400px)",
              },
            }}
            initialState={{
              pagination: { pageSize: 100, pageIndex: 0 },
              showColumnFilters: true,
              columnPinning: { left: ["userName"], right: ["verified"] },
              columnVisibility: {
                id: false,
                serial: true,
                acceptedMajor: false,
                firstChoiceInterviewSecond: false,
                secondChoiceInterviewSecond: false,
                thirdChoiceInterviewSecond: false,
              },
              density: "compact",
            }}
            muiTableBodyCellProps={({ cell }) => ({
              onDoubleClick: (event) => {
                setindex(cell.row.index);
                handleOpen();
                dispatch(setAdminApplicantId(dataTable[cell.row.index].id));
              },
              sx: {
                cursor: "pointer",
              },
            })}
            enableRowNumbers
            muiTableBodyRowProps={({ row }) => ({
              onClick: (e) => {
                let stoploop = false;
                if (!e.shiftKey) {
                } else {
                  tableInstanceRef.current
                    ?.getRowModel()
                    .rows.forEach((element) => {
                      if (row.id === element.id) {
                        element.toggleSelected();
                        stoploop = true;
                      } else if (!stoploop) {
                        const firstChoice = document.getElementById(
                          "firstChoice" + element.original.userName
                        ) as HTMLInputElement | null;
                        firstChoice!.checked = true;
                        element.toggleSelected();
                      }
                    });
                }
              },
              sx: {
                cursor: "pointer",
              },
            })}
            rowNumberMode="static"
            positionToolbarAlertBanner="bottom"
          />
        </ThemeProvider>
      </section>
      <Modal
        show={open}
        onHide={handleClose}
        className={classes.modal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Applicant Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApplicantDashboard
            nextrow={() => nextPrevious(true)}
            previousrow={() => nextPrevious(false)}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={openInterview}
        onHide={handleCloseInterview}
        className={classes.modal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assigned to interview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignToInterviewNotification
            listOfApplicants={Object.keys(rowSelection)}
            updateList={updateList}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
