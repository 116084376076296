import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classes from "./AcceptanceMajor.module.css";
import logo from "../../../assests/logo_blackbg.png";
import { InstructionsAndAcceptanceAPI } from "../../../API/Applicant/InstructionsAndAcceptanceAPI";
import { AcceptedMajorDTO } from "../../../types/Applicant/AcceptedMajorDTO";
import moment from "moment";
import { InterviewInstructionsDTO } from "../../../Admin/types/InterviewList";

const AcceptanceInterview = () => {
  let { id } = useParams();
  const [interviews, setInterviews] = useState<InterviewInstructionsDTO[]>();

  useEffect(() => {
    moment.locale("ar");
    const fetchData = async () => {
      const interviewInstructions = new InstructionsAndAcceptanceAPI();
      setInterviews(
        (await interviewInstructions.GetInterviewInstructions(id)).result
      );
    };
    fetchData();
  }, [id]);

  return (
    <>
      <div className={`container grid grid--1--cols ${classes.padding}`}>
        <header className={` ${classes.header}`}>
          <div className={classes.logo}>
            <img src={logo} alt="bethlehem university" />
          </div>
          <div className={classes.acceptanceHeader}>
            <span>خاص بطلب الإلتحاق رقم: {id?.substring(0, 4)}</span>

            {interviews?.map((item) => {
              return (
                <span className={classes.highLight}>
                  لقد تم اختيارك لإجراء مقابلة في تخصص: {item.majorAr}
                </span>
              );
            })}
          </div>
        </header>
        <div className="grid grid--1--cols" dir="rtl">
          <section className={classes.section}>
            <h2>المقابلة</h2>
            {interviews?.map((item) => {
              return (
                <h1 className={classes.highLight}>
                  ستكون المقابلة الخاصة بك بتخصص {item.majorAr} بتاريخ{" "}
                  {moment(item?.interviewDate).format("YYYY/MM/DD")}
                </h1>
              );
            })}
            <p>
              ستكون المقابلة وجاهية في حرم جامعة بيت لحم في مبنى كلية التمريض
              الجديد , لذلك عليكم التواجد في الجامعة الساعة 9:00 صباحًا لحجز
              موعد للمقابلة وإجرائها.
            </p>
            <span className={classes.highLight}>
              {" "}
              حضور المقابلة إلزامي للقبول، حيث أن نتيجة المقابلة هي إحدى معايير
              القبول.
            </span>
            <p style={{ marginTop: "1.2rem" }}>
              هام جداً الرجاء قراءة ملف التعهد الخاص بتخصصك المرفق بعناية و
              طباعته والتوقيع عليه
            </p>
            <div>
              {interviews?.map((item) => {
                return (
                  <p>
                    <a
                      className="btn"
                      target="_blank"
                      href={item.link}
                      rel="noreferrer"
                    >
                      تحميل ملف التعهد الخاص بتخصص: {item.majorAr}
                    </a>
                  </p>
                );
              })}
            </div>
            <p style={{ marginTop: "0.8rem" }}>
              وإرساله بالبريد الالكتروني إلى
            </p>
            <p>
              <a className="btn" href="mailto:nursingadmission@bethlehem.edu">
                nursingadmission@bethlehem.edu
              </a>
            </p>
            , إن عدم التوقيع عليه يعيق قبولك في الجامعة.
            <p>
              للاستفسار عن المقابلات يمكن التواصل مع كلية التمريض على الارقام
              التالية 2741241 رقم داخلي 2203 أو 2205
            </p>
          </section>
          {/* <section className={classes.section}>
            <span>امتحان القبول</span>
            <p>سيكون الاختبار الخاص بك بتاريخ</p>
            <div className={classes.highLight}>
              <span> {moment(major?.interviewDate).format("YYYY/MM/DD")}</span>
            </div>
            <h3>قاعة: مختبر الحاسوب Fuglister في الطابق الأرضي</h3>
            <p>
              {" "}
              يرجى زيارة موقع الاختبار قبل موعد الامتحان للتأكد من أنه يمكنك
              تسجيل الدخول إلى الموقع وأيضًا لتجربة الاختبار التجريبي المتاح على
              الموقع.
            </p>
            <span>
              <a
                href="https://exam.bethlehem.edu"
                target="_blank"
                className="btn"
                rel="noreferrer"
              >
                انقر هنا للوصول إلى الاختبار التجريبي
              </a>
            </span>
            <p>
              لتسجيل الدخول إلى الموقع ، استخدم اسم المستخدم وكلمة المرور اللذين
              استخدمتهما لإكمال طلب الجامعة
            </p>
            <p>اذا واجهتك مشكلة تقنية الرجاء التواصل مع الدعم الفني</p>
            <p>
              من خلال الواتساب 972566808860 او 972566808870 او 970569506399 او
              970566808850
            </p>
          </section> */}
        </div>
      </div>
    </>
  );
};

export default AcceptanceInterview;
