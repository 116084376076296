import React from "react";
import logo from "../../assests/logo_blackbg.png";

const NoMatch = () => {
  return (
    <div
      className={"container grid grid--1--cols"}
      style={{ justifyItems: "center", marginBottom: "2.4rem" }}
    >
      <img src={logo} alt="bethlehem university" />
      <h1>الصفحة المطلوبة غير متوفره </h1>
      <h1>Page not found</h1>
    </div>
  );
};

export default NoMatch;
