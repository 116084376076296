import AdminHeader from "../adminheader/AdminHeader";
import { toast } from "react-toastify";
import Select from "react-select";

import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
  MaterialReactTable,
} from "material-react-table";
import { Alert, ThemeProvider } from "@mui/material";
import { tableTheme } from "../TableStyle";

import classes from "./rankingList.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import {
  AcceptApplicantDTO,
  ActiveListDTO,
  FilterOptions,
  Major,
} from "../../../types/Admin/ApplicantsDTO";
import moment from "moment";
import { setAdminApplicantId } from "../../../store/Slices/AdminApplicantId";
import Modal from "react-bootstrap/Modal";
import ApplicantDashboard from "../ApplicationDashboard/ApplicantDashboard";
import { RankingListAPI } from "../../../API/Admin/AdminRankingListAPI";
import Cookies from "js-cookie";
import { AdminGloablValues } from "../../../API/Admin/AdminGloablValues";
import { RankingListDTO } from "../../../types/Admin/RankingListsDTO";
import { AssignedToInterview } from "./AssignedToInterview";
import { AcceptedToMajor } from "./AcceptedToMajor";
import { AssignedApplicantDTO } from "../../types/InterviewList";
import { IOption } from "../../../types/Codes/IOptions";
import Spinner from "../../../features/Spinner/Spinner";

export const RankingList = (props: any): JSX.Element => {
  const [wait, setWait] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [index, setindex] = useState<number>(0);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [majorOptions, setmajorOptions] = useState<FilterOptions[]>([]);
  const [majorAcceptanceOptions, setmajorAcceptanceOptions] = useState<
    FilterOptions[]
  >([]);
  const [majorOptionsforprint, setmajorOptionsForPrint] = useState<IOption[]>(
    []
  );
  const [selectedMajorOptionsforprint, setSelectedMajorOptionsForPrint] =
    useState<IOption>();
  const [dataTable, setDataTable] = useState<RankingListDTO[]>([]);
  const tableInstanceRef = useRef<MRT_TableInstance<RankingListDTO>>(null);
  const [activeList, setactiveList] = useState<ActiveListDTO | undefined>(
    undefined
  );

  const [openAcceptance, setOpenAcceptance] = useState(false);
  const handleOpenAcceptance = () => setOpenAcceptance(true);
  const handleCloseAcceptance = () => setOpenAcceptance(false);

  const [openInterview, setOpenInterview] = useState(false);
  const handleOpenInterview = () => setOpenInterview(true);
  const handleCloseInterview = () => setOpenInterview(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  useEffect(() => {
    updateList();
  }, [activeList]);

  const updateList = async () => {
    const appType = Cookies.get("appType");
    let adminvalues = new AdminGloablValues();
    var majorSelected = (
      await adminvalues.GetMajorsForApplicationType(appType ?? "0")
    ).result;
    setmajorOptions(majorSelected);
    let list = [...majorOptions];
    list.push({
      text: "Not assigned yet",
      value: "-",
    });
    setmajorAcceptanceOptions(list);

    let listOfMajors: IOption[] = [];
    for (const major of majorSelected) {
      listOfMajors.push({ label: major.text, value: major.value });
    }
    setmajorOptionsForPrint(listOfMajors);

    await updateActiveList();
    await getRankingList();
    handleCloseAcceptance();
  };
  const nextPrevious = (next: boolean = true) => {
    if (!next) {
      if (index - 1 === -1) {
        alert("No previous!");
        return;
      }
      dispatch(setAdminApplicantId(dataTable[index - 1].id));
      setindex(index - 1);
    } else {
      if (index + 1 === dataTable.length) {
        alert("No next!");
        return;
      }
      dispatch(setAdminApplicantId(dataTable[index + 1].id));
      setindex(index + 1);
    }
  };

  const onTypeChangeHandler = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    await updateList();
  };

  const [acceptedApplicants, setAcceptedApplicants] = useState<
    AcceptApplicantDTO[]
  >([]);
  const acceptForAMajor = async () => {
    let applicants: AcceptApplicantDTO[] = [];
    const list = tableInstanceRef.current?.getPrePaginationRowModel().rows;
    if (list !== undefined) {
      list.forEach((element: MRT_Row<RankingListDTO>) => {
        const value = (
          document.querySelector(
            'input[name="choice' + element.original.userName + '"]:checked'
          ) as HTMLInputElement | null
        )?.value;

        if (value !== undefined) {
          applicants.push({
            applicantId: element.original.id,
            choiceOrder: Number.parseInt(value ?? "0"),
            rankListId: activeList?.id === undefined ? 0 : activeList?.id,
          });
        }
      });
      setAcceptedApplicants(applicants);
      handleOpenAcceptance();
    }
  };

  const [assignedApplicantsForInterview, setAssignedApplicantsForInterview] =
    useState<AssignedApplicantDTO[]>([]);
  const [assignedMajorsForInterview, setAssignedMajorsForInterview] =
    useState<Major[]>();
  const acceptForAnInterview = async () => {
    let applicants: AssignedApplicantDTO[] = [];
    let listOfMajors: Major[] = [];
    const list = tableInstanceRef.current?.getPrePaginationRowModel().rows;
    if (list !== undefined) {
      list.forEach((element: MRT_Row<RankingListDTO>) => {
        const choice1 = (
          document.querySelector(
            'input[name="chkChoice-' +
              element.original.userName +
              '-firstChoice"]:checked'
          ) as HTMLInputElement | null
        )?.value;

        const choice2 = (
          document.querySelector(
            'input[name="chkChoice-' +
              element.original.userName +
              '-secondChoice"]:checked'
          ) as HTMLInputElement | null
        )?.value;

        const choice3 = (
          document.querySelector(
            'input[name="chkChoice-' +
              element.original.userName +
              '-thirdChoice"]:checked'
          ) as HTMLInputElement | null
        )?.value;

        if (choice1 !== undefined) {
          applicants.push({
            applicantId: element.original.id,
            majorId: element.original.firstMajorId,
          });
          listOfMajors.push({
            id: element.original.firstMajorId ?? -1,
            abbr: element.original.firstChoice ?? "",
            code: element.original.firstChoiceCode,
          });
        }

        if (choice2 !== undefined) {
          applicants.push({
            applicantId: element.original.id,
            majorId: element.original.secondMajorId,
          });
          listOfMajors.push({
            id: element.original.secondMajorId ?? -1,
            abbr: element.original.secondChoice ?? "",
            code: element.original.secondChoiceCode,
          });
        }
        if (choice3 !== undefined) {
          applicants.push({
            applicantId: element.original.id,
            majorId: element.original.thirdMajorId,
          });
          listOfMajors.push({
            id: element.original.thirdMajorId ?? -1,
            abbr: element.original.thirdChoice ?? "",
            code: element.original.thirdChoiceCode,
          });
        }
      });

      setAssignedApplicantsForInterview(applicants);
      setAssignedMajorsForInterview(listOfMajors);
      handleOpenInterview();
    }
  };

  const getRankingList = async () => {
    const appType = Cookies.get("appType");
    if (appType == undefined || appType == null) {
      return;
    }
    setIsRefetching(true);
    const ranklist = new RankingListAPI();
    await ranklist
      .GetRankingList(
        appType ?? "-1",
        activeList == undefined ? -1 : activeList.id
      )
      .then((data) => {
        setDataTable(data.result);
      });
    setIsRefetching(false);
  };

  const updateActiveList = async () => {
    const appType = Cookies.get("appType") ?? "-1";
    const ranklist = new RankingListAPI();
    await ranklist.GetActiveList(appType).then((data) => {
      if (data.result.id == 0) {
        ranklist.VirtualRankingList(appType).then((data) => {
          setDataTable(data.result);
        });
      } else {
        if (data.result.id !== activeList?.id) {
          setactiveList(data.result);
        }
      }
    });
  };

  const unfreeze = async () => {
    if (
      window.confirm(
        "Would you like to unfreezed the applicants who have not accepted for a major?"
      )
    ) {
      setWait(true);
      setIsRefetching(true);
      const ranklist = new RankingListAPI();
      if (activeList != null) {
        await ranklist.Unfreeze(activeList.id).then((data) => {
          if (data.isSuccess) {
            toast.success("Unfreezed!");
            setactiveList(undefined);
            getRankingList();
          } else {
            alert("Error in unfreeze the data");
          }
        });
        setIsRefetching(false);
      }
      setWait(false);
    }
  };

  const freeze = async () => {
    setWait(true);
    const appType = Cookies.get("appType");
    if (
      window.confirm(
        "The system will freeze all the applicants and create a new rank list?"
      )
    ) {
      toast.warning("Please wait until the ranking list created ...");
      setIsRefetching(true);
      const ranklist = new RankingListAPI();
      await ranklist.CalculateAndFreeze(appType ?? "0").then((data) => {
        getRankingList();
        setIsRefetching(false);
        setactiveList(data.result);
      });

      toast.success("Ranking list has been calculated and freezed!");
    }
    setWait(false);
  };

  const choiseNA = (choice: string, choiceNA: boolean) => {
    return choiceNA ? (
      <span className={classes.notApplicableMajor}>{choice}</span>
    ) : (
      <span>{choice}</span>
    );
  };

  const formatChoice = (row: MRT_Row<RankingListDTO>, choice: number) => {
    let choiceAbbr = null;
    let choiceName = null;
    let majorHasInterview = false;
    let assignedToInterview = false;
    let interviewDate: Date | undefined = undefined;
    let interviewResult = "";
    let hasResult = false;
    let choiceNA = false;
    if (choice == 1) {
      choiceAbbr = row.original.firstChoice;
      choiceNA = row.original.firstChoiceNA;
      choiceName = "firstChoice";
      majorHasInterview = row.original.hasInterviewFirstChoice;
      hasResult = row.original.hasAResultForAnInterviewFirstChoice;
      interviewDate = row.original.interviewDateFirstChoice;
      assignedToInterview = row.original.assignedToInterviewFirstChoice;
      interviewResult = row.original.interviewResultFirstChoice;
    } else if (choice == 2) {
      choiceAbbr = row.original.secondChoice;
      choiceNA = row.original.secondChoiceNA;
      choiceName = "secondChoice";
      majorHasInterview = row.original.hasInterviewSecondChoice;
      hasResult = row.original.hasAResultForAnInterviewSecondChoice;
      interviewDate = row.original.interviewDateSecondChoice;
      assignedToInterview = row.original.assignedToInterviewSecondChoice;
      interviewResult = row.original.interviewResultSecondChoice;
    } else if (choice == 3) {
      choiceAbbr = row.original.thirdChoice;
      choiceNA = row.original.thirdChoiceNA;
      choiceName = "thirdChoice";
      majorHasInterview = row.original.hasInterviewThirdChoice;
      hasResult = row.original.hasAResultForAnInterviewThirdChoice;
      interviewDate = row.original.interviewDateThirdChoice;
      assignedToInterview = row.original.assignedToInterviewThirdChoice;
      interviewResult = row.original.interviewResultThirdChoice;
    }
    if (choiceAbbr === "") return <span></span>;
    const formatedChoice = (
      <div>
        {(() => {
          if (
            row.original.acceptedMajor != "-" ||
            activeList == undefined ||
            activeList?.id == 0
          ) {
            return <span>{choiseNA(choiceAbbr ?? "-", choiceNA)}</span>;
          } else if (
            !majorHasInterview ||
            (majorHasInterview && assignedToInterview && hasResult)
          ) {
            return (
              <div>
                <input
                  type="radio"
                  id={choiceName ?? "" + row.original.userName}
                  name={"choice" + row.original.userName}
                  value={choice}
                  className={classes.radioButton}
                />
                {choiseNA(choiceAbbr ?? "", choiceNA)}

                <button
                  aria-label="delete"
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => {
                    const choice = document.querySelector(
                      'input[name="choice' +
                        row.original.userName +
                        '"]:checked'
                    ) as HTMLInputElement;
                    if (choice != undefined && choice != null) {
                      choice.checked = false;
                    } else {
                      alert("Not selected");
                    }
                  }}
                >
                  x
                </button>
                {majorHasInterview ? (
                  <div>
                    Interview result: <strong>{interviewResult}</strong>
                  </div>
                ) : null}
              </div>
            );
          } else if (majorHasInterview && assignedToInterview && !hasResult) {
            //Disable checkbox
            return (
              <div>
                <input
                  type="checkbox"
                  id={choiceName ?? "" + row.original.userName + choiceAbbr}
                  name={"checkedChoice" + row.original.userName}
                  value={choice}
                  disabled
                  checked
                  className={classes.radioButton}
                />
                <span>
                  {choiseNA(choiceAbbr ?? "", choiceNA)}
                  <br />
                  Interview date:
                  {interviewDate?.toString() !== "0001-01-01T00:00:00"
                    ? moment(interviewDate).format("DD/MM/YYYY")
                    : ""}
                </span>
              </div>
            );
          } else if (majorHasInterview && !assignedToInterview) {
            return (
              <div>
                <input
                  type="checkbox"
                  id={`chkChoice-${row.original.userName}-${choiceName}`}
                  name={`chkChoice-${row.original.userName}-${choiceName}`}
                  value={choice}
                  className={classes.radioButton}
                />{" "}
                {choiseNA(choiceAbbr ?? "", choiceNA)}
              </div>
            );
          } else {
            return <span>{choiseNA(choiceAbbr ?? "", choiceNA)}</span>;
          }
        })()}
      </div>
    );
    return formatedChoice;
  };

  const columns = useMemo<MRT_ColumnDef<RankingListDTO>[]>(
    () => [
      {
        header: "id",
        accessorKey: "id",
      },
      {
        header: "Application No.",
        accessorKey: "userName",
      },
      {
        header: "Serial",
        accessorKey: "serial",
      },
      {
        header: "English name",
        accessorKey: "englishName",
      },
      {
        header: "First choice",
        accessorKey: "firstChoice",
        filterSelectOptions: majorOptions,
        filterVariant: "select",
        Cell: ({ renderedCellValue, row }) => formatChoice(row, 1),
      },
      {
        header: "Second choice",
        accessorKey: "secondChoice",
        filterSelectOptions: majorOptions,
        filterVariant: "select",
        Cell: ({ renderedCellValue, row }) => formatChoice(row, 2),
      },
      {
        header: "Third choice",
        accessorKey: "thirdChoice",
        filterSelectOptions: majorOptions,
        filterVariant: "select",
        Cell: ({ renderedCellValue, row }) => formatChoice(row, 3),
      },
      {
        header: "Accepted major",
        accessorKey: "acceptedMajor",
        filterSelectOptions: majorAcceptanceOptions,
        filterVariant: "select",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.acceptedMajor !== "" &&
            row.original.acceptedMajor !== "-" ? (
              <div className={classes.accepted}>
                {row.original.acceptedMajor}
                <br />
                {row.original.acceptedDate?.toString() !== "0001-01-01T00:00:00"
                  ? moment
                      .utc(row.original.acceptedDate)
                      .local()
                      .format("DD/MM/YYYY HH:mm")
                  : ""}
              </div>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        size: 20,
        header: "Tawjihi average",
        accessorKey: "tawjihiAverage",
        columnFilterModeOptions: ["between", "greaterThan", "lessThan"],
        filterFn: "between",
      },
      {
        header: "Arabic name",
        accessorKey: "arabicName",
      },
      {
        header: "Last modifieddate",
        accessorKey: "lastModifiedDate",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <span>
              {row.original.verified?.toString() !== "0001-01-01T00:00:00"
                ? moment
                    .utc(row.original.lastModifiedDate)
                    .local()
                    .format("DD/MM/YYYY HH:mm")
                : ""}
            </span>
          </div>
        ),
      },

      {
        header: "Verified",
        accessorKey: "verified",
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {(() => {
              switch (row.original.verified) {
                case "Verified":
                  return (
                    <span className={`${classes.status} ${classes.verified}`}>
                      Verified
                    </span>
                  );
                case "Imported":
                  return (
                    <span className={`${classes.status} ${classes.imported}`}>
                      Imported
                    </span>
                  );
                case "Not verified":
                  return (
                    <span
                      className={`${classes.status} ${classes.notVerified}`}
                    >
                      Not verified
                    </span>
                  );
                default:
                  return (
                    <div className={classes.notVerified}>
                      {row.original.verified}
                    </div>
                  );
              }
            })()}
          </div>
        ),
      },
    ],
    [majorOptions, activeList]
  );

  const updateListOfAssignedInterview = async () => {
    await getRankingList();
    toast.success("Updated");
    handleCloseInterview();
  };

  const removeFromRankingList = async () => {
    if (
      window.confirm("Would you remove the applicants from the ranking list?")
    ) {
      const appType = Cookies.get("appType");
      if (appType == undefined || appType == null) {
        return;
      }
      setWait(true);
      const ranklist = new RankingListAPI();
      await ranklist
        .RemoveFromRankingList({
          ListId: activeList?.id ?? 0,
          ListOfApplicants: Object.keys(rowSelection),
        })
        .then((data) => {
          if (data.result == "") {
            toast.success("Removed", { autoClose: false });
          } else {
            toast.error(data.result, { autoClose: false });
          }
        });
      getRankingList();
      setRowSelection({});
      setWait(false);
    }
  };

  const print = async () => {
    window.open(
      `/admin/printrankinglist/${Cookies.get("appType")}/${activeList!.id}/${
        selectedMajorOptionsforprint?.value
      }`
    );
  };

  return (
    <>
      {wait ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Spinner />
          Please wait until process complete
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {" "}
          <header>
            <AdminHeader
              onTypeHandler={onTypeChangeHandler}
              title="Ranking list"
            />
            {activeList == null || activeList?.id == 0 ? (
              <Alert severity="warning">
                <h4>No active ranking list has been created!</h4>
                <div className={classes.activeList}>
                  <input className="btn" onClick={freeze} value="Freeze" />
                </div>
              </Alert>
            ) : (
              <div>
                <div>
                  <div className="grid grid--7--cols">
                    <div className={classes.activeList}>
                      Active list:{" "}
                      {moment
                        .utc(activeList?.listDateTime)
                        .local()
                        .format("DD/MM/YYYY HH:mm")}{" "}
                      [{activeList?.listNumber}]{" "}
                    </div>
                    <input
                      className="btn"
                      onClick={acceptForAMajor}
                      value="Accept"
                    />
                    <input
                      className="btn"
                      onClick={acceptForAnInterview}
                      value="Assign interviews"
                    />
                    <div>
                      <label>Major for printing:</label>
                      <Select<IOption, false>
                        options={majorOptionsforprint}
                        onChange={(val) => {
                          setSelectedMajorOptionsForPrint(val!);
                        }}
                        value={selectedMajorOptionsforprint}
                        styles={{
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                        }}
                      />
                    </div>
                    <input className="btn" value="Print" onClick={print} />
                    <input
                      className="btn"
                      value="Remove from ranking list"
                      onClick={removeFromRankingList}
                    />
                    <input
                      className="btn goldbutton"
                      onClick={unfreeze}
                      value="Unfreeze"
                    />
                  </div>
                </div>
              </div>
            )}
          </header>
          <section className={classes.listOfApplicants}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={columns}
                data={dataTable}
                tableInstanceRef={tableInstanceRef}
                enablePagination={true}
                enableRowSelection={(row) => row.original.acceptedMajor === "-"}
                getRowId={(row) => row.id}
                onRowSelectionChange={setRowSelection}
                state={{
                  rowSelection: rowSelection,
                  showProgressBars: isRefetching,
                }}
                muiTableContainerProps={{
                  sx: {
                    minHeight: 0,
                    maxHeight: "calc(100vh - 400px)",
                  },
                }}
                initialState={{
                  pagination: { pageSize: 15, pageIndex: 0 },
                  showColumnFilters: true,
                  columnPinning: { left: ["userName"], right: ["verified"] },
                  columnVisibility: {
                    id: false,
                    serial: true,
                    acceptedMajor: true,
                  },
                  density: "compact",
                }}
                muiTableBodyCellProps={({ cell }) => ({
                  onDoubleClick: (event) => {
                    setindex(cell.row.index);
                    handleOpen();
                    dispatch(setAdminApplicantId(dataTable[cell.row.index].id));
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                enableRowNumbers
                muiTableBodyRowProps={({ row }) => ({
                  onClick: (e) => {
                    let stoploop = false;
                    if (!e.shiftKey) {
                    } else {
                      tableInstanceRef.current
                        ?.getRowModel()
                        .rows.forEach((element) => {
                          if (row.id === element.id) {
                            element.toggleSelected();
                            stoploop = true;
                          } else if (!stoploop) {
                            const firstChoice = document.getElementById(
                              "firstChoice" + element.original.userName
                            ) as HTMLInputElement | null;
                            firstChoice!.checked = true;
                            element.toggleSelected();
                          }
                        });
                    }
                  },
                  sx: {
                    cursor: "pointer",
                  },
                })}
                rowNumberMode="static"
                positionToolbarAlertBanner="bottom"
              />
            </ThemeProvider>
          </section>
          <Modal
            show={open}
            onHide={handleClose}
            className={classes.modal}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Applicant Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ApplicantDashboard
                nextrow={() => nextPrevious(true)}
                previousrow={() => nextPrevious(false)}
              />
            </Modal.Body>
          </Modal>
          <Modal
            show={openAcceptance}
            onHide={handleCloseAcceptance}
            className={classes.modal}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Acceptance to major</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AcceptedToMajor
                listOfApplicants={acceptedApplicants}
                updateList={updateList}
              />
            </Modal.Body>
          </Modal>
          <Modal
            show={openInterview}
            onHide={handleCloseInterview}
            className={classes.modal}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Assigned to interview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AssignedToInterview
                listOfApplicants={assignedApplicantsForInterview}
                listOfMajors={assignedMajorsForInterview}
                updateList={updateListOfAssignedInterview}
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};
