import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { ListOfAcceptedApplicantsDTO } from "../types/AcceptedList";

export class AcceptedListAPI {
  async GetAllAcceptedStudents(
    appType: string
  ): Promise<IAPIResponse<ListOfAcceptedApplicantsDTO[]>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/acceptance/getlistofacceptedapplicants/" + appType
    );
    let templetes: IAPIResponse<ListOfAcceptedApplicantsDTO[]> = response.data;
    return Promise.resolve(templetes);
  }
}
