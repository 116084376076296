import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import uploadfile from "ionicons/dist/collection/components/icon/svg/cloud-upload.svg";
import camera from "ionicons/dist/collection/components/icon/svg/camera.svg";
import { WebcamCapture } from "./WebcamCapture";
import { Documents, UploadDocument } from "../../../API/Applicant/DocumentAPI";
import { UploadedOtherDocumentResponseDTO } from "../../../types/Applicant/UploadedDocumentDTO";
import classes from "./Document.module.css";
import { toast } from "react-toastify";

const ListOfOtherDocuments = () => {
  const [showWebCapture, setShowWebCapture] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseWebCapture = () => setShowWebCapture(false);
  const hiddenaddOtherFileInput = React.useRef<HTMLInputElement>(null);

  const fetchData = async () => {
    let listOfDocuments = new Documents();
    setOtherdocuments(
      (await listOfDocuments.GetUploadedOtherDocuments()).result
    );
  };

  const handleOtherChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileUploaded: File | null =
      event.target.files == null ? null : event.target.files[0];

    if (note === "") {
      alert(t("Error_Required")! + t("Documents_AddNote"));
      return;
    }

    let uploadDocument = new UploadDocument();
    await uploadDocument.UploadOtherDocument({
      note: note,
      formFile: fileUploaded,
    });
    fetchData();
    setShow(false);
    toast.success(t("Documents_SuccessFile"));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [note, setNote] = useState<string>("");
  const [otherdocuments, setOtherdocuments] = useState<
    UploadedOtherDocumentResponseDTO[]
  >([]);

  const deleteFile = async (id: number) => {
    const c = window.confirm("هل ترغب في حذف الملف؟");
    if (c) {
      let deleteFile = new Documents();
      setOtherdocuments((await deleteFile.DeleteOtherDocument(id)).result);
    }
  };

  return (
    <div>
      <form>
        <div className={classes.addButton}>
          <button
            className="btn smallbtn"
            type="button"
            onClick={() => setShow(true)}
          >
            {t("Documents_AddOther")!}
          </button>
        </div>
        <div>
          {otherdocuments.map((document) => (
            <div
              className={
                classes.mainGrid +
                " " +
                classes.document +
                (document.filePath != null
                  ? " " + classes.uploadedDocument
                  : "")
              }
            >
              <p>{document.note}</p>
              <a
                href={"https://admapi.bethlehem.edu/" + document.filePath}
                className="btn smallbtn"
                target="_blank"
                rel="noreferrer"
              >
                {t("Documents_ShowFile")}
              </a>
              <button
                type="button"
                style={{ backgroundColor: "red" }}
                className="btn smallbtn "
                onClick={() => deleteFile(document.id)}
              >
                {t("Documents_DeleteFile")}{" "}
              </button>
            </div>
          ))}
        </div>
      </form>{" "}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={classes.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Documents_UploadOtherDocuments")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>
              <label htmlFor="firstName">
                {t("Documents_AddNote")}
                <input
                  type="text"
                  id="firstName"
                  onChange={(e) => setNote(e.target.value)}
                  required
                />
              </label>
            </p>
            <div className="grid grid--2--cols">
              <div className={classes.uploadDocument}>
                <input
                  accept=".pdf,.jpg,.doc,.docx, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                  type="file"
                  // key={document.id.toString()}
                  onChange={(e) => handleOtherChange(e)}
                  hidden
                  ref={hiddenaddOtherFileInput}
                />
                <button
                  type="button"
                  onClick={() => {
                    hiddenaddOtherFileInput.current?.click();
                  }}
                  className={classes.btn}
                >
                  <img
                    className="Capture image"
                    src={uploadfile}
                    alt="Upload file, Bethlehem University"
                  />
                </button>
                <p>{t("Documents_Upload")}</p>
              </div>
              <div className={classes.uploadDocument}>
                <button
                  type="button"
                  onClick={() => {
                    setShowWebCapture(true);
                  }}
                  className={classes.btn}
                >
                  <img
                    className="Capture image"
                    src={camera}
                    alt="Upload file, Bethlehem University"
                  />
                </button>
                <p>{t("Documents_Capture")}</p>
              </div>
            </div>
            <div className="grid grid--1--cols">
              <button type="button" className="btn">
                {t("Add")}
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        show={showWebCapture}
        onHide={handleCloseWebCapture}
        keyboard={false}
        className={classes.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Documents_Capture")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WebcamCapture />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ListOfOtherDocuments;
