import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  AcceptApplicantDTO,
  AcceptListOfApplicantsDTO,
} from "../../../types/Admin/ApplicantsDTO";
import { RankingListAPI } from "../../../API/Admin/AdminRankingListAPI";
import { Alert } from "react-bootstrap";
import Spinner from "../../../features/Spinner/Spinner";
import { toast } from "react-toastify";

export const AcceptedToMajor = (props: any) => {
  const [wait, setWait] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AcceptListOfApplicantsDTO>();

  const [listOfApplicants, setListOfApplicants] = useState<
    AcceptApplicantDTO[]
  >([]);

  useEffect(() => {
    setListOfApplicants(props.listOfApplicants);
  }, []);

  const onSubmit: SubmitHandler<AcceptListOfApplicantsDTO> = async (data) => {
    setWait(true);
    const rankingList = new RankingListAPI();
    await rankingList
      .AcceptList({
        emailTemplete: 0,
        smsTemplete: 0,
        listOfApplicants: listOfApplicants,
      })
      .then(() => {
        props.updateList();
      });
    toast.success("Updated!");
    setWait(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Alert variant="warning">
            <h4>
              Would you like to accept{" "}
              <strong>{props.listOfApplicants.length}</strong> applicants for
              majors?
            </h4>
          </Alert>
        </div>
        <div>
          {props.listOfApplicants.length > 0 ? (
            <div>
              {wait ? (
                <Spinner />
              ) : (
                <button className="btn" type="submit">
                  Accept the students
                </button>
              )}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};
