import { useState, useEffect } from "react";
import { AdminCommentsAPI } from "../../API/Admin/AdminCommentsAPI";
import { RootState } from "../../store/store";
import { useAppSelector } from "../../store/hooks";
import { ListOfCommentsResponseDTO } from "../../types/Admin/AdminCommentsDTO";
import { ListGroup, ListGroupItem, Tab, Tabs } from "react-bootstrap";
import moment from "moment";

const Comments = () => {
  const [emailSubject, setemailSubject] = useState<string>("");
  const [emailBody, setemailBody] = useState<string>("");
  const [smsMessage, setsmsMessage] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [comments, setComments] = useState<ListOfCommentsResponseDTO[]>([]);
  const admin = useAppSelector((state: RootState) => state.admin);

  useEffect(() => {
    const fetchData = async () => {
      const commentAPI = new AdminCommentsAPI();
      setComments((await commentAPI.ListOfComments(admin.userId)).result);
    };
    fetchData();
  }, [admin, refreshData]);

  const sendEmail = async () => {
    if (window.confirm("Would you like to send the email?")) {
      const commentAPI = new AdminCommentsAPI();
      await commentAPI.sendEmail({
        userId: admin.userId,
        body: emailBody,
        subject: emailSubject,
      });
      setemailSubject("");
      setemailBody("");
      setRefreshData(!refreshData);
    }
  };

  const sendSMS = async () => {
    if (window.confirm("Would you like to send the SMS?")) {
      const commentAPI = new AdminCommentsAPI();
      await commentAPI.sendSMS({
        userId: admin.userId,
        message: smsMessage,
      });
      setsmsMessage("");
      setRefreshData(!refreshData);
    }
  };

  const addComment = async () => {
    if (window.confirm("Would you like to add a comment?")) {
      const commentAPI = new AdminCommentsAPI();
      await commentAPI.addComment({
        userId: admin.userId,
        comment: comment,
      });
      setComment("");
      alert("Added!");
      setRefreshData(!refreshData);
    }
  };

  return (
    <div className="grid grid--1--col ">
      <div className="formBox">
        <Tabs
          defaultActiveKey="communications"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="communications" title="Communications">
            <ListGroup>
              {comments
                .filter((c) => c.codesCommentTypeId === 1)
                .map((c) => {
                  return (
                    <ListGroupItem key={c.Id}>
                      {c.createdDate?.toString() !== "0001-01-01T00:00:00"
                        ? moment
                            .utc(c.createdDate)
                            .local()
                            .format("DD/MM/YYYY HH:mm")
                        : ""}
                      /{c.comment}
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </Tab>
          <Tab eventKey="actions" title="Actions">
            <ListGroup>
              {comments
                .filter((c) => c.codesCommentTypeId === 2)
                .map((c) => {
                  return (
                    <ListGroupItem key={c.Id}>
                      {c.createdDate?.toString() !== "0001-01-01T00:00:00"
                        ? moment(c.createdDate).format("DD/MM/YYYY HH:mm")
                        : ""}
                      /{c.comment}
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </Tab>
        </Tabs>
      </div>
      <div className="formBox">
        <div>
          <textarea
            id="comment"
            name="comment"
            rows={2}
            onChange={(e) => setComment(e.target.value)}
            cols={50}
            value={comment}
            placeholder="Comment"
            style={{ width: "100%", marginBottom: "1.2rem", padding: "1.2rem" }}
          ></textarea>
        </div>
        <button onClick={addComment} className="btn">
          Add
        </button>
      </div>
      <div className="formBox">
        <div>
          <input
            className="textbox"
            placeholder="subject"
            onChange={(e) => setemailSubject(e.target.value)}
            value={emailSubject}
            type="textbox"
            style={{ width: "100%", marginBottom: "1.2rem", padding: "1.2rem" }}
          />
        </div>
        <div>
          <textarea
            id="emailbody"
            name="emailbody"
            rows={5}
            onChange={(e) => setemailBody(e.target.value)}
            value={emailBody}
            cols={50}
            placeholder="Body"
            style={{ width: "100%", marginBottom: "1.2rem", padding: "1.2rem" }}
          ></textarea>
        </div>
        <div>
          <button className="btn" onClick={sendEmail}>
            Submit
          </button>
        </div>
      </div>
      <div className="formBox">
        <div>
          <textarea
            id="story"
            name="story"
            rows={2}
            cols={50}
            value={smsMessage}
            onChange={(e) => setsmsMessage(e.target.value)}
            placeholder="SMS message"
            style={{ width: "100%", marginBottom: "1.2rem", padding: "1.2rem" }}
          ></textarea>
        </div>
        <button onClick={sendSMS} className="btn">
          Send
        </button>
      </div>
    </div>
  );
};

export default Comments;
