import { IAPIResponse } from "../../types/IAPIResponse";
import axios from "axios";
import { ICode } from "../../types/ICode";
import { IOption } from "../../types/Codes/IOptions";

export class CodesAPI {
  constructor(public path: string) {}
  async GetAll(): Promise<IAPIResponse<ICode[]>> {
    const response = await axios.get<IAPIResponse<ICode[]>>(this.path);
    let codes: IAPIResponse<ICode[]> = response.data;
    return new Promise<IAPIResponse<ICode[]>>((resolve, reject) => {
      resolve(codes);
    });
  }
}

export class OptionsAPI {
  constructor(public path: string) {}
  async GetAll(): Promise<IAPIResponse<IOption[]>> {
    const response = await axios.get<IAPIResponse<IOption[]>>(this.path);
    let options: IAPIResponse<IOption[]> = response.data;
    return Promise.resolve(options);
  }
}
