import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Spinner from "../../Spinner/Spinner";
import NextPrevious from "../../Layout/NextPrevious";
import { useNavigate } from "react-router-dom";
import school from "../../../assests/illustration/school.svg";
import classes from "./Schoolinfo.module.css";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { RegionsWithSchoolsAPI } from "../../../API/Applicant/RegionsAPI";
import { IGroupOption, IOption } from "../../../types/Codes/IOptions";
import { SchoolInfoRequestDTO } from "../../../types/Applicant/SchoolInfoDto";
import { CodesCertificateAPI } from "../../../API/Codes/CodesCertificateAPI";
import {
  GetSchoolInfoAPI,
  UpdateSchoolInfoAPI,
} from "../../../API/Applicant/SchoolInfoAPI";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { setApplicantStatus } from "../../../store/Slices/ApplicantStatusState";
import { setUpdatedRecord } from "../../../store/Slices/AdminApplicantId";

const formatGroupLabel = (data: IGroupOption) => (
  <div className="groupStyles">
    <span>{data.label}</span>
  </div>
);

const SchoolInfo = () => {
  const { t } = useTranslation();
  const [wait, setWait] = useState<boolean>(false);
  const [regionswithschools, setRegionswithschools] = useState<IGroupOption[]>(
    []
  );
  const [selectedSchoolOption, setSelectedSchoolOption] = useState<IOption>();
  const [certificateTypes, setcertificateTypes] = useState<IOption[]>([]);
  const [certificateType, setcertificateType] = useState<number | null>(null);
  const [tawjihiStreams, setTawjihiStreams] = useState<IOption[]>([]);
  const [tawjihiImported, setTawjihiImported] = useState<boolean>(false);
  const [schoolImported, setSchoolImported] = useState<boolean>(false);
  const [showTawjihiAverage, setshowTawjihiAverage] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SchoolInfoRequestDTO>();
  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );

  const admin = useAppSelector((state: RootState) => state.admin);
  const freezedValue: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );
  const freezed =
    (!admin.allowToEdit && admin.userId !== undefined) || freezedValue;

  useEffect(() => {
    setWait(true);
    async function fetchData() {
      let regionswithschoolsAPI = new RegionsWithSchoolsAPI();
      const schools = (await regionswithschoolsAPI.GetAll()).result;
      setRegionswithschools(schools);

      let tawjihiCertificatesAPI = new CodesCertificateAPI();
      const certificates = (await tawjihiCertificatesAPI.GetAllCertificates())
        .result;
      setcertificateTypes(certificates);
      const tawjihi = (await tawjihiCertificatesAPI.GetAllTawjihiStreams())
        .result;
      setTawjihiStreams(tawjihi);

      let schoolInfo = new GetSchoolInfoAPI();
      let info = (await schoolInfo.Get(admin.userId)).result;
      setcertificateType(info.certificateId);
      setshowTawjihiAverage(
        (info.certificateId !== 1 &&
          info.tawjihiYear !== new Date().getFullYear()) ||
          info.tawjihiYear == null ||
          info.tawjihiYear < new Date().getFullYear() ||
          info.tawjihiAverage !== 0
      );

      if (info == null) return; //no datafound
      reset(info);
      setTawjihiImported(info.tawjihiImported);
      setSchoolImported(info.schoolImported);

      schools.forEach((r) =>
        r.options.forEach((o) =>
          o.value === info.schoolId?.toString()
            ? setSelectedSchoolOption(o)
            : null
        )
      );
    }
    fetchData();
    setWait(false);
  }, [reset, admin]);

  const onSubmit: SubmitHandler<SchoolInfoRequestDTO> = async (data) => {
    setWait(true);
    let updateschoolInfo = new UpdateSchoolInfoAPI(data);
    await updateschoolInfo.update(admin.userId).then(() => {
      dispatch(setApplicantStatus(applicantStatus <= 7 ? 7 : applicantStatus));
      setWait(false);
      toast.success(t("School_sent"));
    });
    dispatch(setUpdatedRecord(true));
  };

  return (
    <div
      className={
        "container grid gridbox marginTopBottom" +
        (admin.userId !== undefined ? " admin" : "")
      }
    >
      <aside className="instruction">
        <p>{t("School_Instruction")}</p>
        <img
          className="instructionimg"
          src={school}
          alt="Contact info, Bethlehem University"
        />
      </aside>
      {!wait ? (
        <div className="formBoxContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formBox">
              <div className="formBoxHeader">
                <p>{t("School_Title")}</p>
              </div>

              <div>
                {errors.schoolId && errors.schoolId?.type === "required" && (
                  <p className="errorMessage">
                    {t("Error_Required")! + t("School_SchoolName")!}
                  </p>
                )}
                {errors.codesCertificateTypeId &&
                  errors.codesCertificateTypeId?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("School_CertificateType")!}
                    </p>
                  )}
                {errors.tawjihiStreamId &&
                  errors.tawjihiStreamId?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("School_TawjihiType")!}
                    </p>
                  )}
                {errors.tawjihiYear &&
                  errors.tawjihiYear?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("School_CertificateYear")!}
                    </p>
                  )}
                {errors.seatNo && errors.seatNo?.type === "required" && (
                  <p className="errorMessage">
                    {t("Error_Required")! + t("School_TawjihiSeatNo")!}
                  </p>
                )}
                {errors.tawjihiAverage &&
                  errors.tawjihiAverage?.type === "required" && (
                    <p className="errorMessage">
                      {t("Error_Required")! + t("School_TawjihiAverage")!}
                    </p>
                  )}
              </div>

              <div className={"grid grid--2--cols"}>
                <div className={classes.school}>
                  <label htmlFor="schoolId">{t("School_SchoolName")!}</label>

                  <div>
                    <Controller
                      control={control}
                      name="schoolId"
                      render={({ field: { onChange, value, ref } }) => (
                        <Select<IOption, false, IGroupOption>
                          options={regionswithschools}
                          {...register("schoolId", { required: true })}
                          formatGroupLabel={formatGroupLabel}
                          isDisabled={
                            (schoolImported || freezed) && !admin.allowToEdit
                          }
                          onChange={(val) => {
                            setValue(
                              "schoolId",
                              val == null ? "-1" : val.value
                            );
                            setSelectedSchoolOption(val!);
                          }}
                          className={
                            schoolImported && admin.userId !== undefined
                              ? " importedAdmin"
                              : ""
                          }
                          value={selectedSchoolOption}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="certificatetype">
                    {t("School_CertificateType")!}
                  </label>
                  <select
                    id="certificatetype"
                    {...register("codesCertificateTypeId", {
                      required: true,
                    })}
                    disabled={admin.userId === undefined || !admin.allowToEdit}
                  >
                    {certificateTypes &&
                      certificateTypes?.map((certificate) => (
                        <option
                          key={certificate.value}
                          value={certificate.value}
                        >
                          {certificate.label}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="material">{t("School_TawjihiType")!}</label>
                  <select
                    id="material"
                    disabled={
                      (tawjihiImported || freezed) && !admin.allowToEdit
                    }
                    {...register("tawjihiStreamId", {
                      required: true,
                    })}
                    className={
                      "textbox" +
                      ((tawjihiImported || freezed) &&
                      admin.userId !== undefined
                        ? " importedAdmin"
                        : "")
                    }
                  >
                    {tawjihiStreams &&
                      tawjihiStreams?.map((stream) => (
                        <option key={stream.value} value={stream.value}>
                          {stream.label}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="tawjihiYear">
                    {t("School_CertificateYear")!}
                  </label>
                  <input
                    {...register("tawjihiYear", {
                      required: true,
                    })}
                    className={
                      "textbox" +
                      ((tawjihiImported || freezed) &&
                      admin.userId !== undefined
                        ? " importedAdmin"
                        : "")
                    }
                    disabled={
                      (tawjihiImported || freezed) && !admin.allowToEdit
                    }
                    placeholder={t("School_CertificateYear")!}
                    type="textbox"
                    onChange={(e) => {
                      if (
                        Number.parseInt(e.target.value) ===
                        new Date().getFullYear()
                      ) {
                        setValue("tawjihiAverage", 0);
                        setshowTawjihiAverage(false);
                      } else {
                        setshowTawjihiAverage(true);
                      }
                    }}
                  />
                </div>
                {certificateType === 1 ? (
                  <div>
                    <label htmlFor="seatNo">{t("School_TawjihiSeatNo")!}</label>
                    <input
                      {...register("seatNo", {
                        required: true,
                      })}
                      className={
                        "textbox" +
                        ((tawjihiImported || freezed) &&
                          admin.userId !== undefined)
                          ? " importedAdmin"
                          : ""
                      }
                      placeholder={t("School_TawjihiSeatNo")!}
                      type="textbox"
                      disabled={!admin.allowToEdit}
                    />
                  </div>
                ) : null}
                {showTawjihiAverage || admin.allowToEdit ? (
                  <div>
                    <label htmlFor="tawjihiAverage">
                      {t("School_TawjihiAverage")!}
                    </label>
                    <input
                      {...register("tawjihiAverage", {
                        required: true,
                      })}
                      className={
                        "textbox" +
                        ((tawjihiImported || freezed) &&
                        admin.userId !== undefined
                          ? " importedAdmin"
                          : "")
                      }
                      placeholder={t("School_TawjihiAverage")!}
                      disabled={
                        (tawjihiImported || freezed) && !admin.allowToEdit
                      }
                      type="textbox"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </form>

          {admin.userId !== undefined && admin.allowToEdit ? (
            <div className="formBox">
              <button
                className="btn"
                onClick={handleSubmit((d) => {
                  onSubmit(d);
                  alert("Saved!");
                })}
              >
                Save
              </button>
            </div>
          ) : (
            <>
              {!freezed && admin.userId === undefined ? (
                <NextPrevious
                  className="nextprevious"
                  next={true}
                  pre={true}
                  onClickPrevious={handleSubmit(async (d) => {
                    await onSubmit(d);
                    navigate("/applicant/contact");
                  })}
                  onClickNext={handleSubmit(async (d) => {
                    await onSubmit(d);
                    navigate("/applicant/choices");
                  })}
                />
              ) : null}
            </>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SchoolInfo;
