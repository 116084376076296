import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import { AcceptedMajorDTO } from "../../types/Applicant/AcceptedMajorDTO";
import { PrintApplicationDTO } from "../../types/Applicant/PrintApplicationDTO";
import { InvoiceBankType } from "../../features/Applicant/Invoice/Bankstamp/BankstampType";
import { InterviewInstructionsDTO } from "../../Admin/types/InterviewList";

export class InstructionsAndAcceptanceAPI {
  async GetMajorDetails(
    id: string | undefined
  ): Promise<IAPIResponse<AcceptedMajorDTO>> {
    const response = await axios.get("/applicant/acceptanceinstructions/" + id);
    let acceptanceDetails: IAPIResponse<AcceptedMajorDTO> = response.data;
    return Promise.resolve(acceptanceDetails);
  }

  async GetInterviewInstructions(
    id: string | undefined
  ): Promise<IAPIResponse<InterviewInstructionsDTO[]>> {
    const response = await axios.get(
      "/applicant/acceptanceinstructions/interviewsinstructions/" + id
    );
    let acceptanceDetails: IAPIResponse<InterviewInstructionsDTO[]> =
      response.data;
    return Promise.resolve(acceptanceDetails);
  }

  async GetPrintApplicationDetails(
    id: string | undefined
  ): Promise<IAPIResponse<PrintApplicationDTO>> {
    const response = await axios.get(
      "/applicant/acceptanceinstructions/print/" + id
    );
    let acceptanceDetails: IAPIResponse<PrintApplicationDTO> = response.data;
    return Promise.resolve(acceptanceDetails);
  }

  async GetPrintToken(): Promise<IAPIResponse<string>> {
    const response = await axios.get(
      "/applicant/acceptanceinstructions/getprinttoken/"
    );
    let printapp: IAPIResponse<string> = response.data;
    return Promise.resolve(printapp);
  }

  async GetInvoiceDetail(
    token: string | undefined
  ): Promise<IAPIResponse<InvoiceBankType>> {
    const response = await axios.get(
      "/applicant/acceptanceinstructions/invoicedetail/" + token
    );
    let printapp: IAPIResponse<InvoiceBankType> = response.data;
    return Promise.resolve(printapp);
  }
}
