import React, { useState } from "react";
import card from "../../../assests/illustration/seatnoandid.svg";
import classes from "./IDandStudentNo.module.css";
import Header from "../../login/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IdAndStudentnoAPI } from "../../../API/Graduate/IdAndStudentnoAPI";
import { ErrorMessage } from "@hookform/error-message";
import Footer from "../../Layout/Footer";
import { IdAndStudentNoDTO } from "../../../types/Applicant/graduate/IdAndStudentNoDTO";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { setApplicantStatus } from "../../../store/Slices/ApplicantStatusState";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";

const IDandStudentNo = () => {
  const [showstudentno, setshowstudentno] = useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IdAndStudentNoDTO>();

  const onSubmit: SubmitHandler<IdAndStudentNoDTO> = async (data) => {
    if (!window.confirm(t("confirm")!)) {
      return;
    }
    toast.success(t("SuccessIno"));
    let idandstudentno = new IdAndStudentnoAPI();
    await idandstudentno.StudentNoAndID(data);
    dispatch(setApplicantStatus(applicantStatus <= 2 ? 2 : applicantStatus));
    navigate("/applicant/info");
  };

  const signout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <header>
        <Header dark={false} />
      </header>

      <main className={"container"}>
        <div className={"container grid gridbox marginTopBottom extendcolgap"}>
          <aside className="instruction">
            <p>{t("ApplicantInfo_instruction")}</p>
            <img className={classes.img} src={card} alt="Bethlehm University" />
          </aside>
          <form className={classes.content} onSubmit={handleSubmit(onSubmit)}>
            <span className="headingPrimary">{t("IDandStudentNo_Header")}</span>
            <select
              className={classes.tawjihiTypes}
              onChange={(e) => {
                e.target.value === "yes"
                  ? setshowstudentno(true)
                  : setshowstudentno(false);
                if (e.target.value !== "1") {
                  setValue("studentNo", "");
                }
              }}
            >
              <option value="yes">{t("yes")}</option>
              <option value="no">{t("no")}</option>
            </select>

            <p className={classes.contenttxt}>{t("InputInfo")}</p>

            {showstudentno ? (
              <>
                {errors.studentNo && errors.studentNo?.type === "required" && (
                  <p className="errorMessage">
                    {t("Error_Required")! + t("IDandStudentNo_StudentNo")!}
                  </p>
                )}
                {errors.studentNo && errors.studentNo?.type === "maxLength" && (
                  <p className="errorMessage">{t("Error_StudentNoLength")!}</p>
                )}
                <input
                  {...register("studentNo", {
                    required: showstudentno ? true : false,
                    maxLength: 10,
                  })}
                  className="textbox"
                  placeholder={t("IDandStudentNo_StudentNo")!}
                  type="textbox"
                />
              </>
            ) : null}

            {errors.studentNo && errors.studentNo?.type === "required" && (
              <p className="errorMessage">{t("Error_Required")! + t("ID")!}</p>
            )}
            <input
              {...register("idNo", {
                required: true,
              })}
              className="textbox"
              placeholder={t("ID")!}
              type="textbox"
            />

            <div className="grid grid--2--cols ">
              <input type="submit" value={t("Continue")!} className={"btn"} />
              <button onClick={signout} className={"btn redbutton"}>
                {t("Signout")}
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default IDandStudentNo;
