import React, { useEffect, useState } from "react";
import card from "../../assests/illustration/seatnoandid.svg";
import classes from "./IdAndSeatNo.module.css";
import Header from "../login/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IdAndSeatNoAPI } from "../../API/Undergraduate/IdAndSeatNoAPI";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { IdAndSeatNoDTO } from "../../types/Applicant/undergraduate/IdAndSeatNoDTO";
import { ErrorMessage } from "@hookform/error-message";
import { CodesCertificateAPI } from "../../API/Codes/CodesCertificateAPI";
import { IOption } from "../../types/Codes/IOptions";
import Footer from "../Layout/Footer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { setApplicantStatus } from "../../store/Slices/ApplicantStatusState";

const IdAndSeatNo = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IdAndSeatNoDTO>();
  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );
  const [certificates, setcertificates] = useState<IOption[]>();
  const [showseatno, setshowseatno] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      let tawjihiCertificatesAPI = new CodesCertificateAPI();
      const tawjihiCertificates = (
        await tawjihiCertificatesAPI.GetAllCertificates()
      ).result;
      setcertificates(tawjihiCertificates);
    };
    reset({
      tawjihiType: 1,
    });
    fetchData();
  }, [reset]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IdAndSeatNoDTO> = async (data) => {
    if (!window.confirm(t("confirm")!)) {
      return;
    }
    let idandseatno = new IdAndSeatNoAPI();
    const res = await idandseatno.SeatNoAndID(data);
    dispatch(setApplicantStatus(applicantStatus <= 2 ? 2 : applicantStatus));
    toast.success(t("SuccessIno"));
    if (res.isSuccess && res.result.applicationStatus > 2) {
      navigate("/applicant/info");
    }
  };
  const signout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <header>
        <Header dark={false} />
      </header>

      <main className={"container"}>
        <div className={"container grid gridbox marginTopBottom extendcolgap"}>
          <aside className="instruction">
            <p>{t("ApplicantInfo_instruction")}</p>
            <img className={classes.img} src={card} alt="Bethlehm University" />
          </aside>
          <form className={classes.content} onSubmit={handleSubmit(onSubmit)}>
            <span className="headingPrimary">{t("IdAndSeatNo_Header")}</span>
            <h2>{t("IdAndSeatNo_TawjihiType")}</h2>

            <Controller
              control={control}
              name="tawjihiType"
              render={({ field: { onChange, value, ref } }) => (
                <select
                  {...register("tawjihiType", {
                    required: true,
                  })}
                  className={classes.tawjihiTypes}
                  onChange={(e) => {
                    setValue("tawjihiType", Number.parseInt(e.target.value));
                    e.target.value === "1"
                      ? setshowseatno(true)
                      : setshowseatno(false);
                    if (e.target.value !== "1") {
                      setValue("seatno", "");
                    }
                  }}
                >
                  {certificates &&
                    certificates?.map((c) => (
                      <option key={c.value} value={c.value}>
                        {c.label}
                      </option>
                    ))}
                </select>
              )}
            />
            <p className={classes.contenttxt}>{t("InputInfo")}</p>

            {errors.idNo && (
              <p className="errorMessage">{t("Error_Required")! + t("ID")!}</p>
            )}

            <input
              {...register("idNo", {
                required: true,
              })}
              className="textbox"
              placeholder={t("ID")!}
              type="textbox"
            />

            {showseatno ? (
              <>
                {errors.seatno && (
                  <p className="errorMessage">
                    {t("Error_Required")! + t("IdAndSeatNo_SeatNo")!}
                  </p>
                )}
                <input
                  {...register("seatno", {
                    required: showseatno ? true! : false,
                  })}
                  className="textbox"
                  placeholder={t("IdAndSeatNo_SeatNo")!}
                  type="textbox"
                />
              </>
            ) : null}

            <div className="grid grid--2--cols ">
              <input type="submit" value={t("Continue")!} className={"btn"} />
              <button onClick={signout} className={"btn redbutton"}>
                {t("Signout")}
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default IdAndSeatNo;
