import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

// Define a type for the slice state
interface NotificationState {
  email: boolean;
  mobile: boolean;
}

// Define the initial state using that type
const initialState: NotificationState = {
  email: false,
  mobile: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  // `notificationSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setMobileVerified: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.email = action.payload;
    },
  },
});

export const { setMobileVerified, setEmailVerified } =
  notificationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNotification = (state: RootState) => state.notification;

export default notificationSlice.reducer;
