import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface RoleState {
  username: string;
  role: string;
}

// Define the initial state using that type
const initialState: RoleState = {
  username: "username",
  role: "UNG",
};

export const roleStateSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
  },
});

export const { setRole } = roleStateSlice.actions;
export const { setUsername } = roleStateSlice.actions;
export const selectRole = (state: RootState) => state.role;
export default roleStateSlice.reducer;
