import { useState, useEffect } from "react";
import classes from "./MainInfo.module.css";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { ApplicantMainInfoDTO } from "../../../types/Admin/ApplicantsDTO";
import moment from "moment";
import { AdminApplicantMainInfoAPI } from "../../../API/Admin/AdminApplicantMainInfoAPI";
import { RankingListAPI } from "../../../API/Admin/AdminRankingListAPI";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { ChoicesAPI } from "../../../API/Applicant/ChoicesAPI";
import { IOption } from "../../../types/Codes/IOptions";
import Cookies from "js-cookie";
import { useAppDispatch } from "../../../store/hooks";
import ReactHtmlParser from "react-html-parser";

import Select from "react-select";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { setUpdatedRecord } from "../../../store/Slices/AdminApplicantId";
import { TempletesEmailAPI } from "../../apis/TempletesEmailAPI";
import { SMSTempletesAPI } from "../../apis/SMSTempletesAPI";
import { ApplicationSMSTempletes } from "../../types/SMSTempletes";
import { ApplicationTempletesEmail } from "../../types/EmailTempletes";
import Spinner from "../../../features/Spinner/Spinner";

const MainInfo = (props: any) => {
  const [wait, setWait] = useState<boolean>(false);
  const admin = useAppSelector((state: RootState) => state.admin);
  const [mainInfo, setmainInfo] = useState<ApplicantMainInfoDTO>();
  const [verified, setverified] = useState<string>();
  const [acceptindvshow, setacceptindvantshow] = useState(false);
  const [assingindvshow, setassignindvantshow] = useState(false);
  const [updateData, setupdateData] = useState<number>(0);
  const [choices, setChoices] = useState<IOption[]>([]);
  const [choice, setChoice] = useState<IOption>();
  const [dataEmailTempletes, setDataEmailTempletes] = useState<IOption[]>([]);
  const [dataEmailTempletesSelected, setDataEmailTempletesSelected] =
    useState<IOption>();
  const [emailTemplete, setEmailTemplete] =
    useState<ApplicationTempletesEmail>();

  const [smsTemplete, setSMSTemplete] = useState<ApplicationSMSTempletes>();
  const [dataSMSTempletes, setDataSMSTempletes] = useState<IOption[]>([]);
  const [dataSMSTempletesSelected, setDataSMSTempletesSelected] =
    useState<IOption>();

  const [sendnotification, setSendNotification] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const mainInfo = new AdminApplicantMainInfoAPI();
      const res = (await mainInfo.Get(admin.userId)).result;
      setmainInfo(res);
      setverified(res.verified);
    };
    fetchData();
  }, [admin, updateData]);

  useEffect(() => {
    const fetchData = async () => {
      const emailTempletes = new TempletesEmailAPI();
      await emailTempletes.GetAllTempletes(true).then((data) => {
        setDataEmailTempletes(data.result);
      });

      const smsTempletes = new SMSTempletesAPI();
      await smsTempletes.GetAllTempletes(true).then((data) => {
        setDataSMSTempletes(data.result);
      });
    };
    if (sendnotification) {
      fetchData();
    }
  }, [sendnotification]);

  const role = Cookies.get("role");

  const removeAcceptance = async () => {
    if (
      window.confirm("Would you like to remove the acceptance of the applicant")
    ) {
      const rankingList = new RankingListAPI();
      await rankingList
        .RemoveAcceptanceIndividually(admin.userId ?? "")
        .then((data) => {
          if (data.result == "Removed") {
            toast.success(data.result);
          } else {
            toast.error(data.result);
          }

          setupdateData(updateData + 1);
        });
      dispatch(setUpdatedRecord(true));
    }
  };

  const showAcceptanceInfo = async () => {
    const choices = new ChoicesAPI();
    setChoices((await choices.GetStudentChoices(admin.userId)).result);
    setacceptindvantshow(true);
    dispatch(setUpdatedRecord(true));
  };

  const acceptIndividual = async () => {
    setWait(true);
    setacceptindvantshow(false);
    const rankingList = new RankingListAPI();
    await rankingList
      .AcceptIndividually({
        applicantId: admin.userId ?? "",
        applicantsMajorId: Number.parseInt(choice?.value ?? ""),
        emailTemplete: emailTemplete?.id ?? 0,
        smsTemplete: smsTemplete?.id ?? 0,
        sendNotification: sendnotification,
      })
      .then((data) => {
        toast.success("Accepted");
        setupdateData(updateData + 1);
      });
    setWait(false);
  };
  const printTheApplication = () => {
    var enc = `https://adm.bethlehem.edu/print/${
      mainInfo?.userName
    }-${admin.userId?.slice(0, 3)}`;
    window.open(enc, "_blank");
  };

  const onEmailNotificationChangeHandler = async (val: IOption) => {
    setDataEmailTempletesSelected(val!);
    const emailTempletes = new TempletesEmailAPI();
    await emailTempletes
      .GetATemplete(Number.parseInt(val!.value!))
      .then((data) => {
        setEmailTemplete(data.result);
      });
  };

  const onSMSNotificationChangeHandler = async (val: IOption) => {
    setDataSMSTempletesSelected(val!);
    const smsTempletes = new SMSTempletesAPI();
    await smsTempletes
      .GetATemplete(Number.parseInt(val!.value!))
      .then((data) => {
        setSMSTemplete(data.result);
      });
  };

  return (
    <div className="grid">
      <div className="formBoxContainer">
        <button onClick={printTheApplication} className={"btn"}>
          Print the application
        </button>
        <div className={"formBox " + classes.littlePadding}>
          <div className={"grid grid--5--cols"}>
            <div>
              <b>Applicant No:</b>
              <br /> {mainInfo?.userName}
            </div>
            <div>
              <b>Name:</b>
              <br /> {mainInfo?.englishName}/{mainInfo?.arabicName}
            </div>
            <div>
              <b>Last modified</b>: <br />{" "}
              {mainInfo?.lastModifiedDate?.toString() !== "0001-01-01T00:00:00"
                ? moment
                    .utc(mainInfo?.lastModifiedDate)
                    .local()
                    .format("DD/MM/YYYY HH:mm")
                : "-"}
            </div>
            {role === "ADM" || role === "AVP" || role === "REG" ? (
              <div className={classes.verification}>
                <b>Verification:</b>
                <br />
                <select
                  id="status"
                  value={verified}
                  onChange={async (e) => {
                    if (
                      window.confirm(
                        "Would you like to update the verification status?"
                      )
                    ) {
                      setverified(e.target.value);
                    }
                    let applicant = new AdminApplicantMainInfoAPI();
                    await applicant.VerificationStatus(
                      admin.userId,
                      Number.parseInt(e.target.value) ?? 1
                    );
                    dispatch(setUpdatedRecord(true));
                  }}
                >
                  <option key={"-1"} value={"-1"}>
                    Select a status
                  </option>
                  <option key={"1"} value={"1"}>
                    Not verified
                  </option>
                  <option key={"2"} value={"2"}>
                    Verified
                  </option>
                  <option key={"3"} value={"3"}>
                    Imported{" "}
                    {mainInfo?.importedNotes !== ""
                      ? " / " + mainInfo?.importedNotes
                      : ""}
                  </option>
                </select>
              </div>
            ) : null}

            <>{wait ? <Spinner /> : null}</>
            {role === "ADM" ||
            role === "AVP" ||
            role === "REG" ||
            role === "REO" ? (
              <div>
                {mainInfo?.acceptedMajor !== null ? (
                  <>
                    <div>
                      {mainInfo?.acceptedMajor === true ? (
                        <div>
                          <p>
                            Accepted major:<b>{mainInfo?.acceptedMajorAbbr}</b>
                          </p>
                          <p>
                            Pay from:
                            <b>
                              {mainInfo?.payFrom == null
                                ? "Not assigned"
                                : moment(mainInfo?.payFrom).format(
                                    "DD/MM/YYYY"
                                  )}
                            </b>
                          </p>
                          <p>
                            Pay to:
                            <b>
                              {mainInfo?.payTo == null
                                ? "Not assigned"
                                : moment(mainInfo?.payTo).format("DD/MM/YYYY")}
                            </b>
                          </p>
                        </div>
                      ) : null}
                    </div>

                    {(role === "ADM" || role === "AVP" || role === "REG") &&
                    !wait ? (
                      <input
                        className="btn"
                        onClick={removeAcceptance}
                        value={"Remove acceptance"}
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    {["ADM", "AVP", "REG"].includes(role ?? "") && !wait ? (
                      <>
                        <input
                          className="btn"
                          onClick={showAcceptanceInfo}
                          value={"Accept individually"}
                        />
                        <i>Refresh page after accept</i>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        show={acceptindvshow}
        onHide={() => setacceptindvantshow(false)}
        className={classes.modal}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Accept an applicant</Modal.Title>
        </Modal.Header>
        {!wait ? (
          <Modal.Body>
            <Select<IOption, false>
              options={choices}
              onChange={(val) => {
                setChoice(val!);
              }}
              value={choice}
            />
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendnotification}
                      onChange={(e) => {
                        setSendNotification(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.formControllabel}>
                      Send Acceptance notification
                    </Typography>
                  }
                />
              </FormGroup>
            </div>
            {sendnotification ? (
              <div className="grid grid--2--cols">
                <div>SMS Notification</div>
                <div>Email Notification</div>
                <Select<IOption, false>
                  options={dataSMSTempletes}
                  onChange={(val) => {
                    onSMSNotificationChangeHandler(val!);
                  }}
                  value={dataSMSTempletesSelected}
                />
                <Select<IOption, false>
                  options={dataEmailTempletes}
                  onChange={(val) => {
                    onEmailNotificationChangeHandler(val!);
                  }}
                  value={dataEmailTempletesSelected}
                />
                <div dir="rtl">
                  {ReactHtmlParser(smsTemplete?.messageAr ?? "")}
                </div>
                <div dir="rtl">
                  <div>{ReactHtmlParser(emailTemplete?.subjectAr ?? "")}</div>
                  <div>{ReactHtmlParser(emailTemplete?.bodyAr ?? "")}</div>
                </div>
              </div>
            ) : null}
            <div>
              <input
                type="button"
                className="btn"
                value="Save"
                onClick={acceptIndividual}
              />
            </div>
          </Modal.Body>
        ) : (
          <Spinner />
        )}
      </Modal>
    </div>
  );
};
export default MainInfo;
