import { IAPIResponse } from "../types/IAPIResponse";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { IJwtToken, IToken } from "../types/IToken";

export class AuthAPI {
  constructor(public username: String, public password: string) {}
  async GetToken(): Promise<IAPIResponse<IToken>> {
    const payload = {
      username: this.username,
      password: this.password,
    };
    const response = await axios.post<IAPIResponse<IToken>>(
      "/usersauth/login",
      payload
    );
    let jwtToken: IAPIResponse<IToken> = response.data;
    return Promise.resolve(jwtToken);
  }

  GetRole(jwtToken: string): IJwtToken {
    let decoded = jwt_decode<IJwtToken>(jwtToken);
    return decoded;
  }
}
