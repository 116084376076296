import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Spinner from "../../Spinner/Spinner";
import NextPrevious from "../../Layout/NextPrevious";
import { useNavigate } from "react-router-dom";
import school from "../../../assests/illustration/specialneed.svg";
import classes from "./SpecialNeedAndHum.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { IOption } from "../../../types/Codes/IOptions";
import { toast } from "react-toastify";
import { CodesAPI } from "../../../API/Codes/CodesAPI";
import { ICode } from "../../../types/ICode";
import {
  ApplicantHumanitarianPrisonsResponseDTO,
  SpecialNeedAndHumRequestDTO,
} from "../../../types/Applicant/SpecialNeedAndHumRequestDTO";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { SpecialNeedAPI } from "../../../API/Applicant/SpecialNeedAPI";
import DatePicker from "react-datepicker";
import { HumanitarianAPI } from "../../../API/Applicant/HumanitarianAPI";
import { setApplicantStatus } from "../../../store/Slices/ApplicantStatusState";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import moment from "moment";
import { setUpdatedRecord } from "../../../store/Slices/AdminApplicantId";

const SpecialNeedAndHum = () => {
  const { t } = useTranslation();
  const yesno: IOption[] = [
    { label: t("no"), value: "no" },
    { label: t("yes"), value: "yes" },
  ];
  const [wait, setWait] = useState<boolean>(false);
  const [from, setfrom] = useState<Date | null>(null);
  const [to, setto] = useState<Date | null>(null);
  const [prisons, setprisons] =
    useState<ApplicantHumanitarianPrisonsResponseDTO[]>();
  const [showSpecialNeed, setShowSpecialNeed] = useState<boolean>(false);
  const [defaultValueSpecialNeed, setdefaultValueSpecialNeed] =
    useState<IOption>(yesno[0]);
  const [defaultValueHum, setdefaultValueHum] = useState<IOption>(yesno[0]);

  const [showHum, setShowHum] = useState<boolean>(false);
  const [showAdd, setShowAdd] = useState(false);
  const [specialNeedCodes, setSpecialNeedCodes] = useState<ICode[]>();
  const [relationCodes, setRelationCodes] = useState<ICode[]>();
  const dispatch = useAppDispatch();
  const applicantStatus: number = useAppSelector(
    (state: RootState) => state.applicantStatus.applicantstatus
  );

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SpecialNeedAndHumRequestDTO>();
  const admin = useAppSelector((state: RootState) => state.admin);
  const freezedValue: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );
  const freezed =
    (!admin.allowToEdit && admin.userId !== undefined) || freezedValue;

  useEffect(() => {
    //Special need
    setWait(true);

    async function fetchData() {
      let specialNeedCodes = new CodesAPI("/applicant/specialneed/codes");
      setSpecialNeedCodes((await specialNeedCodes.GetAll()).result);

      let relations = new CodesAPI("/applicant/humanitarian/relation");
      setRelationCodes((await relations.GetAll()).result);
      let specialNeed = new SpecialNeedAPI();
      const specialneedValues = (await specialNeed.GetSpecialNeed(admin.userId))
        .result;
      if (specialneedValues != null) {
        setdefaultValueSpecialNeed(yesno[1]);
        setShowSpecialNeed(true);
        reset({
          specialneed: {
            specialNeedId: specialneedValues.specialNeedId,
            affectedStudy: specialneedValues.affectedStudy,
          },
        });
      }

      let hum = new HumanitarianAPI();
      const humValues = (await hum.GetHumanitarian(admin.userId)).result;
      setprisons((await hum.getPrisons()).result);
      if (humValues != null) {
        setdefaultValueHum(yesno[1]);
        setShowHum(true);
        reset({
          hum: {
            codesRelationId: humValues.codesRelationId,
            injuryDisability: humValues.injuryDisability,
            longTermsTreatments: humValues.longTermsTreatments,
            martyrName: humValues.martyrName,
            treatmentDates: humValues.treatmentDates,
          },
        });
      }
    }
    fetchData();
    setWait(false);
  }, [reset]);

  const onSubmitSpecialNeed: SubmitHandler<
    SpecialNeedAndHumRequestDTO
  > = async (data) => {
    setWait(true);
    let specialNeed = new SpecialNeedAPI();
    await specialNeed.UpdateStatus(admin.userId);

    if (showSpecialNeed) {
      await specialNeed.UpdateSpecialNeed(data.specialneed, admin.userId);
    } else if (!showSpecialNeed) {
      //Delete special need if exists
      await specialNeed.DeleteSpecialNeed(admin.userId);
    }
    let hum = new HumanitarianAPI();
    if (showHum) {
      await hum.UpdateHumanitarian(data.hum, admin.userId);
      //Update humitarian
    } else if (!showHum) {
      //delete humitarian if exists
      await hum.DeleteHumanitarian(admin.userId);
    }
    dispatch(setApplicantStatus(applicantStatus <= 11 ? 11 : applicantStatus));
    setWait(false);

    if (admin.userId === undefined) {
      toast.success(t("SpecialNeed_sent"));
      navigate("/applicant/doc");
    }
    dispatch(setUpdatedRecord(true));
  };

  const addPrisons = async () => {
    let humanitarian = new HumanitarianAPI();

    const result = (
      await humanitarian.addPrisons(
        {
          fromDate: from,
          toDate: to,
        },
        admin.userId
      )
    ).result;
    setprisons(result);
    setfrom(null);
    setto(null);
    setShowAdd(false);
    toast.success(t("SpecialNeed_sent"));
  };

  const deletePrison = async (id: number) => {
    const c = window.confirm("هل ترغب في حذف التاريخ المضاف؟");
    if (c) {
      let humanitarian = new HumanitarianAPI();
      const result = (await humanitarian.deleteAPrison(id)).result;
      setprisons(result);
      setfrom(null);
      setto(null);
      setShowAdd(false);
      toast.success(t("SpecialNeed_sent"));
    }
  };

  return (
    <div
      className={
        "container grid gridbox marginTopBottom" +
        (admin.userId !== undefined ? " admin" : "")
      }
    >
      <aside className="instruction">
        <p>{t("Hum_Instruction")}</p>
        <img
          className="instructionimg"
          src={school}
          alt="Contact info, Bethlehem University"
        />
      </aside>
      {!wait ? (
        <div className="formBoxContainer">
          <div className="formBox">
            <div className="formBoxHeader">
              <p>{t("SpecialNeed_Title")}</p>
              {/* <div className="helpbox">
                <HelpCenterIcon />
                {t("help")!}
              </div> */}
            </div>
            <div className={"grid grid--1--cols"}>
              <div className={classes.school}>
                <div>
                  <label htmlFor="SpecialNeedOption">
                    {t("SpecialNeed_Option")!}
                  </label>
                  <Select
                    options={yesno}
                    value={defaultValueSpecialNeed}
                    defaultValue={defaultValueSpecialNeed}
                    onChange={(val) => {
                      setShowSpecialNeed(val?.value === "no" ? false : true);
                      setdefaultValueSpecialNeed(
                        val?.value === "no" ? yesno[0] : yesno[1]
                      );
                    }}
                    isDisabled={freezed}
                  />
                  <form onSubmit={handleSubmit(onSubmitSpecialNeed)}>
                    {showSpecialNeed ? (
                      <div>
                        <p className={classes.specialNeedDesc}>
                          {t("SpecialNeed_Desc")}
                        </p>
                        <div className={classes.specialNeedTypeHelp}>
                          <div className={classes.specialNeedType}>
                            <label htmlFor="specialneedCode">
                              {t("SpecialNeed_Type")!}
                            </label>
                            <select
                              id="specialneedCode"
                              {...register("specialneed.specialNeedId")}
                            >
                              {/* selected disabled hidden */}
                              <option key="-1" value="-1">
                                {t("select")!}
                              </option>
                              {specialNeedCodes &&
                                specialNeedCodes?.map((code) => (
                                  <option key={code.id} value={code.id}>
                                    {code.nameAr}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="material">
                            {t("SpecialNeed_Effect")!}
                          </label>
                          <input
                            className="textbox"
                            type="textbox"
                            {...register("specialneed.affectedStudy")}
                          />
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="formBox">
            <div className="formBoxHeader">
              <p>{t("Hum_Title")}</p>
              {/* <div className="helpbox">
                <HelpCenterIcon />
                {t("help")!}
              </div> */}
            </div>
            <div className={"grid grid--1--cols"}>
              <div>
                <div>
                  <label>{t("Hum_Option")!}</label>
                  <Select
                    options={yesno}
                    value={defaultValueHum}
                    defaultValue={defaultValueHum}
                    onChange={(val) => {
                      setShowHum(val?.value === "no" ? false : true);
                      setdefaultValueHum(
                        val?.value === "no" ? yesno[0] : yesno[1]
                      );
                    }}
                    isDisabled={freezed}
                  />
                  <form onSubmit={handleSubmit(onSubmitSpecialNeed)}>
                    {showHum ? (
                      <div>
                        <p className={classes.specialNeedDesc}>
                          {t("Hum_Desc")}
                        </p>
                        <div>
                          <button
                            className="btn smallbtn"
                            type="button"
                            onClick={() => setShowAdd(true)}
                          >
                            {t("Add")!}
                          </button>
                          <table className={classes.table}>
                            <thead>
                              <tr>
                                <th>{t("Hum_FromDate")}</th>
                                <th>{t("Hum_ToDate")}</th>
                                <th>{t("Delete")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {prisons &&
                                prisons.map((e) => (
                                  <tr key={e.id}>
                                    <td>
                                      {moment
                                        .utc(e.fromDate)
                                        .local()
                                        .format("YYYY-MM-DD")}
                                    </td>
                                    <td>
                                      {moment
                                        .utc(e.toDate)
                                        .local()
                                        .format("YYYY-MM-DD")}
                                    </td>
                                    <td>
                                      <button
                                        className="btn goldbutton smallbtn"
                                        type="button"
                                        onClick={() => deletePrison(e.id)}
                                      >
                                        {t("Delete")!}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <p className={classes.specialNeedDesc}>
                            {t("Hum_Injury")}
                          </p>
                          <div className="marginFormBoxField">
                            <label htmlFor="material">
                              {t("Hum_CauseSpecialNeed")!}
                            </label>
                            <input
                              className="textbox"
                              type="textbox"
                              {...register("hum.injuryDisability")}
                              disabled={freezed}
                            />
                          </div>
                          <div className="marginFormBoxField">
                            <label htmlFor="material">
                              {t("Hum_LongTermTreatment")!}
                            </label>
                            <input
                              className="textbox"
                              type="textbox"
                              {...register("hum.longTermsTreatments")}
                              disabled={freezed}
                            />
                          </div>
                          <div className="marginFormBoxField">
                            <label htmlFor="material">{t("Hum_Dates")!}</label>
                            <input
                              className="textbox"
                              type="textbox"
                              {...register("hum.treatmentDates")}
                              disabled={freezed}
                            />
                          </div>
                          <p className="marginFormBoxField">
                            {t("Hum_Relatives")!}
                          </p>
                          <div className={classes.relative}>
                            <div>
                              <label htmlFor="relationCodes">
                                {t("Hum_Martyr")!}
                              </label>
                              <input
                                className="textbox"
                                type="textbox"
                                {...register("hum.martyrName")}
                                disabled={freezed}
                              />
                            </div>
                            <div>
                              <label htmlFor="relationCodes">
                                {t("Hum_Relative")!}
                              </label>
                              <select
                                id="relationCodes"
                                {...register("hum.codesRelationId")}
                                disabled={freezed}
                              >
                                <option key="-1" value="-1">
                                  {t("select")!}
                                </option>
                                {relationCodes &&
                                  relationCodes?.map((code) => (
                                    <option key={code.id} value={code.id}>
                                      {code.nameAr}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {admin.userId !== undefined && admin.allowToEdit ? (
            <div className="formBox">
              <button
                className="btn"
                onClick={handleSubmit((d) => {
                  onSubmitSpecialNeed(d);
                  alert("Saved!");
                })}
              >
                Save
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmitSpecialNeed)}>
              {admin.userId === undefined && !freezed ? (
                <div className="formBox">
                  <NextPrevious
                    next={true}
                    pre={true}
                    prelink={"/applicant/choices"}
                  />
                </div>
              ) : null}
            </form>
          )}
        </div>
      ) : (
        <Spinner />
      )}

      <Modal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        keyboard={false}
        className={classes.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>اضافة تاريخ الاعتقال</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px" }}>
          <div className="grid grid--1--cols">
            <DatePicker
              dateFormat="yyyy/MM/dd"
              placeholderText="من تاريخ"
              selected={from}
              onChange={(date) => setfrom(date)}
            />

            <DatePicker
              dateFormat="yyyy/MM/dd"
              placeholderText="الى تاريخ"
              selected={to}
              onChange={(date) => setto(date)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => addPrisons()}>
            {t("Add")}
          </Button>
          <Button variant="secondary" onClick={() => setShowAdd(false)}>
            {t("Verify_ModelClose")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SpecialNeedAndHum;
