import React from "react";
import classes from "./Footer.module.css";
import Logo from "../../assests/footerlogo.png";
import { useTranslation } from "react-i18next";
import facebook from "ionicons/dist/collection/components/icon/svg/logo-facebook.svg";
import twitter from "ionicons/dist/collection/components/icon/svg/logo-twitter.svg";
import linkedin from "ionicons/dist/collection/components/icon/svg/logo-linkedin.svg";
import instagram from "ionicons/dist/collection/components/icon/svg/logo-instagram.svg";
import youtube from "ionicons/dist/collection/components/icon/svg/logo-youtube.svg";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={classes.footer}>
      <div className={"container grid " + classes.gridFooter}>
        <div className={classes.logoCol}>
          <a className={classes.footerLink} href="https://bethlehem.edu">
            <img
              className={classes.footerLogo}
              src={Logo}
              alt="Bethlehem University"
            />
          </a>

          <ul className={classes.socialLinks}>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.facebook.com/BethlehemU"
              >
                <img src={facebook} className={classes.icon} alt="facebook" />
              </a>
            </li>
            <li>
              <a
                className={classes.footerLink}
                href="https://twitter.com/BU_News"
              >
                <img src={twitter} className={classes.icon} alt="twitter" />
              </a>
            </li>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.linkedin.com/school/bethlehemuni"
              >
                <img src={linkedin} className={classes.icon} alt="linkedin" />
              </a>
            </li>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.instagram.com/bethlehemuniversity/"
              >
                <img src={instagram} className={classes.icon} alt="instagram" />
              </a>
            </li>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.youtube.com/bethlehemunivers"
              >
                <img src={youtube} className={classes.icon} alt="youtube" />
              </a>
            </li>
          </ul>

          <p className={classes.copyright}>{t("Footer_Copyright")}</p>
        </div>

        <nav className={classes.additionCol}>
          <p className={classes.footerHeading}>{t("Footer_AdditionalLink")}</p>
          <ul className={classes.fotterNav}>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.bethlehem.edu/ar/admission/"
              >
                {t("Footer_AdditionalLink_adm")}
              </a>
            </li>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.bethlehem.edu/ar/admission/procedures/"
              >
                {t("Footer_AdditionalLink_how")}
              </a>
            </li>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.bethlehem.edu/ar/admission/offered-programs/"
              >
                {t("Footer_AdditionalLink_offered")}
              </a>
            </li>
            <li>
              <a
                className={classes.footerLink}
                href="https://www.bethlehem.edu/ar/admission/"
              >
                {t("Footer_AdditionalLink_acceptance")}
              </a>
            </li>
          </ul>
        </nav>
        <div className={classes.helpCol}>
          <p className={classes.footerHeading}>{t("Footer_Help")}</p>
          <p>
            <a
              className={classes.footerLink}
              href={"mailto:" + t("Footer_Help_Email")!}
            >
              {t("Footer_Help_Email")}
            </a>
          </p>
          <p>
            <a
              className={classes.footerLink}
              href={"tel:" + t("Footer_Help_Whatsapp")}
            >
              {t("Footer_Help_Whatsapp")}
            </a>
          </p>
        </div>
        <div className={classes.addressCol}>
          <p className={classes.footerHeading}>{t("Footer_Contact")}</p>
          <address className={classes.contacts}>
            <p> {t("Footer_Contact_address")}</p>
            <p>
              <a className={classes.footerLink} href="tel:022741241">
                {t("Footer_Contact_tele")}
              </a>
            </p>
            <p>
              <a
                className={classes.footerLink}
                href={"mailto:" + t("Footer_Contact_email")!}
              >
                {t("Footer_Contact_email")}
              </a>
            </p>
            <p>{t("Footer_Contact_fax")}</p>
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
