import { useEffect, useState } from "react";
import { UploadedDocumentResponseDTO } from "../../../types/Applicant/UploadedDocumentDTO";
import { DashboardAPI } from "../../../API/Admin/AdminDashboardAPI";
import { RootState } from "../../../store/store";
import { useAppSelector } from "../../../store/hooks";
import ReactImageMagnify from "react-image-magnify";

const AdminApplicantDocuments = () => {
  const admin = useAppSelector((state: RootState) => state.admin);
  const [selectedOption, setSelectedOption] = useState<string>();

  const [uploadDocuments, setuploadDocuments] = useState<
    UploadedDocumentResponseDTO[]
  >([]);
  useEffect(() => {
    const fetchData = async () => {
      let listOfDocuments = new DashboardAPI();
      const uploadedDocuments = await listOfDocuments.GetUploadedDocuments(
        admin.userId
      );
      setuploadDocuments(uploadedDocuments.result);
    };
    fetchData();
  }, [admin.userId, admin.allowToEdit]);

  return (
    <div>
      <select
        id="documents"
        onChange={(e) => {
          setSelectedOption(
            "https://admapi.bethlehem.edu/" +
              uploadDocuments[
                uploadDocuments.findIndex(
                  (d) => d.id === Number(e.target.value)
                )
              ].filePath?.toString()
          );
        }}
      >
        <option key={"-1"} value={"-1"}>
          Select a document
        </option>
        {uploadDocuments &&
          uploadDocuments?.map((document) => (
            <option key={document.id} value={document.id}>
              {document.name}
            </option>
          ))}
      </select>

      {selectedOption ? (
        <>
          <div>
            <ReactImageMagnify
              {...{
                enlargedImagePosition: "over",
                smallImage: {
                  alt: "Document",
                  isFluidWidth: true,
                  src: selectedOption,
                },
                largeImage: {
                  src: selectedOption,
                  width: 1200,
                  height: 1800,
                },
                isEnlargedImagePortalEnabledForTouch: true,
              }}
            />
          </div>
          <p>
            <a href={selectedOption} target="_blank" rel="noreferrer">
              View the file
            </a>
          </p>
        </>
      ) : null}
    </div>
  );
};
export default AdminApplicantDocuments;
