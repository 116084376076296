import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../../assests/invoice.png";
import classes from "./invoicebank.module.css";
import BankStamp from "./Bankstamp/Bankstamp";
import { InvoiceBankType } from "./Bankstamp/BankstampType";
import { InstructionsAndAcceptanceAPI } from "../../../API/Applicant/InstructionsAndAcceptanceAPI";
import { useReactToPrint } from "react-to-print";
import { usePDF } from "react-to-pdf";

const InvoiceBank = () => {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({});
  const { toPDF, targetRef } = usePDF({ filename: "invoice.pdf" });

  let { id } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceBankType>();

  useEffect(() => {
    const fetchData = async () => {
      const data = new InstructionsAndAcceptanceAPI();
      setInvoiceDetails((await data.GetInvoiceDetail(id)).result);
    };
    fetchData();
  }, [id]);

  return (
    <>
      <div style={{ padding: "15px" }}>
        <button
          className="btn"
          onClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        >
          Print the invoice
        </button>
        {/* <button className="btn goldbutton" onClick={() => toPDF()}>
          Save as PDF
        </button> */}
      </div>
      <div ref={targetRef}>
        <div className={`${classes.printcontainer}`} ref={contentToPrint}>
          <div className={`${classes.header}`}>
            <div>
              <img src={Logo} alt="Bethlehem" className={classes.headerLogo} />
            </div>
            <div className={`${classes.office}`}>
              <i>Finance Office</i>
              <i>Student Finance Unit</i>
            </div>
          </div>
          <div className={`${classes.padding} ${classes.studentInfo}`}>
            <span>
              Student No: <strong>{invoiceDetails?.studentNumber ?? ""}</strong>{" "}
            </span>
            <span>
              Student Name: <strong>{invoiceDetails?.studentName ?? ""}</strong>{" "}
            </span>
          </div>

          <div
            className={`${classes.padding} ${classes.gridOnecolsNotResponsive}`}
          >
            <BankStamp
              minimumPayment={invoiceDetails?.minimumPayment ?? 0}
              lastDate={invoiceDetails?.lastDate.toString() ?? "Not allowed"}
              studentName={invoiceDetails?.studentName ?? "-"}
              studentNumber={invoiceDetails?.studentNumber ?? 0}
              semesterCode={invoiceDetails?.semesterCode}
              jdCurrency={invoiceDetails?.jdCurrency ?? false}
              balance={invoiceDetails?.balance ?? 0}
            />
          </div>
          <div
            className={`${classes.footer} ${classes.gridTwocolsNotResponsive}`}
          >
            <div className={classes.footerDiv}>
              <span>5 Rue des Frères, Bethlehem, Palestine</span>
              <span>P.O. Box 9, Tel: +970-2-2741241, Fax: +970-2-2744440</span>
              <span>Email: info@bethlehem.edu, Site: www.bethlehem.edu</span>
            </div>
            <div className={`${classes.footerDiv} ${classes.footerDivAr}`}>
              <span>5 شارع الفرير، بيت لحم، فلسطين</span>
              <span>
                صندوق بريد 9، هاتف: 2741241-2-970+، فاكس: 2744440-2-970+
              </span>
              <span>
                www.bethlehem.edu، موقع: info@bethlehem.eduبريد الكتروني:
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceBank;
