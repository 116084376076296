import { configureStore } from "@reduxjs/toolkit";
import NotificationSlice from "../store/Slices/NotificationState";
import ApplicantStatusState from "../store/Slices/ApplicantStatusState";
import AdminApplicantId from "./Slices/AdminApplicantId";
import RoleState from "./Slices/RoleState";
import LoadingState from "./Slices/Loading";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import Freezed from "../store/Slices/Freezed";

const reducers = combineReducers({
  notification: NotificationSlice,
  applicantStatus: ApplicantStatusState,
  role: RoleState,
  loading: LoadingState,
  admin: AdminApplicantId,
  freezed: Freezed,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
