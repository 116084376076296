import axios from "axios";
import { IOption } from "../../types/Codes/IOptions";
import { IAPIResponse } from "../../types/IAPIResponse";

export class CodesCertificateAPI {
  async GetAllTawjihiStreams(): Promise<IAPIResponse<IOption[]>> {
    const response = await axios.get<IAPIResponse<IOption[]>>(
      "/codes/certificate/tawjihistreams"
    );
    let certificates: IAPIResponse<IOption[]> = response.data;
    return Promise.resolve(certificates);
  }

  async GetAllCertificates(): Promise<IAPIResponse<IOption[]>> {
    const response = await axios.get<IAPIResponse<IOption[]>>(
      "/codes/certificate/"
    );
    let certificates: IAPIResponse<IOption[]> = response.data;
    return new Promise<IAPIResponse<IOption[]>>((resolve, reject) => {
      resolve(certificates);
    });
  }
}
