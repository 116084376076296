import axios from "axios";
import {
  ApplicantIdsInfoDTO,
  ApplicantMainInfoDTO,
} from "../../types/Admin/ApplicantsDTO";
import { IAPIResponse } from "../../types/IAPIResponse";

export class AdminApplicantMainInfoAPI {
  async Get(
    applicantId: string | undefined
  ): Promise<IAPIResponse<ApplicantMainInfoDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/maininfo/" + applicantId
    );
    let applicantInfo: IAPIResponse<ApplicantMainInfoDTO> = response.data;
    return Promise.resolve(applicantInfo);
  }

  async GetIDs(
    applicantId: string | undefined
  ): Promise<IAPIResponse<ApplicantIdsInfoDTO>> {
    const response = await axios.get<IAPIResponse>(
      "/admin/maininfo/ids/" + applicantId
    );
    let applicantInfo: IAPIResponse<ApplicantIdsInfoDTO> = response.data;
    return Promise.resolve(applicantInfo);
  }

  async POSTIDs(data: ApplicantIdsInfoDTO): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/admin/maininfo/ids",
      data
    );
    let applicantInfo: IAPIResponse = response.data;
    return Promise.resolve(applicantInfo);
  }

  async VerificationStatus(
    applicantId: string | undefined,
    verifiyType: number
  ): Promise<IAPIResponse> {
    const response = await axios.post<IAPIResponse>(
      "/admin/maininfo/verificationstatus/" + applicantId + "/" + verifiyType
    );
    let seatno: IAPIResponse = response.data;
    return Promise.resolve(seatno);
  }
}
