import React, { useEffect, useState } from "react";
import classes from "./Header.module.css";
import Logo from "../../assests/logo_blackbg.png";
import LogoDark from "../../assests/logo_whitebg.png";
import { LANGUAGES } from "../../constants/index";
import i18n from "../../i18n";

const Header = (props: any) => {
  const [language, setlanguage] = useState<string>("ar");
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang !== null) {
      changeLanguageHandler(lang);
    } else {
      localStorage.setItem("lang", "ar");
      i18n.changeLanguage("ar");
      document.body.style.direction = i18n.dir();
      setlanguage("ar");
    }
  }, []);

  const changeLanguageHandler = (lang: string) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    document.body.style.direction = i18n.dir();
    setlanguage(lang);
  };

  return (
    <nav className={classes.nav}>
      <div className={classes.title}>
        <div>طلب الإلتحاق بجامعة بيت لحم</div>
        <div>ADMISSION APPLICATION</div>
        <div>
          <select
            value={language}
            onChange={(event) => changeLanguageHandler(event.target.value)}
          >
            {LANGUAGES.map(({ code, label }) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <img
          className={classes.logo}
          src={props.dark ? LogoDark : Logo}
          alt="bethlehem university | Admission"
        />
      </div>
    </nav>
  );
};

export default Header;
