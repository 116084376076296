import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import { Major } from "../../../types/Admin/ApplicantsDTO";
import { Alert } from "react-bootstrap";
import {
  AssignedApplicantDTO,
  AssignedListOfApplicantsDTO,
  InterviewComponentProps,
  InterviewMajorDTO,
} from "../../types/InterviewList";
import { toast } from "react-toastify";
import { InterviewListAPI } from "../../apis/InterviewListAPI";
import Spinner from "../../../features/Spinner/Spinner";
import moment from "moment";

export const AssignedToInterview = (props: InterviewComponentProps) => {
  const [wait, setWait] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AssignedListOfApplicantsDTO>();

  const [listOfApplicants, setListOfApplicants] = useState<
    AssignedApplicantDTO[]
  >([]);
  const [interviewMajors, setInterviewMajors] = useState<InterviewMajorDTO[]>(
    []
  );
  const [majors, setMajors] = useState<Major[]>();

  function compareFn(a: Major, b: Major) {
    if (a.code < b.code) {
      return -1;
    } else if (a.code > b.code) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  useEffect(() => {
    setListOfApplicants(props.listOfApplicants);
    //Convert to set later
    let match = false;
    let previous = 0;
    let majors: Major[] = [];
    let majorsInterview: InterviewMajorDTO[] = [];
    if (props.listOfMajors) {
      props.listOfMajors?.sort(compareFn);
      for (const major of props.listOfMajors) {
        if (previous != major.code) {
          match = false;
        } else {
          match = true;
        }
        if (!match) {
          majors.push(major);
          majorsInterview.push({
            id: major.id,
            interviewDate: undefined,
            code: major.code,
          });
          previous = major.code;
        }
      }
    }
    setMajors(majors);
    setInterviewMajors(majorsInterview);
  }, []);

  const onSubmit: SubmitHandler<AssignedListOfApplicantsDTO> = async (data) => {
    const appType = Cookies.get("appType");

    setWait(true);
    const interviewAPI = new InterviewListAPI();
    await interviewAPI
      .AssignAList({
        emailTemplete: 0,
        smsTemplete: 0,
        interviewMajors: interviewMajors,
        listOfApplicants: listOfApplicants,
        applicantId: Number(appType),
      })
      .then(async (data) => {
        if (data) {
          await props.updateList();
        }
      });
    setWait(false);
  };

  const updateDate = async (major: number, interviewDate: Date) => {
    const interviewIndex = interviewMajors?.findIndex((c) => c.id == major);
    let listOfUpdatedMajors = [...interviewMajors];
    listOfUpdatedMajors[interviewIndex!].interviewDate = moment
      .utc(interviewDate)
      .toDate();
    setInterviewMajors(listOfUpdatedMajors!);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Alert variant="warning">
            <h4>
              Would you like to assign{" "}
              <strong>{listOfApplicants.length}</strong> applicants for
              interviews?
            </h4>
          </Alert>
        </div>
        <h3>Please select date for the following majors:</h3>
        <div className="grid grid--2--cols">
          <div>Major</div>
          <div>Date</div>
        </div>
        <div className="grid grid--2--cols">
          {majors?.map(function (object: Major, i: number) {
            return (
              <>
                {" "}
                <strong>{object.abbr}</strong>
                <div>
                  <DatePicker
                    placeholderText={"Select a date"}
                    dateFormat="yyyy/MM/dd"
                    showIcon
                    onChange={(date) => {
                      updateDate(object.id, date ?? new Date());
                    }}
                    selected={
                      interviewMajors.find((c) => c.id == object.id)
                        ?.interviewDate
                    }
                  />
                </div>
              </>
            );
          })}
        </div>
        {props.listOfApplicants.length > 0 ? (
          <div>
            {wait ? (
              <Spinner />
            ) : (
              <button className="btn" type="submit">
                Finish
              </button>
            )}
          </div>
        ) : null}
      </form>
    </div>
  );
};
