import { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";
import classes from "./WebcamCapture.module.css";
import { UploadDocument } from "../../../API/Applicant/DocumentAPI";
export const WebcamCapture = (props: any) => {
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setUrl(imageSrc);
    }
    if (imageSrc == null) {
      return;
    }

    fetch(new URL(imageSrc?.toString()))
      .then((res) => res.blob())
      .then(async (blob) => {
        const fileUploaded = new File([blob], "test.png", {
          type: "image/png",
        });
        let uploadDocument = new UploadDocument();
        await uploadDocument.UploadDocument({
          id: props.id,
          formFile: fileUploaded,
        });
        props.fetchData();
      });
  }, [webcamRef]);

  return (
    <>
      {
        <>
          {url === undefined ? (
            <div className={classes.webcamcapture}>
              <Webcam
                className={classes.webcam}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
              <button className="btn btnsmall" onClick={capture}>
                {t("Documents_Capture")}
              </button>
            </div>
          ) : (
            <div className={classes.webcamcapture}>
              <h1>تم تحميل الصورة بنجاح</h1>
            </div>
          )}
        </>
      }
    </>
  );
};
