import axios from "axios";
import { IAPIResponse } from "../../types/IAPIResponse";
import {
  ApplicantHumanitarianPrisonsRequestDTO,
  ApplicantHumanitarianPrisonsResponseDTO,
} from "../../types/Applicant/SpecialNeedAndHumRequestDTO";
import { ApplicantHumanitarianDTO } from "../../types/Applicant/ApplicantHumanitarianDTO";

export class HumanitarianAPI {
  async GetHumanitarian(
    userId?: string
  ): Promise<IAPIResponse<ApplicantHumanitarianDTO>> {
    const response = await axios.get<IAPIResponse<ApplicantHumanitarianDTO>>(
      "/applicant/humanitarian" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let hum: IAPIResponse<ApplicantHumanitarianDTO> = response.data;
    return Promise.resolve(hum);
  }
  async UpdateHumanitarian(
    data: ApplicantHumanitarianDTO,
    userId?: string
  ): Promise<IAPIResponse<ApplicantHumanitarianDTO>> {
    const response = await axios.post<IAPIResponse<ApplicantHumanitarianDTO>>(
      "/applicant/humanitarian" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      data
    );
    let hum: IAPIResponse<ApplicantHumanitarianDTO> = response.data;
    return Promise.resolve(hum);
  }

  async DeleteHumanitarian(
    userId?: string
  ): Promise<IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>> {
    const response = await axios.delete<
      IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>
    >(
      "/applicant/humanitarian/" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let listOfNewPrisons: IAPIResponse<
      ApplicantHumanitarianPrisonsResponseDTO[]
    > = response.data;
    return Promise.resolve(listOfNewPrisons);
  }

  async getPrisons(
    userId?: string
  ): Promise<IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>> {
    const response = await axios.get<
      IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>
    >(
      "/applicant/humanitarian/prisons" +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let addPrisons: IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]> =
      response.data;
    return new Promise<IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>>(
      (resolve, reject) => {
        resolve(addPrisons);
      }
    );
  }
  async addPrisons(
    data: ApplicantHumanitarianPrisonsRequestDTO,
    userId?: string
  ): Promise<IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>> {
    const response = await axios.post<
      IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>
    >(
      "/applicant/humanitarian/addprisons" +
        (typeof userId !== "undefined" ? "/" + userId : ""),
      data
    );
    let addPrisons: IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]> =
      response.data;
    return new Promise<IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>>(
      (resolve, reject) => {
        resolve(addPrisons);
      }
    );
  }
  async deleteAPrison(
    id: number,
    userId?: string
  ): Promise<IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>> {
    const response = await axios.delete<
      IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>
    >(
      "/applicant/humanitarian/deleteaprison/" +
        id +
        (typeof userId !== "undefined" ? "/" + userId : "")
    );
    let listOfNewPrisons: IAPIResponse<
      ApplicantHumanitarianPrisonsResponseDTO[]
    > = response.data;
    return new Promise<IAPIResponse<ApplicantHumanitarianPrisonsResponseDTO[]>>(
      (resolve, reject) => {
        resolve(listOfNewPrisons);
      }
    );
  }
}
