import { RouteObject } from "react-router-dom";
import Login from "./features/login/Login";
import IdAndSeatNo from "./features/Udergraduate/IdAndSeatNo";
import IDandStudentNo from "./features/Graduate/IDandStudentNo/IDandStudentNo";
import Layout from "./features/Layout/Layout";
import ApplicantInfo from "./features/Applicant/ApplicantInfo/ApplicantInfo";
import ContactInfo from "./features/Applicant/ContactInfo/ContactInfo";
import Schoolinfo from "./features/Applicant/Schoolinfo/Schoolinfo";
import Choices from "./features/Applicant/Choices/Choices";
import SpecialNeedAndHum from "./features/Applicant/SpecialNeedAndHum/SpecialNeedAndHum";
import Documents from "./features/Applicant/Documents/Documents";
import NoMatch from "./features/NoMatch/NoMatch";
import VerifyEmail from "./features/Applicant/VerifyEmail/VerifyEmail";
import Experiance from "./features/Graduate/Experiance/Experiance";
import Thanks from "./features/Applicant/Thanks";
import AdmissionCard from "./features/Applicant/admissioncard";
import AcceptanceMajor from "./features/Applicant/NotificaitonsDetails/AcceptanceMajor";
import AcceptanceInterview from "./features/Applicant/NotificaitonsDetails/AcceptanceInterview";
import PrintApplicant from "./features/Applicant/NotificaitonsDetails/PrintApplicant";
import Paypal from "./features/Applicant/NotificaitonsDetails/Paypal";
import { Dashboard, ListOfApplicants } from "./Admin/views";
import { RankingList } from "./Admin/views/rankingList";
import { AdminAssignedToInterview } from "./Admin/views/AdminAssignedToInterview/AssignedToInterview";
import { AdminAcceptedToMajor } from "./Admin/views/AdminAcceptedToMajor/AdminAcceptedToMajor";
import InvoiceBank from "./features/Applicant/Invoice/InvoiceBank";
import PrintRankingList from "./Admin/views/PrintRankingList/PrintRankingList";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/ug/idandseatno",
    element: <IdAndSeatNo />,
  },
  {
    path: "/gr/idandstudentno",
    element: <IDandStudentNo />,
  },

  {
    path: "/applicant/",
    element: <Layout />,
    children: [
      {
        path: "info",
        element: <ApplicantInfo />,
      },
      {
        path: "contact",
        element: <ContactInfo />,
      },
      {
        path: "schoolinfo",
        element: <Schoolinfo />,
      },
      {
        path: "choices",
        element: <Choices />,
      },
      {
        path: "snh",
        element: <SpecialNeedAndHum />,
      },
      {
        path: "doc",
        element: <Documents />,
      },
      {
        path: "experiance",
        element: <Experiance />,
      },
      {
        path: "thx",
        element: <Thanks />,
      },
    ],
  },

  {
    path: "/admin/",
    element: <Dashboard />,
    children: [
      {
        path: "listofapplicants",
        element: <ListOfApplicants />,
      },
      {
        path: "rankinglist",
        element: <RankingList />,
      },
      {
        path: "assignedtoInterview",
        element: <AdminAssignedToInterview />,
      },
      {
        path: "acceptedmajor",
        element: <AdminAcceptedToMajor />,
      },
    ],
  },
  {
    path: "/verifyemail",
    element: <VerifyEmail />,
  },
  {
    path: "/admissioncard",
    element: <AdmissionCard />,
  },
  {
    path: "/m/:id",
    element: <AcceptanceMajor />,
  },
  {
    path: "/p/:id",
    element: <Paypal />,
  },
  {
    path: "/i/:id",
    element: <AcceptanceInterview />,
  },
  {
    path: "/v/:id",
    element: <InvoiceBank />,
  },
  {
    path: "/print/:id",
    element: <PrintApplicant />,
  },
  {
    path: "/admin/printrankinglist/:listno/:apptype/:major",
    element: <PrintRankingList />,
  },
  {
    path: "/*",
    element: <NoMatch />,
  },
];

export { routes };
