import { useEffect, useState } from "react";
import { IOption } from "../../../types/Codes/IOptions";
import { OptionsAPI } from "../../../API/Codes/CodesAPI";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./AdminHeader.module.css";
import Cookies from "js-cookie";

const AdminHeader = (props: any) => {
  const [semesters, setsemesters] = useState<IOption[]>([]);
  const [selectedSemester, setselectedSemester] = useState<string>();
  const [types, settypes] = useState<IOption[]>([]);
  const [selectedType, setselectedType] = useState<string>();
  useEffect(() => {
    const fetchData = async () => {
      let semesters = new OptionsAPI("/admin/globalvalues/semesters");
      const listOfSemesters = (await semesters.GetAll()).result;
      setsemesters(listOfSemesters);
      const semester = Cookies.get("semester");
      if (semester !== undefined) {
        changeSemesterHandler(semester);
      }
      const appType = Cookies.get("appType");
      if (appType !== undefined) {
        setselectedType(appType);
        selectApplicationTypeHandler(appType);
      }
    };
    fetchData();
  }, []);

  const onSemesterChangeHandler = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    changeSemesterHandler(e.target.value);
  };

  const changeSemesterHandler = (semester: string) => {
    setselectedSemester(semester);
    updateApplicationTypes(semester);
    Cookies.set("semester", semester);
  };

  const updateApplicationTypes = async (sem: string) => {
    let applicantTypes = new OptionsAPI(
      "/admin/globalvalues/applicationtypes/" + sem
    );
    const listOfTypes = (await applicantTypes.GetAll()).result;
    settypes(listOfTypes);
  };

  const onTypeChangeHandler = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const appType = e.target.value;
    selectApplicationTypeHandler(appType);
  };

  const selectApplicationTypeHandler = async (appType: string) => {
    Cookies.set("appType", appType);
    setselectedType(appType);
    props.onTypeHandler(appType);
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className={"notification"}
      />
      <h1>{props.title}</h1>
      <div className={classes.adminHeader + " grid grid--5--cols"}>
        <select
          id="semesters"
          onChange={onSemesterChangeHandler}
          value={selectedSemester}
        >
          <option key={"-1"} value={"-1"}>
            Select a semester
          </option>
          {semesters?.map((semester) => (
            <option key={semester.value} value={semester.value}>
              {semester.label}
            </option>
          ))}
        </select>
        <select
          id="applicationTypes"
          onChange={onTypeChangeHandler}
          value={selectedType}
        >
          <option key={"-1"} value={"-1"}>
            Select an applicant type
          </option>
          {types?.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>{" "}
    </>
  );
};

export default AdminHeader;
