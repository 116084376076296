import React, { useState, useEffect } from "react";
import camera from "ionicons/dist/collection/components/icon/svg/camera.svg";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classes from "./Document.module.css";
import { WebcamCapture } from "./WebcamCapture";
import { UploadedDocumentResponseDTO } from "../../../types/Applicant/UploadedDocumentDTO";
import { Documents, UploadDocument } from "../../../API/Applicant/DocumentAPI";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";

const Document = () => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState<number>(0);
  const handleClose = () => setShow(false);
  const { t } = useTranslation();
  const freezed: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );
  const [data, setData] = useState<UploadedDocumentResponseDTO[]>([]);

  //Move it to use effect and update a signle uploaded file not
  const fetchData = async () => {
    let listOfDocuments = new Documents();
    setData((await listOfDocuments.GetUploadedDocuments()).result);
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Create a reference to the hidden file input element
  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const fileUploaded: File | null =
      event.target.files == null ? null : event.target.files[0];
    let uploadDocument = new UploadDocument();
    await uploadDocument.UploadDocument({
      id: id,
      formFile: fileUploaded,
    });
    fetchData();
    toast.success(t("Documents_SuccessFile"));
  };

  const deleteFile = async (id: number) => {
    const c = window.confirm("هل ترغب في حذف الملف؟");
    if (c) {
      let deleteFile = new Documents();
      await deleteFile.DeleteDocument(id);
    }
    fetchData();
  };

  return (
    <div>
      {data.map((document) => (
        <div
          className={
            classes.mainGrid +
            " " +
            classes.document +
            (document.filePath != null ? " " + classes.uploadedDocument : "")
          }
        >
          {document.filePath == null ? (
            <>
              <div
                className={document.requiredDocument ? classes.required : ""}
              >
                <p>{document.nameAr}</p>
              </div>
              <div>
                <div className={classes.uploadDocument}>
                  <input
                    accept=".pdf,.jpg,.doc,.docx, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                    type="file"
                    required={document.requiredDocument}
                    key={document.id.toString()}
                    onChange={(e) => {
                      handleChange(e, document.id);
                    }}
                  />{" "}
                </div>
              </div>
              <div>
                <div className={classes.uploadDocument}>
                  <button
                    type="button"
                    onClick={() => {
                      setShow(true);
                      setId(document.id);
                    }}
                    className={classes.btn}
                  >
                    <img
                      className="Capture image"
                      src={camera}
                      alt="Upload file, Bethlehem University"
                    />
                  </button>
                  <p>{t("Documents_Capture")}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <p>{document.nameAr}</p>
              <a
                href={"https://admapi.bethlehem.edu/" + document.filePath}
                className="btn smallbtn"
                target="_blank"
                rel="noreferrer"
              >
                {t("Documents_ShowFile")}
              </a>
              {!freezed ? (
                <button
                  type="button"
                  style={{ backgroundColor: "red" }}
                  className="btn smallbtn "
                  onClick={() => deleteFile(document.id)}
                >
                  {t("Documents_DeleteFile")}{" "}
                </button>
              ) : null}
            </>
          )}
        </div>
      ))}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={classes.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Documents_Capture")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WebcamCapture id={id} fetchData={() => fetchData()} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};
export default Document;
