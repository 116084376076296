import { IAPIResponse } from "../../types/IAPIResponse";
import axios from "axios";
import { IApplicationStatus } from "../../types/Applicant/IApplicationStatus";
import { IdAndSeatNoDTO } from "../../types/Applicant/undergraduate/IdAndSeatNoDTO";

export class IdAndSeatNoAPI {
  async SeatNoAndID(
    data: IdAndSeatNoDTO
  ): Promise<IAPIResponse<IApplicationStatus>> {
    const response = await axios.post<IAPIResponse<IApplicationStatus>>(
      "/applicant/personalinfo/seatandid",
      data
    );
    let idAndSeatNo: IAPIResponse<IApplicationStatus> = response.data;
    return new Promise<IAPIResponse<IApplicationStatus>>((resolve) => {
      resolve(idAndSeatNo);
    });
  }
}
