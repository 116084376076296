import { useEffect, useState } from "react";
import { TempletesEmailAPI } from "../../apis/TempletesEmailAPI";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { IOption } from "../../../types/Codes/IOptions";
import { SMSTempletesAPI } from "../../apis/SMSTempletesAPI";
import { Alert } from "react-bootstrap";
import { AdminAssignedListInterviewsNotificationDTO } from "../../types/NotificationTypes";
import Select from "react-select";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment-business-days";
import { ApplicationTempletesEmail } from "../../types/EmailTempletes";
import { ApplicationSMSTempletes } from "../../types/SMSTempletes";
import { NotificationsAPI } from "../../apis/NotificationsAPI";
import ReactHtmlParser from "react-html-parser";
import Spinner from "../../../features/Spinner/Spinner";
import {
  AssignedListOfApplicantsDTO,
  InterviewNotificationComponentProps,
} from "../../types/InterviewList";
import { toast } from "react-toastify";

export const AssignToInterviewNotification = (
  props: InterviewNotificationComponentProps
) => {
  const [wait, setWait] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<AdminAssignedListInterviewsNotificationDTO>();

  const [dataEmailTempletes, setDataEmailTempletes] = useState<IOption[]>([]);
  const [dataEmailTempletesSelected, setDataEmailTempletesSelected] =
    useState<IOption>();
  const [result, setresult] = useState<string[]>([]);
  const [dataSMSTempletes, setDataSMSTempletes] = useState<IOption[]>([]);
  const [dataSMSTempletesSelected, setDataSMSTempletesSelected] =
    useState<IOption>();

  const [emailTemplete, setEmailTemplete] =
    useState<ApplicationTempletesEmail>();

  const [smsTemplete, setSMSTemplete] = useState<ApplicationSMSTempletes>();

  useEffect(() => {
    const notificationList = async () => {
      const emailTempletes = new TempletesEmailAPI();
      await emailTempletes.GetAllTempletes(true).then((data) => {
        setDataEmailTempletes(data.result);
      });

      const smsTempletes = new SMSTempletesAPI();
      await smsTempletes.GetAllTempletes(true).then((data) => {
        setDataSMSTempletes(data.result);
      });
    };
    notificationList();
  }, []);

  const onSubmit: SubmitHandler<
    AdminAssignedListInterviewsNotificationDTO
  > = async (data) => {
    setWait(true);
    const notification = new NotificationsAPI();
    const result = await notification.AssignInterviews({
      emailTemplete: emailTemplete?.id ?? 0,
      smsTemplete: smsTemplete?.id ?? 0,
      listOfApplicants: props.listOfApplicants,
    });
    toast.success(
      <div>
        {result.result.map((item) => {
          return <strong>{item}</strong>;
        })}
      </div>
    );
    props.updateList();
    setWait(false);
  };

  const onEmailNotificationChangeHandler = async (val: IOption) => {
    setValue("emailTemplete", Number.parseInt(val!.value!));
    setDataEmailTempletesSelected(val!);
    const emailTempletes = new TempletesEmailAPI();
    await emailTempletes
      .GetATemplete(Number.parseInt(val!.value!))
      .then((data) => {
        setEmailTemplete(data.result);
      });
  };

  const onSMSNotificationChangeHandler = async (val: IOption) => {
    setValue("smsTemplete", Number.parseInt(val!.value!));
    setDataSMSTempletesSelected(val!);
    const smsTempletes = new SMSTempletesAPI();
    await smsTempletes
      .GetATemplete(Number.parseInt(val!.value!))
      .then((data) => {
        setSMSTemplete(data.result);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Alert variant="warning">
            <h4>
              Would you like to assign{" "}
              <strong>{props.listOfApplicants.length}</strong> applicants for
              interviews?
            </h4>
          </Alert>
        </div>
        <h3></h3>
        <div className="grid grid--2--cols">
          <div>SMS Notification</div>
          <div>Email Notification</div>
          <Controller
            control={control}
            name="smsTemplete"
            render={({ field: { onChange, value, ref } }) => (
              <Select<IOption, false>
                options={dataSMSTempletes}
                {...register("smsTemplete", { required: true })}
                onChange={(val) => {
                  onSMSNotificationChangeHandler(val!);
                }}
                value={dataSMSTempletesSelected}
              />
            )}
          />
          <Controller
            control={control}
            name="emailTemplete"
            render={({ field: { onChange, value, ref } }) => (
              <Select<IOption, false>
                options={dataEmailTempletes}
                {...register("emailTemplete", { required: true })}
                onChange={(val) => {
                  onEmailNotificationChangeHandler(val!);
                }}
                value={dataEmailTempletesSelected}
              />
            )}
          />
          <div dir="rtl">{ReactHtmlParser(smsTemplete?.messageAr ?? "")}</div>
          <div dir="rtl">
            <div>{ReactHtmlParser(emailTemplete?.subjectAr ?? "")}</div>
            <div>{ReactHtmlParser(emailTemplete?.bodyAr ?? "")}</div>
          </div>
        </div>
        {props.listOfApplicants.length > 0 ? (
          <div>
            {wait ? (
              <Spinner />
            ) : (
              <button className="btn" type="submit">
                Finish
              </button>
            )}
          </div>
        ) : null}
      </form>
    </div>
  );
};
