import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../Spinner/Spinner";
import NextPrevious from "../../Layout/NextPrevious";
import { useNavigate } from "react-router-dom";
import school from "../../../assests/illustration/school.svg";
import { useForm, SubmitHandler } from "react-hook-form";
import { SchoolInfoRequestDTO } from "../../../types/Applicant/SchoolInfoDto";
import { UpdateSchoolInfoAPI } from "../../../API/Applicant/SchoolInfoAPI";
import { toast } from "react-toastify";
import Document from "./Document";
import ListOfOtherDocuments from "./ListOfOtherDocuments";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";

const SchoolInfo = () => {
  const freezed: boolean = useAppSelector(
    (state: RootState) => state.freezed.freezed
  );
  const { t } = useTranslation();
  const [wait, setWait] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SchoolInfoRequestDTO>();

  const onSubmit: SubmitHandler<SchoolInfoRequestDTO> = (data) => {
    toast.success(t("Documents_sent"));
  };

  return (
    <div className={"container grid gridbox marginTopBottom"}>
      <aside className="instruction">
        <p>{t("Documents_Title")}</p>
        <img
          className="instructionimg"
          src={school}
          alt="Contact info, Bethlehem University"
        />
      </aside>
      {!wait ? (
        <div className="formBoxContainer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formBox">
              <div className="formBoxHeader">
                <p>{t("Documents_Title")}</p>
                {/* <div className="helpbox">
                  <HelpCenterIcon />
                  {t("help")!}
                </div> */}
              </div>
              <div className={"grid grid--1--cols"}>
                <Document />
              </div>
            </div>
          </form>
          <div className="formBox">
            <div className="formBoxHeader">
              <p>{t("Documents_Other")}</p>
              {/* <div className="helpbox">
                <HelpCenterIcon />
                {t("help")!}
              </div> */}
            </div>
            <ListOfOtherDocuments />
          </div>
          {!freezed ? (
            <div className="formBox">
              <NextPrevious
                next={true}
                pre={true}
                onClickPrevious={handleSubmit((d) => {
                  onSubmit(d);
                  navigate("/applicant/snh");
                })}
                onClickNext={handleSubmit((d) => {
                  onSubmit(d);
                  navigate("/applicant/thx");
                })}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SchoolInfo;
